import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../interfaces/AppSettings';


@Injectable({ providedIn: 'root' })
export class AppSettingsService {

  public static Settings: AppSettings;

  private http: HttpClient;
  constructor(private httpBackEnd: HttpBackend) {
    this.http = new HttpClient(httpBackEnd);
  }
  load() {
    const jsonFile = 'assets/settings.json';
    return new Promise<AppSettings>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: AppSettings) => {
        AppSettingsService.Settings = response;
        AppSettingsService.Settings.baseUrlToken = response.apiAddress;
        AppSettingsService.Settings.baseUrl = `${response.apiAddress}/api/v1`;
        AppSettingsService.Settings.baseUrlV2 = `${response.apiAddress}/api/v2`;
        AppSettingsService.Settings.fusionChartsLicense = response.fusionChartsLicense;
        AppSettingsService.Settings.pendoApiKey = response.pendoApiKey;
        AppSettingsService.Settings.auth0Settings = response.auth0Settings;
        resolve(AppSettingsService.Settings);
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }


}
