import { Injectable } from '@angular/core';
import { IAppGuard, INavData } from './_nav';
import { IAccount, IAccountMember } from './interfaces/Account';
import { ISession } from './interfaces/Session';
import { Decrypt, Encrypt } from './utils/encryption';

@Injectable({
  providedIn: 'root'
})
export class AppSessionService {
  public currentSession: ISession = {} as ISession;
  public currentMenu:  any;
  private currentDate: string;

  constructor() {
    this.setDateKey();
    if (sessionStorage.getItem('SESSION')) {
      this.currentDate = sessionStorage.getItem('LOGIN-DATE');
      this.currentSession = JSON.parse(Decrypt(sessionStorage.getItem('SESSION'), this.currentDate)) as ISession;
    }
  }

  setSession(currentSession: ISession): void {
    this.setDateKey();
    const session = JSON.stringify(currentSession);
    if(!this.currentDate)
      this.currentDate = sessionStorage.getItem('LOGIN-DATE');
    sessionStorage.setItem('SESSION', Encrypt(session, this.currentDate));
    this.currentSession = currentSession;
  }

  getSession(): ISession {
    if (sessionStorage.getItem('SESSION')) {
      this.setDateKey();
      this.currentDate = sessionStorage.getItem('LOGIN-DATE');
      const session = JSON.parse(Decrypt(sessionStorage.getItem('SESSION'), this.currentDate)) as ISession;
      return session;
    }
    return this.currentSession;
  }

  setDateKey(): void {
    if (!sessionStorage.getItem('LOGIN-DATE')) {
      this.currentDate = JSON.stringify(new Date());
      sessionStorage.setItem('LOGIN-DATE', this.currentDate);
    }
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this.currentSession = this.getSession();
    this.currentSession.isLoggedIn = isLoggedIn;
    this.setSession(this.currentSession);
  }

  setEmail(email: string): void {
    this.currentSession = this.getSession();
    this.currentSession.email = email;
    this.setSession(this.currentSession);
  }

  setAccount(account: IAccount): void {
    this.currentSession = this.getSession();
    this.currentSession.account = account;
    this.setSession(this.currentSession);
  }

  setAccountMember(accountMember: IAccountMember): void {
    this.currentSession = this.getSession();
    this.currentSession.accountMember = accountMember;
    this.setSession(this.currentSession);
  }

  setClientId(clientId: string): void {
    this.currentSession = this.getSession();
    this.currentSession.clientId = clientId;
    this.setSession(this.currentSession);
  }

  setAccountId(accountId: number): void {
    this.currentSession = this.getSession();
    this.currentSession.accountId = accountId;
    this.setSession(this.currentSession);
  }

  setAccountMemberId(accountMemberId: number): void {
    this.currentSession = this.getSession();
    this.currentSession.accountMemberId = accountMemberId;
    this.setSession(this.currentSession);
  }

  setInternalRole(internalRole: number): void {
    this.currentSession = this.getSession();
    this.currentSession.internalRole = internalRole;
    this.setSession(this.currentSession);
  }

  setActivateMercury(activateMercury: boolean): void {
    this.currentSession = this.getSession();
    this.currentSession.activateMercury = activateMercury;
    this.setSession(this.currentSession);
  }

  setGuards(guards: IAppGuard[]): void {
    this.currentSession = this.getSession();
    this.currentSession.guards = guards;
    this.setSession(this.currentSession);
  }

  setMenu(menu: any): void {
    this.currentMenu = menu;
    const lmenu = JSON.stringify(menu);
    sessionStorage.setItem('MENU', lmenu);
  }

  getMenu(): INavData[] {
    return JSON.parse(sessionStorage.getItem('MENU')) as INavData[];
  }

  clear(): void {
    sessionStorage.clear();
  }
}
