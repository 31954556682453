import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IAppGuard } from './_nav';
import { AppAuthService } from './app-auth.service';
import { AppSessionService } from './app-session.service';
import { ISession } from './interfaces/Session';
import { IDeactivateComponent } from './interfaces/IDeactivateComponent';
import { AppSettingsService } from './data-services/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuardService  {

  public guards !: IAppGuard[];
  constructor(
    private authService: AppAuthService,
    private router: Router,
    private _sessionService: AppSessionService
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log('RUTA');
    console.log(next);
    console.log(state.url);
    return this.checkLoggedIn(state.url);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }

  canDeactivate(component: IDeactivateComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return component.canExit ? component.canExit() : true;
  }

  checkLoggedIn(url: string): boolean {
    if (window.location.pathname === '/auth0') {
      this.router.navigate(['/auth0']);
      return false;
    }

    if (window.location.pathname === '/callback') {
      this.router.navigate(['/callback']);
      return false;
    }

    if (window.location.pathname === '/auth0/logged-out') {
      this.router.navigate(['/auth0/logged-out']);
      return false;
    }
    
    const session = this.authService.getSession();
    console.log(`Guard request to: ${url}`);

    const param = url.indexOf('?');
    if (param > 0) {
      console.log('Route with parammeters');
      url = url.substring(0, param);
    }
    const loggedIn = this.authService.isLoggedIn;
    if (loggedIn) {
      if (session.guards != null) {
        if (this.checkAuthorized(url, session)) {
          return true;
        }
      }
      alert('You are not authorized to access this option.');
      console.log(
        `Route ${url} NOT authorized to: ${this.authService.email}`
      );
      return false;
    } else {
      this.authService.redirectUrl = url;
      if(localStorage.getItem('auth0OrgId')){
        window.location.replace(`${AppSettingsService.Settings.auth0Settings.appUri}/auth0?organization=${localStorage.getItem('auth0OrgId')}&&redirectUrl=${url}`);
      } else if (sessionStorage.getItem('isLoggedOutFromAuth0') === 'true') {
        window.location.replace(AppSettingsService.Settings.auth0Settings.universalLoginUrl+'/logged-out');
      }
      else{
        this.router.navigate(['login']);
        console.log('Authorized: false');
      }  
      return false;
    }
  }

  checkAuthorized(url: string, session: ISession): boolean {
    for (const entry of session.guards) {
      if (url === entry.url) {
        for (const r of entry.roles) {
          if (r === this.authService.internalRole) {
            console.log(
              `Route ${entry.url} authorized to: ${r} : ${this.authService.email}`
            );
            return true;
          }
        }
      }
    }
    return false;
  }
}
