import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multiselect-checkbox',
  templateUrl: './multiselect-checkbox.component.html',
  styleUrls: ['./multiselect-checkbox.component.scss']
})
export class MultiselectCheckboxComponent {
  @Input() options: MultiselectCheckboxItem[] = [];
  @Input() checkedItems?: number[] = [];
  @Input() label: string = '';
  @Input() id: string = '';
  @Output() userSelectionChange = new EventEmitter<MultiselectCheckboxItem[]>();

  searchInput = new Subject<string>();

  constructor() { }

  onChange(data: MultiselectCheckboxItem[]): void {
    if (!Array.isArray(data)) { return; }
    this.userSelectionChange.emit(data);
  }
}

export interface MultiselectCheckboxItem {
  key: number;
  value: string;
}
