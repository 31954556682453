import { Injector } from '@angular/core';
import { AppAuthService } from './app-auth.service';
import { AppSettingsService } from './data-services/app-settings.service';
import { AuthClientConfig } from '@auth0/auth0-angular';

export function appInitializer(appConfig: AppSettingsService, injector: Injector, authConfig: AuthClientConfig) {
  return (async () => {
    await appConfig.load().then(settings=> {
      authConfig.set({
        domain: settings.auth0Settings.domain,
        clientId: settings.auth0Settings.clientId,
        authorizationParams: settings.auth0Settings.authorizationParams,
      });
    });   
    const authService = injector.get(AppAuthService);
    await authService.refreshToken().toPromise();
  });
}
