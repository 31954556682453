// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.card {
  height: 100%;
  margin: 5px;
  padding: 10px;
  border: 2px;
  border-color: rgb(238, 236, 236);
  border-style: solid;
  border-radius: 5px;
}

.card:hover {
  cursor: pointer;
  background-color: rgb(240, 239, 239);
}

.card-disabled {
  opacity: 0.4;
}

.card-disabled:hover {
  cursor: default;
  background-color: white;
}

.icn {
  font-weight: bold;
}

:host-context [class^=icon-],
[class*=" icon-"] {
  font-family: simple-line-icons, sans-serif !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/shared-components/grid-menu/grid-menu.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,gCAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,oCAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,eAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;;EAEI,qDAAA;AAAJ","sourcesContent":["\r\n.title {\r\n    font-weight: bold;\r\n    margin-bottom: 0.1rem;\r\n}\r\n\r\n.card {\r\n    height: 100%;\r\n    margin: 5px;\r\n    padding: 10px;\r\n    border: 2px;\r\n    border-color: rgb(238, 236, 236);\r\n    border-style: solid;\r\n    border-radius: 5px;\r\n}\r\n\r\n.card:hover {\r\n    cursor: pointer;\r\n    background-color: rgb(240, 239, 239);\r\n}\r\n\r\n.card-disabled {\r\n    opacity: 0.4;\r\n}\r\n\r\n.card-disabled:hover {\r\n    cursor: default;\r\n    background-color: white;\r\n}\r\n\r\n.icn {\r\n    font-weight: bold;\r\n}\r\n\r\n:host-context [class^=\"icon-\"],\r\n[class*=\" icon-\"] {\r\n    font-family: simple-line-icons, sans-serif !important;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
