import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import Containers
import { MainLayoutComponent } from './containers';

import { AppGuardService } from './app-guard.service';
import { ActivateComponent } from './views/init-components/activate/activate.component';
import { CreatePasswordComponent } from './views/init-components/create-password/create-password.component';
import { ForgotComponent } from './views/init-components/forgot-password/forgot.component';
import { LeadDetailsRedirectionComponent } from './views/init-components/lead-details-redirection/lead-details-redirection.component';
import { LoginComponent } from './views/init-components/login/login.component';
import { SpamUnsubscribeComponent } from './views/init-components/spam-unsubscribe/spam-unsubscribe.component';
import { UnauthorizedComponent } from './views/init-components/unauthorized/unauthorized.component';
import { Auth0Component } from './views/auth0/auth0.component';
import { CallbackComponent } from './views/auth0/callback/callback.component';
import { ErrorComponent } from './views/auth0/error/error.component';

export const routes: Routes = [

  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AppGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/angular-materials/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/angular-materials/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'campaign-list',
        loadChildren: () => import('./views/angular-materials/campaign-management/campaign-management.module').then(m => m.CampaignManagementModule)
      },
      {
        path: 'campaign-management-module',
        loadChildren: () => import('./views/angular-materials/campaign-management/campaign-management.module').then(m => m.CampaignManagementModule)
      },
      {
        path: 'lead-management',
        loadChildren: () => import('./views/lead-management/lead-management.module').then(m => m.LeadManagementModule)
      },
      {
        path: 'reporting',
        loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'settings-module',
        loadChildren: () => import('./views/angular-materials/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'communications',
        loadChildren: () => import('./views/communications/communications.module').then(m => m.CommunicationsModule)
      }
    ]
  },

  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'activate',
    component: ActivateComponent,
    data: {
      title: 'Activate'
    }
  },
  {
    path: 'createpassword',
    component: CreatePasswordComponent,
    data: {
      title: 'Create Password'
    }
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: {
      title: 'Forgot password'
    }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: 'Unauthorized'
    }
  },
  {
    path: 'routelead',
    component: LeadDetailsRedirectionComponent,
    data: {
      title: 'Route Lead'
    }
  },
  {
    path: 'unsubscribe',
    component: SpamUnsubscribeComponent,
    data: {
      title: 'Unsubscribe'
    }
  },
  {
    path: 'auth0',
    component: Auth0Component,
    data: {
      title: 'auth0'
    }
  },
  {
    path: 'callback',
    component: CallbackComponent,
    data: {
      title: 'callback'
    }
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      title: 'error'
    }
  },
  {
    path: 'auth0/logged-out',
    component: Auth0Component,
    data: {
      title: 'logged-out'
    }
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
