import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../data-services/data.service';
import { ISimpleChart1 } from '../../../interfaces/Views';

@Component({
  selector: 'app-simple-chart',
  templateUrl: './simple-chart.component.html'
})
export class SimpleChartComponent implements OnInit {

  @Output() ready = new EventEmitter<any>();
  @Output() dataReady = new EventEmitter<any>();

  public uCalls = 0;
  data: object;
  handler: any;
  chartObj: any;
  charType = 'pie2d';
  isPieChart = true;

  public chartData: ISimpleChart1[] = [];

  private InitData: ISimpleChart1[] = [
    { Id: 0, Name: '', Value: 0 }
  ];

  constructor(private zone: NgZone, private service: DataService, private router: Router) {
  }

  ngOnInit(): void {
    this.DrawChart(this.InitData);
  }

  initialized($event) {
    console.log('chart initialized!');
    this.chartObj = $event.chart;
    this.attachEvent();
    this.ready.emit(null);
  }

  attachEvent() {
    this.handler = this.dataplotClickHandler.bind(this);
    this.chartObj.addEventListener('dataplotClick', this.handler);
  }

  dataplotClickHandler(eventObj, dataObj) {
    this.zone.run(() => {
      console.log(this.chartData[dataObj.index]);
    });
  }

  public SetData(data: ISimpleChart1[]) {
    this.DrawChart(data);
  }

  private DrawChart(data: ISimpleChart1[]) {
    if (data === undefined) {
      return;
    }
    const dataSeries = [];
    let i = 0;
    data.forEach(element => {
      dataSeries.push({
        label: element.Name,
        value: element.Value
      });
      i++;
    });
    this.data = {
      chart: {
        // caption: "Split of Visitors by Age Group",
        // subCaption: "Last year",
        use3DLighting: '0',
        showPercentValues: '1',
        decimals: '1',
        useDataPlotColorForLabels: '1',
        theme: 'fusion'
      },
      data: dataSeries
    };
  }

  public toggleChartType() {
    this.isPieChart = !this.isPieChart;
    this.charType = this.isPieChart ? 'pie2d' : 'bar2d';
  }

}
