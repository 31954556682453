import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataService } from '../../../../data-services/data.service';
import { IErrorLog } from '../../../../interfaces/ErrorLog';
import { ISimpleChart1 } from '../../../../interfaces/Views';

@Component({
  selector: 'app-widget-chart1-shared',
  templateUrl: './widget-chart1-shared.component.html'
})
export class WidgetChart1SharedComponent implements OnInit {

  @Output() ready = new EventEmitter<any>();
  @Output() dataReady = new EventEmitter<any>();
  
  @Input() height:string;
  @Input() width:string;


  public uCalls = 0;
  data: object;
  handler: any;
  chartObj: any;
  charType = 'pie2d';
  isPieChart = true;
  dataSet = 1;

  public chartData: ISimpleChart1[] = [];

  private InitData: ISimpleChart1[] = [
    { Id: 0, Name: '', Value: 0 }
  ];

  constructor(private zone: NgZone, private service: DataService, private router: Router) {
  }

  ngOnInit(): void {
    this.DrawChart(this.InitData);
  }

  initialized($event) {
    console.log('chart initialized!');
    this.chartObj = $event.chart;
    this.attachEvent();
    this.ready.emit(null);
  }

  attachEvent() {
    this.handler = this.dataplotClickHandler.bind(this);
    this.chartObj.addEventListener('dataplotClick', this.handler);
  }

  dataplotClickHandler(eventObj, dataObj) {
    this.zone.run(() => {
      console.log(this.chartData[dataObj.index]);
      this.useThis(this.chartData[dataObj.index].Id);
    });
  }

  useThis(id: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        'id': id,
        'dataset': this.dataSet
      }
    };
    this.router.navigate(['../lead-management/all-leads/'], navigationExtras);
  }

  public GetDataRange(accountId: number, d1: string, d2: string, dataset: SimpleChartDataSet) {
    let L1;

    if (dataset === SimpleChartDataSet.Channels) {
      this.dataSet = 1;
      L1 = this.service.getWidgetChart1(accountId, d1, d2);
    }
    if (dataset === SimpleChartDataSet.Sources) {
      this.dataSet = 2;
      L1 = this.service.getWidgetChart1B(accountId, d1, d2);
    }

    L1.subscribe((data: any) => {
      if (data) {
        this.chartData = data;
        this.DrawChart(data);
        this.dataReady.emit(null);
      } else {
        this.DrawChart(this.InitData);
      }
    },
      err => {
        this.DrawChart(this.InitData);
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private DrawChart(data: ISimpleChart1[]) {
    if (data === undefined) {
      return;
    }
    const dataSeries = [];
    let i = 0;
    data.forEach(element => {
      dataSeries.push({
        label: element.Name,
        value: element.Value
      });
      i++;
    });
    this.data = {
      chart: {
        // caption: "Title",
        // subCaption: "Last year",
        use3DLighting: '0',
        showPercentValues: '1',
        decimals: '1',
        useDataPlotColorForLabels: '1',
        theme: 'fusion'
      },
      data: dataSeries
    };
  }

  public toggleChartType() {
    this.isPieChart = !this.isPieChart;
    this.charType = this.isPieChart ? 'pie2d' : 'bar2d';
  }

}

export enum SimpleChartDataSet {
  Channels,
  Sources
}
