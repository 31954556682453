import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogComponent } from './log/log.component';
import { AngularMaterialModule } from '../angular-materials.module';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SaveTemplateComponent } from './save-template/save-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { MappingTableComponent } from './mapping-table/mapping-table.component';
import { FilterbarMaterialComponent } from './filterbar/filterbar.component';
import { DatePickerDialogComponent } from './filterbar/date-picker-dialog/date-picker-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DateRangeFilterComponent } from './filterbar/daterange-filter/daterange-filter.component';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';


@NgModule({
  declarations: [
    LogComponent,
    ConfirmationModalComponent,
    SaveTemplateComponent,
    SelectTemplateComponent,
    MappingTableComponent,
    FilterbarMaterialComponent,
    DatePickerDialogComponent,
    DateRangeFilterComponent,
    UpdateDialogComponent,
    PreviewTemplateComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AngularMaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    LogComponent,
    ConfirmationModalComponent,
    SaveTemplateComponent,
    SelectTemplateComponent,
    MappingTableComponent,
    FilterbarMaterialComponent,
    DateRangeFilterComponent
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatLegacySnackBar
  ]
})
export class SharedMaterialComponentsModule { }
