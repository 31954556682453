import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TemplatesService } from '../../../../data-services/endpoints/templates.service';
import { ITemplate, TemplateType } from '../../../../interfaces/ITemplate';
import { GenericValidator } from '../../../../utils/generic-validator';
import { debounceTime } from  'rxjs/operators';

@Component({
    selector: 'app-save-template',
    templateUrl: './save-template.component.html',
    styleUrls: ['./save-template.component.scss']
})

export class SaveTemplateComponent implements OnInit, OnChanges {
    @Input() enabled: boolean = false;
    @Input() templateType: TemplateType;
    @Input() content: string;
    @Input() direction: string = 'column';
    @Input() inputWidth: string = '100%';

    @Output() templateCreated = new EventEmitter<ITemplate>();

    public inputInfo: string = 'Template Name';
    public isSaving: boolean = false;
    public checked: boolean = false;
    public fxLayoutGap: string = '0px';

    public _form: UntypedFormGroup;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    constructor(private fb: UntypedFormBuilder, private snackBar: MatSnackBar,
      private templateService: TemplatesService) {
        this.validationMessages = {
            Name: {
              required: `Please enter ${this.inputInfo}.`,
              minlength: `${this.inputInfo} must be at least 1 character long.`,
              maxlength: `${this.inputInfo} must have less than 50 characters.`,
              notUnique: `${this.inputInfo} is already taken.`
            }
          };

          this._form = this.fb.group({
            Name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)], this.isTemplateNameUnique()],
          });

          this.genericValidator = new GenericValidator(this.validationMessages);
          this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
            this.displayMessage = this.genericValidator.processMessages(this._form);
          });
    }

    ngOnInit(): void {
        switch (this.templateType) {
            case TemplateType.Sms:
                this.inputInfo = `Sms ${this.inputInfo}`;
            break;
            case TemplateType.Email:
                this.inputInfo = `Email ${this.inputInfo}`;
            break;
        }

        if (this.direction === 'row') {
          this.fxLayoutGap = '15px';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.enabled?.currentValue === false) {
        this.checked = false;
      }
    }

    private showErrorBar(): void {
      this.snackBar.open('An error was detected.', 'Retry', { duration: 3000 });
    }

    saveTemplate(): void {
      if (this._form.valid && this.content) {
        this.isSaving = true;
        this.snackBar.open('Saving...', '');
        const template: ITemplate = Object.assign({}, this._form.value);
        template.Content = this.content;
        template.TemplateType = this.templateType;
        this.templateService.addTemplate(template).subscribe((templateAdded: ITemplate) => {
          this.snackBar.open(`${TemplateType[this.templateType]} template saved.`, 'Close', { duration: 3000 });
          this.isSaving = false;
          this.templateCreated.emit(templateAdded);

        },
          err => {
            this.showErrorBar();
            this.isSaving = false;
          });
      }
    }

    private isTemplateNameUnique(): AsyncValidatorFn {
      return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> | Observable<{ [key: string]: boolean } | null> => {
        if (control.value && control.value.replace(/\s+/g, '').length > 0) {
          return from(this.templateService.getTemplateByName(this.templateType, control.value)).pipe(
            map(
              available => {
                return available ? null : { 'notUnique': true };
              }
            )
          );
        } else {
          return null;
        }
      };
    }
}
