import * as moment from 'moment';
import * as momenttz from 'moment-timezone';

export interface IAppTimeZone {
  Name: string;
  Offset: number;
  Id: number;
}

export function ToUTCDateTime(date: Date) {
  return momenttz(moment.utc(date)).format('lll');
}

export function ToLocalDateTime(date: Date, timeZoneName: string) {
  return momenttz(moment.utc(date)).tz(timeZoneName).format('lll');
}

export function ToLocalDateTimeLong(date: Date, timeZoneName: string) {
  return momenttz(moment.utc(date)).tz(timeZoneName).format('LLLL');
}

export function ToLocalDateTimeShort(date: Date, timeZoneName: string) {
  return momenttz(moment.utc(date)).tz(timeZoneName).format('MM/DD/YYYY hh:mm:ss a');
}

export function ToLocalDateOnly(date: Date, timeZoneName: string) {
  return momenttz(moment.utc(date)).tz(timeZoneName).format('MM/DD/YYYY');
}

export function ToLocalTimeOnly(date: Date, timeZoneName: string) {
  return momenttz(moment.utc(date)).tz(timeZoneName).format('hh:mm:ss a');
}

export function ToFormatDateTime(date: Date) {
  if (date == null) { return ''; }
   return moment(date).format('L');
}

export function ToISODate(date: Date) {
  if (!moment.isDate(date)) { return ''; }
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

// this function generate errors in production!!!!
export function DateTimePickerCorrection(date: any) {
  return moment(date).format('MM/DD/YYYY hh:mm:ss a');
}
