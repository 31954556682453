import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { ToLocalDateTime } from '../../../interfaces/AppTimeZone';
import { IErrorLog } from '../../../interfaces/ErrorLog';
import { ILeadsView1 } from '../../../interfaces/Views';
import { UserMessageComponent } from '../user-message/user-message.component';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls:['./leads.component.scss']
})
export class LeadsComponent implements AfterViewInit {
  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @Output() dateReady = new EventEmitter<any>();

  public leadId = 0;
  // data
  public itemsList: ILeadsView1[];
  public selectedItem: ILeadsView1;

  constructor(private service: DataService, private authService: AppAuthService, private router: Router) {

  }

  ngAfterViewInit(): void {
  }

  public getData(accountId: number) {
    const l1 = this.service.getNewLeads(accountId,50);
    l1.subscribe((data) => {
      console.log(data);
      this.itemsList = data;
      this.dateReady.emit(null);
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  public getDataRange(accountId: number,  d1: string, d2: string) {
    const l1 = this.service.getNewLeadsRange(accountId, d1, d2);
    l1.subscribe((data) => {
      console.log(data);
      this.itemsList = data;
      this.dateReady.emit(null);
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  toLocalDateTime(date: Date) {
    const zoneName = this.authService.AccountMember.AppTimeZoneName;
    return ToLocalDateTime(date, zoneName);
  }

  trackByFn(index, item: ILeadsView1) {
    if (!item) { return null; }
    return item.Id;
  }

  public editItem(item: ILeadsView1) {
    this.useThis(item.Id);
  }

  useThis(id: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        'id': id
      }
    };
    this.router.navigate(['../lead-management/lead-details/'], navigationExtras);
  }

}
