
export interface ISignalRNotification {
    date: string;
    notificationType: SignalRNotificationType;
    title: string;
    content: string;
    autoClose: boolean;
    isHtmlContent: boolean;
    dataText: string;
    dataNumber: number;
}

export enum SignalRNotificationType {
    Error = 0,
    Info,
    Success,
    Warning
}

export interface ISignalRMessage {
    messageType: SignalRMessageType;
    message1: string;
    message2: string;
    message3: string;
    param1: number;
    param2: number;
    param3: number;
}

export enum SignalRMessageType {
    MessageBoxChanged = 0,
    LeadAssignmentChanged,
    ResponseTimeChanged,
    CurrentStepChanged,
    UnreadEmails,
    GlobalRefresh,
    NewSms,
    ConsentUpdated,
    EmailEventChanged,
    LeadReQualified
}
