import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Auth0Component } from './auth0.component';
import { CallbackComponent } from './callback/callback.component';
import { ErrorComponent } from './error/error.component';
import { AngularMaterialModule } from '../angular-materials/angular-materials.module';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { AuthComponentsModule } from '@nextech/auth0-components';
import { UserOrgComponent } from './user-org/user-org.component';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    AuthComponentsModule
  ],
  exports: [
    UserAvatarComponent,
    UserOrgComponent
  ],
  declarations: [
    Auth0Component,
    CallbackComponent,
    UserAvatarComponent,
    ErrorComponent,
    UserOrgComponent
  ]
})
export class Auth0Module { }
