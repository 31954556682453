export interface IFeatureFlag {
    Key: string;
    Enabled: boolean;
    Id: number;
    DateCreated: string;
    CreatedBy: string;
    UpdatedBy: string;
    Protected: boolean;
}

export const FeatureFlags = {
    futureFeatures: 0,  // deprecated OP-1989
    communicationsWorkflow: 1, // deprecated OP-1989
    communicationsSms: 2, // deprecated OP-1989
    communicationsNav: 3,
    leadCommPref: 4, // deprecated OP-1989
    communicationsMergeTags: 5,
    pmIntegration: 6,
    referralSourceMapping: 7,
    providersMapping: 8,
    locationsMapping: 9,
    appointmentsMapping: 10,
    appointmentSync: 11,
    unsubscribeMarketingEmail: 12,
    campaignManagementNav: 13, // deprecated OP-1989
    explicitConsent: 14,
    terminatingCondition: 15,
    showDefaultCampaign: 16,
    pmsFilterEmailBlast: 17,
    showOpportunitiesWidget: 18,
    leadConversations: 19,
    reportLiveChat: 20,
    terminationRuleWizard: 21,
    defaultEmailSender: 22,
    emailTemplates: 23, // deprecated verified on OP-1989
    settingsMaterial: 24, // deprecated OP-1989
    smsTemplates: 25, // deprecated verified on OP-1989
    automatedEmailBlasts: 26,
    campaignEmailBlasts: 27,
    emailModalMaterial: 28,
    cmsMaterial: 29,
    accountEmailTemplateMaterials: 30,
    searchByAreaCode: 31,
    cmsEmailStats: 32,
    auth0Migrated: 33
};
