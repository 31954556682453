import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ISection {
  sectionTitle: string;
  itemsList: ISectionItem[];
}
export interface ISectionItem {
  cardId: number;
  title: string;
  description: string;
  icon: string;
  enabled: boolean;
}
@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss']
})
export class GridMenuComponent implements OnInit {
  @Input() sections: ISection[] = [];
  @Output() select = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelectOption(id: number) {
    this.select.emit(id);
  }

}
