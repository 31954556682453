import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  public errId = 0;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
   this.errId = +this.route.snapshot.queryParamMap.get('err');
   console.log(this.errId);
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

}
