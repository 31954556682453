import { IPaginationResult } from './IPaginationFilter';

export interface ITemplate {
    Id: number;
    DateCreated: string;
    DateUpdated?: string;
    CreatedBy: string;
    UpdatedBy?: string;
    Protected: boolean;
    Name: string;
    TemplateType: TemplateType;
    Content: string;
}

export enum TemplateType {
    Sms = 1,
    Email = 2,
}
export interface ITemplatePaginationResult extends IPaginationResult {
    Templates: ITemplate[];
}
