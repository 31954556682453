import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, debounceTime, tap } from 'rxjs/operators';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { IAccountMember } from '../../../interfaces/Account';
import { ILogin } from '../../../interfaces/Login';
import { InternalRoles } from '../../../interfaces/roles-enum';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../../shared-components/user-message/user-message.component';
import { LeadDetailsRedirectionService } from '../lead-details-redirection/lead-details-redirection.service';
import { Auth0Settings } from 'src/app/interfaces/AppSettings';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: UntypedFormGroup;
  errorMessage = '';
  UnauthorizedMsg = false;
  canLogin = true;
  settings: Auth0Settings;

  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('clientInput') clientInput: ElementRef;

  get lastClientId(): string {
    return localStorage.getItem('MML-REMEMBER-CLIENTID') || '';
  }
  set lastClientId(value: string) {
    localStorage.setItem('MML-REMEMBER-CLIENTID', value);
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, private authService: AppAuthService, private dataService: DataService, private redirectLead: LeadDetailsRedirectionService, private route: ActivatedRoute) {
    this.validationMessages = {
      client: {
        required: 'Please enter your client Id.',
        pattern: 'Please enter a valid client Id.',
        minlength: 'Client Id must be 7 characters long.',
        maxlength: 'Client Id must be 7 characters long.'
      },
      email: {
        required: 'Please enter your email address.',
        pattern: 'Please enter a valid email address.'
      },
      password: {
        required: 'Please enter your password.',
        minlength: 'Password must be at least 8 characters.',
        maxlength: 'Password cannot exceed 50 characters.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.settings = AppSettingsService.Settings.auth0Settings;
  }

  ngOnInit() {
    var isforcedClassicLogin = this.isForcedClassicLogin();
 
    if (!isforcedClassicLogin) {
      const auth0OrgId = localStorage.getItem('auth0OrgId');
      if (auth0OrgId) {
        window.location.replace(`${this.settings.appUri}/auth0?organization=${auth0OrgId}&redirectUrl=/dashboard`);
        return;
      }

      const auth0HasLoggedIn = localStorage.getItem('auth0_hasLoggedIn');
      if (auth0HasLoggedIn == "true") {
        window.location.replace(`${this.settings.universalLoginUrl}`);
      }
    }

    this.authService.logout();
    this.errorMessage = '';
    this.loginForm = this.fb.group({
      client: [this.lastClientId,
      [Validators.required, Validators.pattern('^[A-Za-z]+[0-9]{3,3}$'), Validators.minLength(7), Validators.maxLength(7)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]]
    });
    this.loginForm.valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });
    this.loginForm.controls['email'].valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
      if (value !== undefined) {
        const email = value as string;
        this.loginForm.controls['email'].setValue(email.toLowerCase());
      }
    });
  }

  private isForcedClassicLogin() {
    const queryParams = this.route.snapshot.queryParams;
    const forceMode = queryParams['force'];

    if (forceMode?.toLowerCase() === 'classic') {
      localStorage.removeItem('auth0_hasLoggedIn');
      return true;
    }

    return false;
  }

  redirectToLeadDetails() {
    this.redirectLead.redirectLeadFromLogin();
    this.redirectLead.redirect = false;
  }

  redirect() {
    if (this.redirectLead.redirect) {
      this.redirectToLeadDetails();
    } else {
      this.router.navigateByUrl(this.authService.redirectUrl);
      this.redirectLead.redirect = false;
    }
  }

  redirectToDashboard() {
    if (this.redirectLead.redirect) {
      this.redirectToLeadDetails();
    } else {
      this.router.navigate(['/dashboard']); // user role1
      this.redirectLead.redirect = false;
    }
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  public forgottenPassword() {
    this.router.navigate(['../forgot/']);
  }

  login() {
    if (this.loginForm && this.loginForm.valid) {
      this.canLogin = false;
      const { client, email, password } = this.loginForm.value;
      this.tryLogin(client, email, password);
    } else {
      this.UnauthorizedMsg = true;
    }
  }

  tryLogin(client: string, email: string, pass: string) {
    this.errorMessage = '';
    const login: ILogin = {
      UserName: email,
      Email: email,
      Password: pass
    };
    this.authService.loginService(login, client).pipe(
      tap(session => {
        if(session.LoginHintEmail && session.LoginHintEmail === login.Email){
          const email = encodeURIComponent(session.LoginHintEmail);
          if(session.HasError){
            const appUrl = encodeURIComponent(this.settings.appUri);
            this.replaceWindowLocation(`${this.settings.universalLoginUrl}/login-notification?error&returnPath=${appUrl}`);
          }
          else{
            this.replaceWindowLocation(`${this.settings.universalLoginUrl}/login-notification?loginhint=${email}`);
          }
        }

        this.authService.setClientId(client);
        this.authService.setToken(session.Token, session.Expiration);
        this.lastClientId = client;
        this.authService.setSessionEmail(session.User);
      }),
      concatMap(accountMember => this.authService.getAccountByEmail(accountMember.User)),
      tap(accountMember => {
        this.authService.AccountMember = accountMember;
        this.authService.setSessionAccountMember(accountMember);
      }),
      concatMap(account => this.authService.getAccount(account.AccountId)),
      tap(account => {
        this.authService.Account = account;
        this.authService.setSessionAccount(account);
      }),
      concatMap(guards => this.dataService.getAppGuardDefinitions()),
      tap(guards => {
        this.authService.guards = guards;
        this.authService.setGuards(guards);
      }),
    ).subscribe(response => {
      const session = this.authService.getSession();
      this.onGetUserComplete(session.accountMember);
    },
      err => {
        console.log(err);
        this.errorMessage = err.error_description;
        this.UnauthorizedMsg = true;
        setTimeout(() => {
          this.canLogin = true;
        }, 100);
      },
      () => {
        console.log('try login completed');
      });
  }

  onGetUserComplete(member: IAccountMember) {
    if (!this.authService.Account.IsActive) {
      this.router.navigate(['/unauthorized'], { queryParams: { err: 5 } });
      return;
    }

    this.authService.login(member.EMail, member.InternalRole, member); // login in authService

    if (!member.ActivateAccountCompleted) { // complete activate account
      this.router.navigate(['/activate']); // admin app USER
      return;
    }

    if (!member.IsActive) {
      this.router.navigate(['/unauthorized'], { queryParams: { err: 4 } });
      return;
    }

    if (member.InternalRole === InternalRoles.UserAdmin) {
      if (this.authService.redirectUrl) {
        this.redirect();
      } else {
        this.redirectToDashboard(); // admin app USER
      }
      return;
    }

    if (member.InternalRole === InternalRoles.UserRole1) {
      if (this.authService.redirectUrl) {
        this.redirect();
      } else {
        this.redirectToDashboard();
      }
      return;
    }
    // if no role selected logout
    this.errorMessage = 'No configuration for user role.';
    this.authService.logout();
  }

  replaceWindowLocation(url: string): void {
    window.location.replace(url);
  }

  private setFocus(): void {
    if (this.loginForm.value.client === '') {
      this.clientInput.nativeElement.focus();
    } else {
      this.emailInput.nativeElement.focus();
    }
  }
}
