import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSummernoteDirective } from 'ngx-summernote';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { IAccount } from '../../../interfaces/Account';
import { AppEmailContentType, AppEmailDirection, AppEmailType, IAppEmail } from '../../../interfaces/AppEmail';
import { IDataEvent } from '../../../interfaces/DataEventEnum';
import { IErrorLog, LogClientError } from '../../../interfaces/ErrorLog';
import { FormState } from '../../../interfaces/FormState';
import { ISimpleLead } from '../../../interfaces/Lead';
import { ILeadIncomingEMail } from '../../../interfaces/LeadIncomingEmail';
import { IMessageBox, MessageDirectionEnum } from '../../../interfaces/MessageBox';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../user-message/user-message.component';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-email-shared',
  templateUrl: './email-shared.component.html'
})
export class EmailSharedComponent implements OnInit {
  @ViewChild(NgxSummernoteDirective) summerNote: NgxSummernoteDirective;
  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;

  @ViewChild('myModal') public myModal: ModalDirective;
  @Output() modalDataChanged = new EventEmitter<IDataEvent>();
  @Input() account: IAccount;
  @Input() leadId = 0;

  ready = false;
  // form
  public windowLayout = MessageDirectionEnum.Outgoing;

  public formState = FormState.Error;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  _form: UntypedFormGroup;
  get FormState() { return FormState; } // to expose enum values, not the actual formstate
  get MessageDirection() { return MessageDirectionEnum; }
  // data
  public selectedLead: ISimpleLead;

  public selectedAppEmail: IAppEmail;
  public selectedLeadEmail: ILeadIncomingEMail;

  public subjectFocus = false;

  config = {
    disable: true,
    placeholder: '',
    tabsize: 2,
    height: '250px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']]
    ],
    popover: {
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ]
    },
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  content = '';
  public editorDisable = false;

  constructor(private service: DataService, private authService: AppAuthService, private fb: UntypedFormBuilder) {
    this.validationMessages = {
      Subject: {
        required: 'Please enter field.',
        minlength: 'Field value must be at least 1 characters.',
        maxlength: 'Field value must be less than 250 characters.'
      },
      Content: {
        required: 'Please enter field.',
        minlength: 'Please enter field.',
        maxlength: 'Field value must be less than 10,000 characters.'
      },
      NotifyError: {
      },
      NotifyDelivered: {
      },
      NotifyOpened: {
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this._form = this.fb.group({
      Subject: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      Content: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10000)]],
      NotifyError: ['', []],
      NotifyDelivered: ['', []],
      NotifyOpened: ['', []]
    });
  this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
	this.displayMessage = this.genericValidator.processMessages(this._form);
});
  }

  public modalShown() {
    console.log('Modal Shown');
    this.subjectFocus = true;
  }

  public modalClosed() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedAppEmail = null;
    this.myModal.hide();
  }

  public addEntity() {
    console.log('Add Email');
    this.content = '';
    this.formState = FormState.isNew;
    this.editorDisable = false;
    this.getInitialEmailData(this.leadId);
  }

  public editEntity(entity: IMessageBox) {
    console.log('Edit Email');
    this.content = '';
    this.formState = FormState.isEdit;
    this.editorDisable = true;
    if (entity.Direction === MessageDirectionEnum.Outgoing) {
      this.windowLayout = MessageDirectionEnum.Outgoing;
      this.getAppEmail(entity.AppEmailId);
    } else if (entity.Direction === MessageDirectionEnum.Incoming) {
      this.windowLayout = MessageDirectionEnum.Incoming;
      this.getLeadIncomingEMail(entity.LeadIncomingEMailId);
    }

  }

  private getInitialEmailData(id: number) {
    this.ready = false;
    this.userMessage.getData();
    const l1 = this.service.getSimpleLead(id);
    l1.subscribe(data => {
      this.selectedLead = data;
      if (data.EMail.trim().length > 0) {
        this.populateModalForm();
        this.userMessage.clear();
      } else {
        alert('Invalid email address.');
      }
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private getAppEmail(id: number) {
    this.ready = false;
    this.userMessage.getData();
    const l1 = this.service.getAppEmail(id);
    l1.subscribe(data => {
      this.selectedAppEmail = data;
      this.userMessage.clear();
      this.populateModalForm();
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private getLeadIncomingEMail(id: number) {
    this.ready = false;
    this.userMessage.getData();
    const l1 = this.service.getLeadIncomingEmail(id);
    l1.subscribe(data => {
      this.selectedLeadEmail = data;
      this.userMessage.clear();
      this.populateModalForm();
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private populateModalForm() {
    this._form.reset();
    if (this.formState === FormState.isNew) {

      this._form.controls['NotifyError'].patchValue(true);
      this._form.controls['NotifyDelivered'].patchValue(false);
      this._form.controls['NotifyOpened'].patchValue(false);
      this._form.controls['Subject'].enable({ onlySelf: true });
      this._form.markAsPristine();

    } else if (this.formState === FormState.isEdit) {
      this._form.controls['Subject'].disable({ onlySelf: true });

      if (this.windowLayout === MessageDirectionEnum.Outgoing) {
        this._form.patchValue(this.selectedAppEmail);
      } else if (this.windowLayout === MessageDirectionEnum.Incoming) {
        this._form.patchValue(this.selectedLeadEmail);
      }

      this._form.markAsPristine();

    }
    this.myModal.show();
    this.ready = true;
  }

  public modalSave() {
    console.log('SAVE');
    console.log(this.formState);
    if (this._form.valid) {

      const entity: IAppEmail = {
        Id: 0,
        LeadId: this.selectedLead.Id,
        Direction: AppEmailDirection.Outbound,
        AccountMemberFromId: this.authService.AccountMember.Id,
        AppEmailType: AppEmailType.AppEmail,
        ContentType: AppEmailContentType.Html,
        Content: this._form.controls['Content'].value,
        To: this.selectedLead.EMail,
        ReplyTo: this.authService.AccountMember.EMail,
        From: 'AdminEmail',
        FromName: `${this.authService.AccountMember.FirstName} ${this.authService.AccountMember.LastName}`,
        Subject: this._form.controls['Subject'].value,
        NotifyDelivered: this._form.controls['NotifyDelivered'].value,
        NotifyOpened: this._form.controls['NotifyOpened'].value,
        NotifyError: this._form.controls['NotifyError'].value
      };

      if (this.formState === FormState.isNew) {
        this.insertEntity(entity);
      } else if (this.formState === FormState.isEdit) {
        // this.updateEntity(entity);
      }
    }
  }

  public modalClose() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedAppEmail = null;
    this.subjectFocus = false;
    this.myModal.hide();
  }

  private insertEntity(entity: IAppEmail) {
    this.userMessage.saveData();
    this._form.markAsPristine(); // to disable save button
    this.service.insertAppEmail(entity)
      .subscribe(
        (data) => {
          this.selectedAppEmail = data;
          this.formState = FormState.isEdit;
          this.userMessage.saveSucceded();
          this.modalClosed();
        },
        err => {
          // this.modalClosed();
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

  modelchange($event) {
    this.content = $event;
  }

  public getDataSignature() {
    console.log('get signature');
    const l1 = this.service.getAccountMemberSignatureByAccountMemberId(this.authService.AccountMember.Id);
    l1.subscribe(data1 => {
      if (data1.Signature.trim().length > 0) {
        this.content = this.content + ' <br><br><br><br>' + data1.Signature;
        this.summerNote.summernoteModel = this.content;
      }
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(err);
          if (error && error.FriendlyMsg) {
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            this.userMessage.showError('Client Error ' + err.message, err.status);
            LogClientError(err, this.service);
          }
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

}
