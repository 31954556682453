import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import { DataService } from '../../../data-services/data.service';
import { IErrorLog } from '../../../interfaces/ErrorLog';

@Component({
  selector: 'app-call-player-mini',
  templateUrl: './call-player-mini.component.html'
})
export class CallPlayerMiniComponent implements OnInit, OnDestroy {
  public showControls = false;
  public playing = false;
  public ready = false;
  public noRecording = false;
  @Input() callId = 0;

  wave: WaveSurfer = null;
  url = '';

  constructor(private cdr: ChangeDetectorRef, private service: DataService) { }

  ngOnInit(): void {
    if (this.callId > 0) {
      this.getRecordingUrl(this.callId);
    }
  }

  ngOnDestroy(): void {
    console.log('destroy called!');
    this.ready = false;
    if (this.wave) {
      this.wave.stop();
      this.wave = null;
    }
  }

  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wave = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#D9DCFF',
        progressColor: '#4353FF',
        cursorColor: '#4353FF',
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 1,
        height: 100,
        barGap: 2,
        // waveColor: 'blue',
        // progressColor: 'green',
        // barWidth: 2,
        // barHeight: 1,
        // barGap: null,
        plugins: [
          TimelinePlugin.create({
            container: '#wave-timeline'
          })
        ]
      });

      this.wave.on('ready', () => {
        this.ready = true;
        this.showControls = true;
        this.onPlayPause();
      });

      this.wave.on('seek', () => {
        this.playing = this.wave.isPlaying();
        console.log(this.playing);
      });

      this.wave.on('finish', () => {
        this.playing = false;
        this.wave.seekTo(0);
        this.cdr.detectChanges();
      });
    });
  }

  onPreviewPressed(): void {
    if (this.url.length <= 0) {
      this.ready = true;
      this.noRecording = true;
      return;
    }
    if (!this.wave) {
      console.log('generating wave form');
      this.generateWaveform();
    }
    this.cdr.detectChanges();
    Promise.resolve().then(() => this.wave.load(this.url));
  }

  onPlayPause(): void {
    this.wave.playPause();
    this.playing = this.wave.isPlaying();
    console.log(this.playing);
  }

  private getRecordingUrl(callId: number) {
    const l1 = this.service.getCallRecordingUrl(callId);
    l1.subscribe(data => {
      if (data) {
        if (data.Value) {
          this.url = data.Value;
          if (this.url.trim().length > 0) {
            this.onPreviewPressed();
          } else {
            console.log('No recording found');
            this.ready = true;
            this.noRecording = true;
          }
        } else {
          this.ready = true;
          this.noRecording = true;
        }
      } else {
        console.log('No recording found');
        this.ready = true;
        this.noRecording = true;
      }
    }, err => {
      this.ready = true;
      this.noRecording = true;
      if (err instanceof HttpErrorResponse) {
        const error: IErrorLog = err.error;
        console.log(error);
      } else {
        console.log(err);
      }
    });
  }

}
