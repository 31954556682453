// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nxt-avatar {
  padding-right: 8px;
  padding-left: 8px;
}

.avatar-menu .mat-mdc-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.avatar-menu {
  --mat-menu-container-color: #fff;
  color: rgba(0, 0, 0, 0.8705882353);
}

.avatar-menu .avatar-menu-text {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
}

.avatar-menu hr {
  margin: 0.5em 0;
}

.avatar-menu .avatar-menu-button .mdc-list-item__primary-text {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/auth0/user-avatar/user-avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AAEA;EACI,gCAAA;EACA,kCAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".nxt-avatar {\r\n    padding-right: 8px;\r\n    padding-left: 8px;\r\n}\r\n\r\n.avatar-menu .mat-mdc-menu-item:hover:not([disabled]) {\r\n    background: rgba(0,0,0,.04)\r\n}\r\n\r\n.avatar-menu {\r\n    --mat-menu-container-color: #fff;\r\n    color: #000000de\r\n}\r\n\r\n.avatar-menu .avatar-menu-text {\r\n    margin-left: 15px;\r\n    margin-right: 15px;\r\n    font-size: 16px\r\n}\r\n\r\n.avatar-menu hr {\r\n    margin: .5em 0;\r\n}\r\n\r\n.avatar-menu .avatar-menu-button .mdc-list-item__primary-text {\r\n    font-size: 16px\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
