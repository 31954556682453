// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  outline-style: none;
  outline-width: 0px;
  background-color: white !important;
  color: black !important;
  font-weight: bolder !important;
  opacity: 0.65;
  text-align: left;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  cursor: default;
}

.ng-select.custom .ng-select-container {
  border-radius: 0;
}

:host-context(.with-border) label {
  border-color: #f0f3f5 !important;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/views/shared-components/multiselect-checkbox/multiselect-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,kCAAA;EACA,uBAAA;EACA,8BAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gCAAA;EACA,6BAAA;EACA,sBAAA;AACF","sourcesContent":[".label {\r\n  outline-style: none; \r\n  outline-width: 0px; \r\n  background-color: white !important; \r\n  color: black !important;\r\n  font-weight: bolder !important;\r\n  opacity: 0.65;\r\n  text-align: left;\r\n  display: inline-block;\r\n  padding: 0.375rem 0.75rem;\r\n  font-size: 0.875rem;\r\n  line-height: 1.5;\r\n  cursor: default;\r\n}\r\n\r\n.ng-select.custom .ng-select-container {\r\n  border-radius: 0;\r\n}\r\n\r\n:host-context(.with-border) label {\r\n  border-color: #f0f3f5 !important;\r\n  border: 1px solid transparent;\r\n  border-radius: 0.25rem;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
