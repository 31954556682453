import { Component, Input, OnInit } from '@angular/core';
import { AppAuthService } from '../../../app-auth.service';
import { ToLocalDateTime } from '../../../interfaces/AppTimeZone';

@Component({
  selector: 'app-log-info',
  templateUrl: './log-info.component.html'
})
export class LogInfoComponent implements OnInit {
  @Input() entity: any;

  constructor(private authService: AppAuthService) { }

  ngOnInit() {
  }

  public ToLocalDateTime(date: Date) {
    if (date === null) { return 'not available'; }
    if ( this.authService.ActivateMercury ) {
      return ToLocalDateTime(date, this.authService.MercuryTimeZoneName);
      } else {
        return ToLocalDateTime(date, this.authService.AccountMember.AppTimeZoneName);
      }
  }

}
