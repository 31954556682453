import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../../../data-services/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LeadDetailsRedirectionService {

  private baseUrl = AppSettingsService.Settings.baseUrl;

  id: string = undefined;
  clientId: string = undefined;
  isLoggedIn = false;
  redirect = false;

  constructor(private router: Router, private http: HttpClient) { }

  decryptLeadId() {
    const api = '/Leads/';
      return this.http.get<string>(`${this.baseUrl}${api}${this.id}`)
  }

  login() {
    this.router.navigate(['/login']);
  }

  unauthorized() {
    this.router.navigate(['/unauthorized']);
  }

  leadDetails() {
    this.decryptLeadId().subscribe((leadId) => {

      const options: NavigationExtras = {
        queryParams: {
          'id': leadId
        }
      }

      this.router.navigate(['../lead-management', 'lead-details'], options);
    }, (error) => {
      console.log(error);
      this.unauthorized();
    });
  }

  redirectLeadFromLogin() {
    if (this.id && this.clientId) {
      this.leadDetails();
    }
  }
}
