import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { IAccount, IAccountMember } from '../../../interfaces/Account';
import { ToLocalDateTime } from '../../../interfaces/AppTimeZone';
import { isProduction } from '../../../interfaces/consts';
import { DataEventSource, IDataEvent } from '../../../interfaces/DataEventEnum';
import { IEntity } from '../../../interfaces/Entity';
import { IErrorLog } from '../../../interfaces/ErrorLog';
import { FormState } from '../../../interfaces/FormState';
import { IReminder, ReminderStatus, ReminderType } from '../../../interfaces/Reminder';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../user-message/user-message.component';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-reminder-shared',
  templateUrl: './reminder-shared.component.html'
})
export class ReminderSharedComponent implements OnInit {

  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @ViewChild('myModal') public myModal: ModalDirective;
  @Output() modalDataChanged = new EventEmitter<IDataEvent>();
  @Input() account: IAccount;
  @Input() leadId = 0;
  public startAt = new Date(new Date().setHours(0, 0, 0, 0));
  public startAtLD = new Date();

  ready = false;
  // form
  public formState = FormState.Error;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  _form: UntypedFormGroup;
  get FormState() { return FormState; } // to expose enum values, not the actual formstate
  public dateTimeFocus = false;
  // data
  public selectedEntity: IReminder;
  public accountMemberList: IAccountMember[] = [];

  constructor(private service: DataService, private authService: AppAuthService, private fb: UntypedFormBuilder) {
    this.validationMessages = {
      Content: {
        required: 'Please enter reminder content.',
        minlength: 'Reminder content must be at least 1 characters.',
        maxlength: 'Reminder content must be a maximum of 2000 characters.'
      },
      ScheduleDateTime: {
        required: 'Please select a Schedule date and time',
      },
      AccountMemberToId: {
        required: 'Please enter field',
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this._form = this.fb.group({
      Content: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(2000)]],
      ScheduleDateTime: ['', [Validators.required]],
      AccountMemberToId: ['', [Validators.required]],
    });
  this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
	this.displayMessage = this.genericValidator.processMessages(this._form);
});
  }

  public modalShown() {
    console.log('Modal Shown');
    this.startAtLD = new Date();
    this.dateTimeFocus = true;
  }

  public modalClosed() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedEntity = null;
    this.myModal.hide();
  }

  public addEntity() {
    console.log('Add Location');
    this.formState = FormState.isNew;
    this.getEntity(0);
    this.myModal.show();
  }

  public editEntity(entity: IEntity) {
    console.log('Edit Location');
    this.formState = FormState.isEdit;
    this.getEntity(entity.Id);
    this.myModal.show();
  }

  private getEntity(id: number) {
    this.dateTimeFocus = false;
    this.ready = false;
    this.userMessage.getData();
    const l1 = this.service.getReminder(id);
    const l2 = this.service.getAccountMembers(this.authService.Account.Id);
    forkJoin([l1, l2]).subscribe(([data1, data2]) => {
      console.log(data1);
      console.log(data2);
      this.accountMemberList = data2.filter(e => e.IsActive && !e.IsAutoAdmin);
      this.selectedEntity = data1;
      this.populateModalForm();
      this.userMessage.clear();
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private populateModalForm() {
    this._form.reset();
    this._form.patchValue(this.selectedEntity);
    const date: Date = new Date();
    this._form.patchValue({ ScheduleDateTime: date });
    this._form.patchValue({  AccountMemberToId: this.authService.AccountMember.Id });

    this._form.markAsPristine();

    if (this.formState === FormState.isNew) {

    } else if (this.formState === FormState.isEdit) {

    }
    this.myModal.show();
    this.ready = true;
  }

  public modalSave() {
    console.log('SAVE');
    console.log(this.formState);
    if (this._form.valid) {
      const entity: IReminder = Object.assign({}, this.selectedEntity, this._form.value);
      entity.ScheduleDateTime = this._form.controls['ScheduleDateTime'].value;
      entity.AccountMemberId = this.authService.AccountMember.Id;
      entity.AccountMemberToId = this._form.controls['AccountMemberToId'].value;
      entity.LeadId = this.leadId;
      entity.ReminderStatus = ReminderStatus.Initializing;
      entity.ReminderType = ReminderType.UserReminder;
      entity.ScheduleDateTime = this.toLocalDateTime(entity.ScheduleDateTime);
      console.log(entity);
      if (this.formState === FormState.isNew) {
        console.log('insert');
        this.insertEntity(entity);
      } else if (this.formState === FormState.isEdit) {
        console.log('update');
        // this.updateEntity(entity);
      }
    }
  }

  toLocalDateTime(date: any) {
     if ( isProduction ) { return date; }
    const zoneName = this.authService.AccountMember.AppTimeZoneName;
    return ToLocalDateTime(date, zoneName);
  }

  public modalClose() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedEntity = null;
    this.myModal.hide();
  }

  private insertEntity(entity: IReminder) {
    this.userMessage.saveData();
    this._form.markAsPristine(); // to disable save button
    this.service.insertReminder(this.leadId, entity)
      .subscribe(
        (data) => {
          this.selectedEntity = data;
          this.formState = FormState.isEdit;
          this.userMessage.saveSucceded();
          this.modalClosed();
        },
        err => {
          // this.modalClosed();
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

  private updateEntity(entity: IEntity) {
    this.userMessage.saveData();
    this._form.markAsPristine(); // to disable save button
    this.service.updateEntity(entity)
      .subscribe(
        (data) => {
          this.selectedEntity = data;
          this.formState = FormState.isEdit;
          this.userMessage.saveSucceded();
          const modalEvent: IDataEvent = { Account: this.account, Entity: this.selectedEntity, Type: DataEventSource.Locations, AccountId: this.account.Id };
          this.modalDataChanged.emit(modalEvent);
          this.modalClosed();
        },
        err => {
          // this.modalClosed();
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

}
