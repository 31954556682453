import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaginationFilterParams } from '../../interfaces/IPaginationFilter';
import { ITemplate, ITemplatePaginationResult } from '../../interfaces/ITemplate';
import { AppSettingsService } from '../app-settings.service';

@Injectable({
    providedIn: 'root',
})
export class TemplatesService {

    private _baseUrl = AppSettingsService.Settings.baseUrl;

    constructor(private _http: HttpClient) { }

    public getTemplate(templateId: number): Observable<ITemplate> {
        const api = '/Templates';
        return this._http.get<ITemplate>(`${this._baseUrl}${api}/${templateId}`);
    }

    public getTemplateByName(templateType: number, templateName: string): Observable<boolean> {
        const api = '/Templates/NameAvailable';
        return this._http.get<boolean>(`${this._baseUrl}${api}?templateType=${templateType}&name=${templateName}`);
    }

    public getAllTemplates(templateType: number): Observable<ITemplate[]> {
        const api = `/Templates?templateType=${templateType}`;
        return this._http.get<ITemplate[]>(`${this._baseUrl}${api}`);
    }

    public getTemplates(templateType: number, paginationFilterParams: IPaginationFilterParams): Observable<ITemplatePaginationResult> {
        const api = `/Templates/page?templateType=${templateType}`;
        return this._http.get<ITemplatePaginationResult>(`${this._baseUrl}${api}`, {
            params: {
                pageNumber: paginationFilterParams.PageNumber,
                pageSize: paginationFilterParams.PageSize,
                sortBy: paginationFilterParams.SortBy,
                descending: paginationFilterParams.Descending,
                searchValue: paginationFilterParams.SearchValue
            }
        });
    }

    public addTemplate(template: ITemplate): Observable<ITemplate> {
        const api = '/Templates';
        return this._http.post<ITemplate>(`${this._baseUrl}${api}`, template);
    }

    public updateTemplate(template: ITemplate): Observable<ITemplate> {
        const api = '/Templates';
        return this._http.put<ITemplate>(`${this._baseUrl}${api}/${template.Id}`, template);
    }

    public deleteTemplate(templateId: number): Observable<any> {
        const api = '/Templates';
        return this._http.delete(`${this._baseUrl}${api}/${templateId}`);
    }
}
