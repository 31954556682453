import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';
import { Auth0Settings } from 'src/app/interfaces/AppSettings';

@Component({
  selector: 'app-user-org',
  templateUrl: './user-org.component.html',
  styleUrl: './user-org.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UserOrgComponent implements OnInit{
  nextechAuth0ApiUrl: string;
  stringEnableList: string;
  settings: Auth0Settings;

  constructor(private router: Router) {
  }

  ngOnInit() {
    // We need to reload the user-org component at each page
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.settings = AppSettingsService.Settings.auth0Settings;
    this.nextechAuth0ApiUrl = this.settings.auth0ApiBaseUrl + `/api`;
    // To show org switcher in enabled pages only
    this.stringEnableList = this.settings.nlmStringEnableList;
  }
}
