import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneToDisplay } from '../utils/phone-numbers-utils';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, args?: string): unknown {
    try {
      return formatPhoneToDisplay(value);
    } catch (error) {
      return value;
    }
  }

}
