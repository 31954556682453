// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  background-color: #E9F5FB !important;
}

mat-form-field {
  width: 100%;
  line-height: 17px;
}

::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/views/angular-materials/shared-components/mapping-table/mapping-table.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AAEA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".selected {\r\n    background-color: #E9F5FB !important;\r\n}\r\n\r\nmat-form-field {\r\n    width: 100%;\r\n    line-height: 17px;\r\n}\r\n\r\n::ng-deep .mat-form-field-wrapper{\r\n    padding-bottom: 0;\r\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
