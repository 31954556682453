import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_SNACK_BAR_DATA, MatLegacySnackBar as MatSnackBar,  } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
  })
export class SnackbarComponent { 
    constructor(@Inject(MAT_LEGACY_SNACK_BAR_DATA) public data: any,
                public snackBar: MatSnackBar) {
    }
  }