import { IAccount, IAccountMember } from '../../app/interfaces/Account';

declare global {
    interface Window {
      pendo: any;
    }
}

function installPendo(apiKey: string) {
    (function(p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {(function(m) {
            o[m] = o[m] || function() {o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); }; })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName('head')[0];
        z.appendChild(y);
    })(window, document, 'script', 'pendo');
}

function initializePendo(account: IAccount, accountMember: IAccountMember, clientId: string) {
    window.pendo.initialize({
        visitor: {
            id: accountMember.EMail,
            first_name: accountMember.FirstName,
            last_name: accountMember.LastName,
            role: accountMember.ApplicationRoleName
        },
        account: {
            id: clientId.toUpperCase(),
            is_integrated: account.IsPmIntegrated,
            pm_system: account.PmsEmrName
        }
    });
}

export { initializePendo, installPendo };
