
export interface IReminder {
  AccountMemberId: number;
  LeadId: number;
  AccountMemberToId: number;
  Content: string;
  ReminderType: ReminderType;
  ReminderStatus: ReminderStatus;
  LastStatusChange: string;
  ScheduleDateTime: string;
  Confirmed: boolean;
  ConfirmedDateTime?: string;
  Job1TId: number;
  IsActive: boolean;
  Id: number;
  DateCreated: string;
  DateUpdated?: string;
  CreatedBy: string;
  UpdatedBy?: string;
  Protected: boolean;
}

export enum ReminderType {
    UserReminder,
    UserTaskDue,
    LeadAppointment
}

export enum ReminderStatus {
    Initializing,
    Scheduled,
    Confirmed,
    Canceled,
    Error
}
