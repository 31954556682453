import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TemplatesService } from '../../../../data-services/endpoints/templates.service';
import { ITemplate, TemplateType } from '../../../../interfaces/ITemplate';

@Component({
    selector: 'app-select-template',
    templateUrl: './select-template.component.html'
})

export class SelectTemplateComponent implements OnInit, OnChanges {

    @Input() templateType: TemplateType;
    @Input() newTemplate: ITemplate = null;
    @Input() disabled: boolean = false;
    @Input() inputWidth: string = '100%';
    @Output() templateSelected = new EventEmitter<ITemplate>();

    public inputInfo: string = 'Templates';
    public templateOptions: ITemplate[];

    constructor(private snackBar: MatSnackBar, private templateService: TemplatesService) {
    }

    ngOnInit(): void {
        switch (this.templateType) {
            case TemplateType.Sms:
                this.inputInfo = `SMS ${this.inputInfo}`;
            break;
            case TemplateType.Email:
                this.inputInfo = `Email ${this.inputInfo}`;
            break;
        }
        this.getTemplates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.newTemplate && changes.newTemplate.currentValue) {
            this.templateOptions.unshift(changes.newTemplate.currentValue);
        }
    }

    private showErrorBar(): void {
        this.snackBar.open('An error was detected.', 'Retry', { duration: 3000 });
      }

    private getTemplates(): void {
        this.templateService.getAllTemplates(this.templateType).subscribe((templates: ITemplate[]) => {
            this.templateOptions = templates;
        },
        err => {
            this.showErrorBar();
        });
    }

    public onTemplateSelected(selection: MatSelectChange): void {
        this.templateSelected.emit(selection.value);
    }
}
