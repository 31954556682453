import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppAuthService } from '../../../app-auth.service';
import { IAccountMember } from '../../../interfaces/Account';
import { IErrorLog } from '../../../interfaces/ErrorLog';
import { GenericValidator } from '../../../utils/generic-validator';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  createPasswordForm: UntypedFormGroup;

  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  passwordGroupMessage: string;
  isBusy = false;
  UnauthorizedMsg = false;
  UnauthorizedMsgDetails = '';
  public id = '';
  public email = '';
  private clientId: string;

  public ready = false;

  public get tooltipMessage(): SafeHtml {
    const htmlContent = `<p>Password must:</p>
    <ul style='text-align:left;margin-left:-20px;'><li>Not contain the username</li>
    <li>Not contain 'password'</li>
    <li>Be at least 9 characters long</li>
    <li>Does not exceed 50 characters</li>
    <li>Contain at least one number</li>
    <li>Contain at least one Upper and Lowercase letter</li>
    <li>Contain at least one special character (ex. !@#$%&*)</li>
    <li>Not have the same character repeated 3 times (ex. 111 or aaa)</li>
    <li>Not contain 3 consecutive sequential characters (ex. abc or 123)</li></ul>`;
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, private route: ActivatedRoute, private service: AppAuthService,
     private sanitizer: DomSanitizer) {
    this.validationMessages = {
      password: {
        required: 'Please enter your password.',
        passwordInvalid : ''
      },
      confirmPassword: {
        required: 'Please enter your password.',
        passwordInvalid : ''
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.isBusy = false;
    this.id = this.route.snapshot.queryParams['id'] || '';
    this.clientId = this.route.snapshot.queryParams['client'] || '';
    if (this.id === '' || this.clientId === '') {
      this.router.navigate(['/unauthorized']);
    }
    this.getUser(this.id);
    this.configForm();
  }

  configForm() {
    this.createPasswordForm = this.fb.group({
      passwordGroup: this.fb.group({
        password: ['', [Validators.required], [this.isPasswordValid()]],
        confirmPassword: ['', [Validators.required]],
    }, { validator: [passwordMatcher] }),
    });
    this.createPasswordForm.valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.createPasswordForm);
    });
    const controls = this.createPasswordForm.get('passwordGroup.password');
    controls.valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.setMessage(controls);
    });
  }

  getUser(encryptedId: string) {
    console.log(`service called for  ${encryptedId}`);
    this.service.getAccountMemberByEncryptedId(encryptedId, this.clientId)
      .subscribe(
        (data: IAccountMember) => {
          if (data != null) {
            if (data.IsValidForCreatePassword) {
              console.log(data);
              this.email = data.EMail;
              this.ready = true;
            } else {
              if (data.CreatePasswordCompleted) {
                this.router.navigate(['/unauthorized'], { queryParams: { err: 3 } });
              } else {
                this.router.navigate(['/unauthorized'], { queryParams: { err: 1 } });
              }
            }
          }
        },
        err => {
          this.router.navigate(['/unauthorized'], { queryParams: { err: 2 } });
        },
        () => {

        }
      );
  }

  register(event) {
    event.preventDefault();
    this.createAccountPasswordFromEmail();
  }

  createAccountPasswordFromEmail() {
    this.isBusy = true;
    const pass = this.createPasswordForm.get('passwordGroup.password').value;
    this.service.emailCanCreateAccountMember(this.email, pass, this.clientId)
      .subscribe(
        (data) => {
          console.log(data);
          this.router.navigate(['/login']);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.UnauthorizedMsg = true;
            this.UnauthorizedMsgDetails = error.FriendlyMsg;
          } else {
            console.log(err);
            this.UnauthorizedMsg = true;
            this.UnauthorizedMsgDetails = err;
          }
        },
        () => {
          this.isBusy = false;
          console.log('email can create account password completed');
        }
      );
  }

  setMessage(c: AbstractControl): void {
    this.passwordGroupMessage = '';
    if ((c.touched || c.dirty) && c.errors) {
      this.passwordGroupMessage = Object.keys(c.errors).map(key =>
        this.validationMessages[key]).join(' ');
    }
  }

  isPasswordValid(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const password = control.value;
      return this.service.validatePassword(this.email, password, this.clientId).pipe(map(result => {
          this.validationMessages.password.passwordInvalid = result.Message;
        return (result.Valid ? null : { passwordInvalid: true });
      }));
    };
  }
}

function passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
  const passwordControl = c.get('password');
  const confirmPasswordControl = c.get('confirmPassword');

  if (passwordControl.pristine || confirmPasswordControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmPasswordControl.value) {
    return null;
  }
  return { 'match': true };
}
