import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppAuthService } from '../../../app-auth.service';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../../shared-components/user-message/user-message.component';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  errorMessage = '';
  UnauthorizedMsg = false;
  canSubmit = false;
  confirmation = false;

  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('clientInput') clientInput: ElementRef;

  get lastClientId(): string {
    return localStorage.getItem('MML-REMEMBER-CLIENTID') || '';
  }
  set lastClientId(value: string) {
    localStorage.setItem('MML-REMEMBER-CLIENTID', value);
  }

  constructor(private fb: UntypedFormBuilder, private authService: AppAuthService) {
    this.validationMessages = {
      client: {
        required: 'Please enter your client Id.',
        pattern: 'Please enter a valid client Id.',
        minlength: 'Client Id must be 7 characters long.',
        maxlength: 'Client Id must be 7 characters long.'
      },
      email: {
        required: 'Please enter your email address.',
        pattern: 'Please enter a valid email address.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.errorMessage = '';
    this.forgotPasswordForm = this.fb.group({
      client: [this.lastClientId,
      [Validators.required, Validators.pattern('^[A-Za-z]+[0-9]{3,3}$'), Validators.minLength(7), Validators.maxLength(7)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    });
    this.forgotPasswordForm.valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.forgotPasswordForm);
      this.canClick();
    });
    this.forgotPasswordForm.controls['email'].valueChanges.pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.forgotPasswordForm);
      if (value !== undefined) {
        const email = value as string;
        this.forgotPasswordForm.controls['email'].setValue(email.toLowerCase());
      }
      this.canClick();
    });
  }

  canClick() {
    if (this.forgotPasswordForm && this.forgotPasswordForm.valid) {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
  }

  forgotPassword() {
    if (this.forgotPasswordForm && this.forgotPasswordForm.valid) {
      const { client, email } = this.forgotPasswordForm.value;
      this.tryRecoverPassword(client, email);
    } else {
      this.UnauthorizedMsg = true;
    }
  }

  tryRecoverPassword(clientId: string, email: string) {
    this.errorMessage = '';
    this.authService.forgotPasswordService(email, clientId).subscribe();
    this.confirmation = true;
  }

}
