import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, throwError, timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { mergeMap, retryWhen, tap } from 'rxjs/operators';
import { AppAuthService } from './app-auth.service';
import { AppSignalrService } from './app-signalr.service';
import { AppSettingsService } from './data-services/app-settings.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  retryDelay = 2000;
  retryMaxAttempts = 3;

  constructor(private router: Router, private authService: AppAuthService, private signalRService: AppSignalrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.isLoggedIn && !this.signalRService.reconectionInProgress && !this.signalRService.isConnected) {
      console.log('SignalR Interceptor autoconnect');
      this.signalRService.InitSignalR(this.authService.AuthToken);
      this.signalRService.reconectionInProgress = true;
    }

    console.log(`HTTP INTERCEPTOR CALLED - ${request.url}`);
    let token = this.authService.AuthToken;
    
    if(request.url.includes(AppSettingsService.Settings.auth0Settings.auth0ApiBaseUrl)){
      token = this.authService.getAuth0Token();
    }
    const clientId = request.headers.get('ClientId') || this.authService.getClientId() || '';
    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        ClientId: clientId
      },
      setParams: {
        accountMemberId: !this.authService.AccountMember ? '0' : this.authService.AccountMember.Id.toString(),
        accountId: !this.authService.Account ? '0' : this.authService.Account.Id.toString(),
      }
    });

    return next.handle(req).do((event: HttpEvent<any>) => {
      console.log('Interceptor response completed.');
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          console.log('Token Expired');
          this.router.navigate(['/login']);

          if (this.authService.isLoggedIn) {
            this.authService.logout();
          }
        }
      }
    });
  }

  retryAfterDelay(): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === this.retryMaxAttempts) {
            return throwError(err);
          }
          return of(err).pipe(
            tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }
}
