import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { IAccount } from '../../../interfaces/Account';
import { IErrorLog } from '../../../interfaces/ErrorLog';
import { FormState } from '../../../interfaces/FormState';
import { IMessageBox, MessageBoxTypeEnum } from '../../../interfaces/MessageBox';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../user-message/user-message.component';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-note-shared',
  templateUrl: './note-shared.component.html'
})
export class NoteSharedComponent implements OnInit {

  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @ViewChild('myModal') public myModal: ModalDirective;
  @Input() account: IAccount;
  @Input() leadId = 0;

  ready = false;
  // form
  public formState = FormState.Error;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  _form: UntypedFormGroup;
  get FormState() { return FormState; } // to expose enum values, not the actual formstate
  // data
  public selectedEntity: IMessageBox;
  public focus = false;

  constructor(private service: DataService, private authService: AppAuthService, private fb: UntypedFormBuilder) {
    this.validationMessages = {
      Content: {
        required: 'Type note content to save',
        maxLength: 'Field name must be less than 50 characters.'
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this._form = this.fb.group({
      Content: ['', [Validators.required, Validators.maxLength(1000)]]
    });
  this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
	this.displayMessage = this.genericValidator.processMessages(this._form);
});
  }

  public modalShown() {
    console.log('Modal Shown');
    this.focus = true;
  }

  public modalClosed() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedEntity = null;
    this.focus = false;
    this.myModal.hide();
  }

  public addEntity() {
    console.log('Add');
    this.formState = FormState.isNew;
    this.getEntity(0);
    this.myModal.show();
  }

  public editEntity(entity: IMessageBox) {
    console.log('Edit');
    this.formState = FormState.isEdit;
    this.getEntity(entity.Id);
    this.myModal.show();
  }

  private getEntity(id: number) {
    this.ready = false;
    this.focus = false;
    this.userMessage.getData();
    const l1 = this.service.getMessageBox(id);
    l1.subscribe(data1 => {
      console.log(data1);
      this.selectedEntity = data1;
      this.populateModalForm();
      this.userMessage.clear();
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private populateModalForm() {
    this._form.reset();
    this._form.patchValue(this.selectedEntity);
    this._form.markAsPristine();
    if (this.formState === FormState.isNew) {

    } else if (this.formState === FormState.isEdit) {

    }
    this.myModal.show();
    this.ready = true;
  }

  public modalSave() {
    console.log('SAVE');
    console.log(this.formState);
    if (this._form.valid) {
      this.selectedEntity.Content = this._form.controls['Content'].value;
      this.selectedEntity.LeadId = this.leadId;
      this.selectedEntity.Type = MessageBoxTypeEnum.Note;
      console.log(this.selectedEntity);
      if (this.formState === FormState.isNew) {
        console.log('insert');
        this.insertEntity(this.selectedEntity);
      } else if (this.formState === FormState.isEdit) {
        console.log('update');
        this.updateEntity(this.selectedEntity);
      }
    }
  }

  public modalClose() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.selectedEntity = null;
    this.myModal.hide();
  }

  private insertEntity(entity: IMessageBox) {
    this.userMessage.saveData();
    this._form.markAsPristine(); // to disable save button
    this.service.insertMessageBox(entity, this.leadId)
      .subscribe(
        (data) => {
          this.selectedEntity = data;
          this.formState = FormState.isEdit;
          this.userMessage.saveSucceded();
          this.modalClosed();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

  private updateEntity(entity: IMessageBox) {
    this.userMessage.saveData();
    this._form.markAsPristine(); // to disable save button
    this.service.updateMessageBox(entity, this.leadId, entity.Id)
      .subscribe(
        (data) => {
          this.selectedEntity = data;
          this.formState = FormState.isEdit;
          this.userMessage.saveSucceded();
          this.modalClosed();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

}
