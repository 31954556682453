import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CustomRangeComponent } from './custom-range/custom-range.component';

export interface IFilerEvent {
  index: number;
  date1: Date;
  date2: Date;
}

@Component({
  selector: 'app-filterbar',
  templateUrl: './filterbar.component.html'
})
export class FilterbarComponent implements OnInit {
  @ViewChild(CustomRangeComponent, { static: true }) entityModal: CustomRangeComponent;
  public label = '';
  public labelDate = '';
  public showCancel = false;
  public filterActive = false;

  @Input() showCustoms = true;
  @Input() showAllTime = true;
  @Input() menuOptionSetSelection = 0;
  @Input() defaultIndexValue = 9;
  @Output() selectedEvent = new EventEmitter<IFilerEvent>();

  public selectedRange: Date[] = [];

  public displayOptions: string[] = [];
  private menuOptionSetSelection1: string[] = [
    'Last 7 days',
    'This Week',
    'Last Week',
    'Last 30 days',
    'This Month',
    'Last Month',
    'This Year',
    'Last Year',
    'Year to Date',
    'Last 12 Months'
  ];

  constructor() { }

  ngOnInit(): void {
    switch (this.menuOptionSetSelection) {
      case 0:
        this.displayOptions = Object.assign([], this.menuOptionSetSelection1);
        this.setDefault();
    }
  }

  private setDefault(sendEvent = true) {
    this.label = this.displayOptions[this.defaultIndexValue];
    this.getDateRange(this.defaultIndexValue, sendEvent);
  }

  public Select(index: number) {
    this.filterActive = true;

    if (index === 100) {
      this.label = 'Custom Dates';
    }
    if (index === 200) {
      this.label = 'All Time';
    }

    if (index < this.displayOptions.length) {
      this.label = this.displayOptions[index];
    }
    this.showCancel = true;
    this.getDateRange(index);
  }

  public Cancel(sendEvent = true) {
    this.filterActive = false;
    this.showCancel = false;
    this.setDefault(sendEvent);
  }

  public GetButtonStyle() {
    if (!this.filterActive) {
      return 'btn  border-secondary text-dark';
    } else {
      return 'btn  btn-primary text-white';
    }
  }

  private setDateRangeString(range: IFilerEvent) {
    if (range === undefined) { return; }
    switch (range.index) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 100:
        return ': ' + moment(range.date1).format('MM/DD/YYYY') + '-' + moment(range.date2).format('MM/DD/YYYY');
      default:
        return '';
    }
  }

  private getDateRange(index: number, sendEvent = true) {
    let event: IFilerEvent;
    let inCase = false;
    const today = moment();
    this.selectedRange = [];
    switch (index) {
      case 0:  // last 7 days
        const last7 = moment().add(-7, 'days');
        event = {
          index: index,
          date1: last7.toDate(),
          date2: today.clone().toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 1: // this week
        const monday = today.clone().weekday(1);
        const friday = today.clone().weekday(5);
        event = {
          index: index,
          date1: monday.toDate(),
          date2: friday.toDate()
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 2: // last week
        const mondayLast = today.clone().add(-1, 'week').weekday(1);
        const fridayLast = today.clone().add(-1, 'week').weekday(5);
        event = {
          index: index,
          date1: mondayLast.toDate(),
          date2: fridayLast.toDate()
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 3: // last 30
        const last30 = today.clone().add(-30, 'days');
        event = {
          index: index,
          date1: last30.toDate(),
          date2: today.clone().toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 4: // this month
        event = {
          index: index,
          date1: today.clone().startOf('month').toDate(),
          date2: today.clone().endOf('month').toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 5: // last month
        const lastMonth = today.clone().add(-1, 'month');
        event = {
          index: index,
          date1: lastMonth.startOf('month').toDate(),
          date2: lastMonth.endOf('month').toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 6: // this year
        event = {
          index: index,
          date1: today.clone().startOf('year').toDate(),
          date2: today.clone().endOf('year').toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 7: // last year
        const lastYear = today.clone().add(-1, 'year');
        event = {
          index: index,
          date1: lastYear.startOf('year').toDate(),
          date2: lastYear.endOf('year').toDate(),
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 8: // year to date
        event = {
          index: index,
          date1: today.clone().startOf('year').toDate(),
          date2: today.clone().toDate()
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 9: // last 12 months
        event = {
          index: index,
          date1: today.clone().add(-12, 'month').toDate(),
          date2: today.clone().toDate()
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      case 100: // custom
        this.entityModal.addEntity();
        break;
      case 200: // all time
        event = {
          index: index,
          date1: today.clone().add(-100, 'year').toDate(),
          date2: today.clone().toDate()
        };
        this.labelDate = this.setDateRangeString(event);
        this.selectedRange = [event.date1, event.date2];
        inCase = true;
        break;
      default:
        event = {
          index: -1,
          date1: new Date(),
          date2: new Date()
        };
        this.selectedRange = [event.date1, event.date2];
    }

    if (sendEvent && inCase) {
      this.selectedEvent.emit(event);
    }
  }

  public onCustomRangeSelected($event: any) {
    const event = {
      index: 100,
      date1: $event[0],
      date2: $event[1],
    };
    this.labelDate = this.setDateRangeString(event);
    this.selectedRange = [event.date1, event.date2];
    this.selectedEvent.emit(event);
  }

  public onCancel(sendEvent = true) {
    this.Cancel(sendEvent);
  }

}
