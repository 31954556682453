import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from '../../../../data-services/data.service';
import { IErrorLog } from '../../../../interfaces/ErrorLog';
import { IFunnelView1 } from '../../../../interfaces/Views';


@Component({
  selector: 'app-funnel',
  templateUrl: 'funnel.component.html'
})
export class FunnelComponent implements OnInit, OnDestroy {
  private _funnelData = [0, 0, 0, 0, 0, 0, 0];
  private _funnelInternalData = [10, 10, 10, 10, 10, 10, 0];

  @Output() ready = new EventEmitter<any>();
  @Output() dataReady = new EventEmitter<any>();

  @Input() height:string;
  @Input() width:string;
  
  public uCalls = 0;
  data: object;
  handler: any;
  chartObj: any;


  constructor(private zone: NgZone,  private service: DataService, private router: Router) {
  }

  ngOnInit(): void {
    const chartData = [0, 0, 0, 0, 0, 0, 0];
    this.DrawChart(chartData);
  }

  ngOnDestroy(): void {
  }

  public GetFunnelData(accountId: number) {
    const L1 = this.service.getFunnel(accountId);
    L1.subscribe((data: IFunnelView1[]) => {
      const localData = [0, 0, 0, 0, 0, 0, 0];
      if (data) {
        data.forEach(element => {
          localData[element.OrderInFunnel - 1] = element.Total;
        });
      }
      this.DrawChart(localData);
      this.dataReady.emit(null);
    },
      err => {
        const chartData = [0, 0, 0, 0, 0, 0, 0];
        this.DrawChart(chartData);
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  public GetFunnelDataRange(accountId: number, d1: string, d2: string) {
    const L1 = this.service.getFunnelRange(accountId, d1, d2);
    L1.subscribe((data: IFunnelView1[]) => {
      const localData = [0, 0, 0, 0, 0, 0, 0];
      if (data) {
        data.forEach(element => {
          localData[element.OrderInFunnel - 1] = element.Total;
        });
      }
      this.DrawChart(localData);
      this.dataReady.emit(null);
    },
      err => {
        const chartData = [0, 0, 0, 0, 0, 0, 0];
        this.DrawChart(chartData);
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  private DrawChart(values: number[]) {
    if (values === undefined) {
      return;
    }
    this._funnelData = values;
    const dataSeries = [];
    dataSeries.push({ label: 'New Leads: ' + this._funnelData[0].toString(), value: this._funnelInternalData[0].toString(), tooltext: 'New Leads: ' + this._funnelData[0].toString(), color: '#71cbff' });
    dataSeries.push({ label: 'Follow Up: ' + this._funnelData[1].toString(), value: this._funnelInternalData[1].toString(), tooltext: 'Follow Up: ' + this._funnelData[1].toString(), color: '#82e1e1' });
    dataSeries.push({ label: 'Consult Scheduled: ' + this._funnelData[2].toString(), value: this._funnelInternalData[2].toString(), tooltext: 'Consult Scheduled: ' + this._funnelData[2].toString(), color: '#ffa1b5' });
    dataSeries.push({ label: 'Consult Complete: ' + this._funnelData[3].toString(), value: this._funnelInternalData[3].toString(), tooltext: 'Consult Complete: ' + this._funnelData[3].toString(), color: '#ce93d8' });
    dataSeries.push({ label: 'Procedure Scheduled: ' + this._funnelData[4].toString(), value: this._funnelInternalData[4].toString(), tooltext: 'Procedure Scheduled: ' + this._funnelData[4].toString(), color: '#ffd56c' });
    dataSeries.push({ label: 'Procedure Completed: ' + this._funnelData[5].toString(), value: this._funnelInternalData[5].toString(), tooltext: 'Procedure Completed: ' + this._funnelData[5].toString(), color: '#83ff1e' });
    this.data = {
      chart: {
        // renderAt: 'chart-container',
        theme: 'fusion',
        caption: '',
        subcaption: '',
        streamlinedData: '0',
        decimals: '1',
        is2D: '1',
        labelDistance: '15',
        showPercentValues: '0',
        useSameSlantAngle: '1',
        bgColor: '#ffffff',
        borderAlpha: '20',
        showPlotBorder: '0',
        plotBorderThickness: '20',
        plotBorderAlpha: '255',
        plotBorderColor: '#ffffff',
        showBorder: '0',
        showLegend: '0',
        showLabels: '1',
        showValues: '0',
        plotTooltext: ' ',
        minPlotHeightForValue: '50'
      },
      data: dataSeries
    };
    this.uCalls = this._funnelInternalData[6];
  }

  dataplotClickHandler(eventObj, dataObj) {
    this.zone.run(() => {
      this.handleFunnelType(dataObj.dataIndex);
    });
  }

  initialized($event) {
    console.log('chart initialized!');
    this.chartObj = $event.chart;
    this.attachEvent();
    this.ready.emit(null);
  }

  attachEvent() {
    this.handler = this.dataplotClickHandler.bind(this);
    this.chartObj.addEventListener('dataplotClick', this.handler);
  }

  handleFunnelType(dataIndex: any) {
    switch (dataIndex) {
      case 1:
        this.router.navigate(['lead-management/new-leads']);
        break;
      case 2:
        this.router.navigate(['lead-management/followup']);
        break;
      case 3:
        this.router.navigate(['lead-management/app-scheduled']);
        break;
      case 4:
        this.router.navigate(['lead-management/app-completed']);
        break;
      case 5:
        this.router.navigate(['lead-management/procedure-scheduled']);
        break;
      case 6:
        this.router.navigate(['lead-management/procedure-completed']);
        break;
    }
  }

}
