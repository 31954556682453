import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as FusionCharts from 'fusioncharts';
import { ConnectionService } from 'ng-connection-service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { initializePendo } from '../../../assets/scripts/pendo';
import { AppAuthService } from '../../app-auth.service';
import { AppSessionService } from '../../app-session.service';
import { AppSignalrService } from '../../app-signalr.service';
import { AppSettingsService } from '../../data-services/app-settings.service';
import { DataService } from '../../data-services/data.service';
import { IErrorLog } from '../../interfaces/ErrorLog';
import { ISimpleLead } from '../../interfaces/Lead';
import {
  ISignalRMessage,
  ISignalRNotification,
  SignalRMessageType,
  SignalRNotificationType,
} from '../../interfaces/SignalR';
import { IUnreadLeadEmailsView } from '../../interfaces/Views';
import { INavData } from '../../_nav';
import { debounceTime } from  'rxjs/operators';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dropdown') searchLeadsDropdown: BsDropdownDirective;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  public navItems: INavData[];
  public sidebarMinimized = true;
  public changes: MutationObserver;
  public element: HTMLElement;
  public isLoading = false;
  signalRNotificationsHandler: Subscription;
  signalRMessagesHandler: Subscription;

  year = new Date().getFullYear().toString();
  public isMercury = false;
  // Toaster
  private offlineToast: ActiveToast<any>;

  private isAppHidden = false;
  userWasNotified = false;
  private visibiltyEvent: any;

  private sub: Subscription;

  private checkEmailMins = 10;

  public searchText = '';
  public searchTextChanged = new Subject<string>();
  public isSearching = false;
  public searchCount = 0;
  public itemList: ISimpleLead[] = [];
  public navigationList: INavData[] = [];

  // Notifications
  notifications: string[] = ['No new Notifications'];
  // Tasks
  tasksNotifications: string[] = ['No new Tasks'];
  // Email
  public emailCount = 0;
  public emailNotifications: IUnreadLeadEmailsView[] = [];
  // SMS
  public smsCount = 0;
  smsNotifications: string[] = [];

  constructor(
    private signalRService: AppSignalrService,
    private authService: AppAuthService,
    private service: DataService,
    private router: Router,
    private connectionService: ConnectionService,
    private toastr: ToastrService,
    private readonly menu: AppSessionService,

    @Inject(DOCUMENT) _document?: any
  ) {
    this.connectionService.monitor().subscribe((currentState) => {
      if (currentState) {
        this.authService.online = true;
        console.log('ONLINE');
        toastr.clear();
        this.toastr.success(
          'The application is now Online',
          'Application Online',
          {
            closeButton: true,
            easeTime: 0,
          }
        );
      } else {
        this.authService.online = false;
        console.log('OFFLINE');
        toastr.clear();
        this.toastr.error(
          'Please verify your Internet connection.',
          'Application Offline',
          {
            disableTimeOut: true,
            tapToDismiss: false,
            easeTime: 0,
          }
        );
      }
    });

    if (!this.authService.ActivateMercury) {
      if (this.menu.getMenu() !== null) {
        this.navItems = this.menu.getMenu();
      } else {
        this.getMenu();
      }
      this.isMercury = false;
    } else {
      this.getMenuMercury();
      this.isMercury = true;
    }

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains(
        'sidebar-minimized'
      );
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class'],
    });
    
    this.visibiltyEvent = document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.isAppHidden = true;
        this.userWasNotified = false;
      } else {
        this.isAppHidden = false;
        this.userWasNotified = false;
      }
    });

    this.searchTextChanged.pipe(debounceTime(500)).subscribe(val => {
      if (this.searchText.trim().length >= 3) {
        this.getDataSearchData(
          this.authService.AccountMember.AccountId,
          this.searchText.trim()
        );
      } else {
        this.isSearching = false;
        this.searchLeadsDropdown.autoClose = true;
        this.searchLeadsDropdown.hide();
      }
    });

    FusionCharts.options['license']({
      key: AppSettingsService.Settings.fusionChartsLicense,
      creditLabel: false
    });
  }

  public getMenu() {
    this.service.getMenu().subscribe(
      (data) => {
        this.navigationList = data;
        this.navItems = this.navigationList;
        this.menu.setMenu(this.navItems);
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {}
    );
  }

  public getMenuMercury() {
    this.service.getMenuMercury().subscribe(
      (data) => {
        this.navigationList = data;
        this.navItems = this.navigationList;
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {}
    );
  }

  public searchChanged() {
    this.searchTextChanged.next();
  }

  public cancelSearch() {
    this.searchLeadsDropdown.hide();
    this.itemList = [];
    this.searchCount = 0;
    this.searchText = '';
  }

  public useThisClick(item: ISimpleLead) {
    this.searchLeadsDropdown.hide();
    this.useThis(item.Id);
    this.itemList = [];
    this.searchCount = 0;
    this.searchText = '';
  }

  public getDataSearchData(accountId: number, text: string) {
    this.isSearching = true;
    const l1 = this.service.getSimpleLeadSearch(accountId, text);
    l1.subscribe(
      (data) => {
        this.isSearching = false;
        console.log(data);
        if (data !== null) {
          this.searchCount = data.length;
          this.itemList = data;
          this.searchLeadsDropdown.show();
        } else {
          this.itemList = [];
          this.searchCount = 0;
        }
      },
      (err) => {
        this.isSearching = false;
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {}
    );
  }

  ngOnInit() {
    this.signalRNotificationsHandler = this.signalRService.signalRChange.subscribe(
      (notification) => this.ProcessSignalRNotifications(notification)
    );
    this.signalRMessagesHandler = this.signalRService.signalRChange2.subscribe(
      (msg) => this.ProcessSignalRMessages(msg)
    );
    if (window.pendo) {
      initializePendo(this.authService.Account, this.authService.AccountMember, this.authService.getClientId());
    }
  }

  ngOnDestroy(): void {
    if (this.changes !== undefined) {
      this.changes.disconnect();
    }
    if (this.signalRNotificationsHandler !== undefined) {
      this.signalRNotificationsHandler.unsubscribe();
    }
    if (this.signalRMessagesHandler !== undefined) {
      this.signalRMessagesHandler.unsubscribe();
    }
    if (this.visibiltyEvent !== undefined) {
      this.visibiltyEvent.unsubscribe();
    }
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  private ProcessSignalRMessages(message: ISignalRMessage) {
    if (message.messageType === SignalRMessageType.UnreadEmails) {
      if (message.param1 > 0) {
        this.GetUnreadLeadEmailsAlerts();
      } else {
        this.emailNotifications = [];
        this.emailCount = 0;
      }
    }
  }

  public ProcessSignalRNotifications(notification: ISignalRNotification) {
    const close = !notification.autoClose;
    const disableTimeOut = !notification.autoClose;
    const tapToDismiss = notification.autoClose;

    if (this.isAppHidden && !this.userWasNotified) {
      this.userWasNotified = true;
    }

    if (notification.notificationType === SignalRNotificationType.Error) {
     this.showErrorMessage(notification, close, disableTimeOut, tapToDismiss);
    }

    if (notification.notificationType === SignalRNotificationType.Info) {
      this.showInfoMessage(notification, close, disableTimeOut, tapToDismiss);
    }

    if (notification.notificationType === SignalRNotificationType.Warning) {
      this.showWarningMessage(notification, close, disableTimeOut, tapToDismiss);
    }

    if (notification.notificationType === SignalRNotificationType.Success) {
      this.showSuccessMessage(notification, close, disableTimeOut, tapToDismiss);
    }
  }

  public showErrorMessage(notification: ISignalRNotification, close: boolean, disableTimeOut: boolean, tapToDismiss: boolean ): boolean {
    this.toastr.error(notification.content, notification.title, {
      closeButton: close,
      disableTimeOut: disableTimeOut,
      tapToDismiss: tapToDismiss,
      easeTime: 0,
      timeOut: 10000,
    });
    return true;
  }

  public showInfoMessage(notification: ISignalRNotification, close: boolean, disableTimeOut: boolean, tapToDismiss: boolean ): boolean {
    this.toastr.info(notification.content, notification.title, {
      closeButton: close,
      disableTimeOut: disableTimeOut,
      tapToDismiss: tapToDismiss,
      easeTime: 0,
      timeOut: 10000,
    });
    return true;
  }

  public showWarningMessage(notification: ISignalRNotification, close: boolean, disableTimeOut: boolean, tapToDismiss: boolean ): boolean {
    this.toastr.warning(notification.content, notification.title, {
      closeButton: close,
      disableTimeOut: disableTimeOut,
      tapToDismiss: tapToDismiss,
      easeTime: 0,
      timeOut: 10000,
    });
    return true;
  }

  public showSuccessMessage(notification: ISignalRNotification, close: boolean, disableTimeOut: boolean, tapToDismiss: boolean ): boolean {
    this.toastr.success(notification.content, notification.title, {
      closeButton: close,
      disableTimeOut: disableTimeOut,
      tapToDismiss: tapToDismiss,
      easeTime: 0,
      timeOut: 10000,
    });
    return true;
  }

  private CheckForUnreadLeadEMails() {
    if (this.isMercury) {
      return;
    }
    this.CheckIfSmtpIsReady();
  }

  private CheckIfSmtpIsReady(): boolean {
    const accountMemberId = this.authService.AccountMember.Id;
    const l1 = this.service.getAccountMemberEMailConfigByAccountMemberId(
      accountMemberId
    );
    this.isLoading = true;
    l1.subscribe(
      (data) => {
        console.log('SMTP CONFIG');
        console.log(data);
        if (data.Active) {
          this.CheckForUnreadLeadEMailsAfterSMTPconfig();
        } else {
          this.isLoading = false;
        }
      },
      (err) => {
        this.isLoading = false;
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
        return false;
      },
      () => {}
    );
    return false;
  }

  private CheckForUnreadLeadEMailsAfterSMTPconfig() {
    const accountMemberId = this.authService.AccountMember.Id;
    const l1 = this.service.checkForUnreadLeadEMails(accountMemberId);
    l1.subscribe(
      (data) => {
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {}
    );
  }

  // only called by signal R
  private GetUnreadLeadEmailsAlerts() {
    const accountMemberId = this.authService.AccountMember.Id;
    this.isLoading = true;
    const l1 = this.service.getUnreadLeadEmailsAlerts(accountMemberId);
    l1.subscribe(
      (data) => {
        console.log(data);
        if (data && data.length > 0) {
          let msgCount = 0;
          data.forEach((element) => {
            msgCount = msgCount + element.UnreadEmails;
          });
          this.emailCount = msgCount;
          this.emailNotifications = data;
        } else {
          this.emailNotifications = [];
          this.emailCount = 0;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => {}
    );
  }

  public editItem(item: IUnreadLeadEmailsView) {
    this.useThis(item.LeadId);
    console.log(item);
  }

  useThis(id: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
      },
    };
    // this.router.navigate(['../lead-management/all-leads'], { skipLocationChange: true })
    //   .then(() => this.router.navigate(['../lead-management/lead-details/'], navigationExtras));
    this.router.navigate(
      ['../lead-management/lead-details/'],
      navigationExtras
    );
  }

  createManualLead() {
    this.router.navigate(['../lead-management/manual-lead']);
  }

  public refreshNotifications() {
    if (this.isLoading) {
      return;
    }
    console.log('refresh');
    this.CheckForUnreadLeadEMails();
  }

  ngAfterViewInit() {
    this.searchItem(this.router.url, this.navItems);
  }

  searchItem(url: string, items: INavData[]): void {
    if (items) {
      items.forEach(element => {
        if (element.children) {
          this.searchItem(url, element.children);
        }
      });
    }
  }

  updateClass(url: string): void {
    const container  = document.querySelector('.sidebar');
    const element = container.querySelector(`[href='#${url}']`);
    if (element) {
      element.classList.add('active');
      if (element.parentElement.parentElement.parentElement.parentElement.parentElement.className === 'nav-item nav-dropdown') {
        element.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('open');
        element.parentElement.parentElement.parentElement.classList.add('open');
      } else {
        element.parentElement.parentElement.parentElement.classList.add('open');
      }
    }
  }
}
