import { Injectable } from '@angular/core';
import { HashLocationStrategy } from '@angular/common';

@Injectable()
export class CustomLocationStrategy extends HashLocationStrategy {
    override prepareExternalUrl(internal: string): string {
        let url = '/#' + internal;

        if (window.location.pathname === '/auth0' || window.location.pathname === '/callback' || window.location.pathname === '/auth0/logged-out') {
            url = window.location.href;
        };
        return url;
    }
}
