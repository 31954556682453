import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from '../data-services/data.service';

export interface IErrorLog {
  LogDate: string;
  AccountMemberId: number;
  UserName: string;
  FriendlyMsg: string;
  ExceptionUrl: string;
  ExceptionMsg: string;
  ExceptionType: string;
  ExceptionSource: string;
  ExceptionDetails: string;
  StatusCode: number;
  ErrorLogType: number;
  Id: number;
}

export function LogClientError(httpError: HttpErrorResponse, service: DataService): any {
  const entity: IErrorLog = {
    Id: 0,
    AccountMemberId: 0,
    UserName: '',
    FriendlyMsg: 'Client Error',
    ExceptionUrl: httpError.url,
    ExceptionMsg: httpError.statusText,
    ExceptionDetails: httpError.message,
    ExceptionType: httpError.name,
    ExceptionSource: httpError.error,
    StatusCode: httpError.status,
    ErrorLogType: httpError.type,
    LogDate: '',
  };

  service.insertErrorLog(entity)
    .subscribe(
      (data) => {
        return data;
      },
      err => {
        console.log(err);
      });
}
