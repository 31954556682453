import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppAuthService } from '../../../app-auth.service';
import { LeadDetailsRedirectionService } from './lead-details-redirection.service';

@Component({
  selector: 'app-lead-details-redirection',
  templateUrl: './lead-details-redirection.component.html',
})
export class LeadDetailsRedirectionComponent implements OnInit {

  constructor(private authService: AppAuthService,
    private service: LeadDetailsRedirectionService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe((queryParams) => {
        this.service.isLoggedIn = this.authService.isLoggedIn;
        this.service.id = queryParams['id'];
        this.service.clientId = queryParams['client'];
        this.redirect();
      });
  }

  redirect() {

    if (!this.service.id || !this.service.clientId) {
      this.service.redirect = false;
      this.service.unauthorized();
    } else {
      if (!this.service.isLoggedIn) {
        this.service.redirect = true;
        this.service.login();
      } else {
        this.service.redirect = false;
        this.service.leadDetails();
      }
    }
  }
}
