import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NgxSummernoteDirective } from 'ngx-summernote';
import { AppAuthService } from '../../../../app-auth.service';
import { EmailsService } from '../../../../data-services/endpoints/emails.service';
import { AppEmailContentType, AppEmailDirection, AppEmailType, IAppEmail } from '../../../../interfaces/AppEmail';
import { ITemplate, TemplateType } from '../../../../interfaces/ITemplate';
import { IComplexLead } from '../../../../interfaces/Lead';
import { IMasterDetailDialogScreenConfig } from '../../../../interfaces/ScreenConfig';
import { GenericValidator, validateContent } from '../../../../utils/generic-validator';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
})
export class EmailModalComponent implements OnInit {
  @ViewChild(NgxSummernoteDirective) summerNote: NgxSummernoteDirective;

  public screenConfig: IMasterDetailDialogScreenConfig = {
    EntityName: 'E-Mail',
    ShowInputHints: true
  };

  public templateType = TemplateType.Email;
  public templateCreated: ITemplate = null;
  public isLoading: boolean = false;

  public _form: UntypedFormGroup;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  public content = '';
  public summernoteConfig = {
    disable: true,
    placeholder: '',
    tabsize: 2,
    height: '250px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
      ['para', ['style', 'paragraph', 'ul', 'ol', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']]
    ],
    popover: {
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ]
    },
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  constructor(private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EmailModalComponent>,
    private authService: AppAuthService,
    private emailService: EmailsService,
    @Inject(MAT_DIALOG_DATA) public selectedLead: IComplexLead
    ) {
    this.configForm();
  }

  ngOnInit(): void {
    this.populateModalForm();
  }

  private configForm() {
    this.validationMessages = {
      Subject: {
        required: 'Please enter a subject.',
        minlength: 'Subject must be at least 1 character long.',
        maxlength: 'Subject must have less than 250 characters.',
      },
      Content: {
        required: 'Please enter template content.',
        maxlength: 'Field value must have less than 10,000 characters.'
      },
      NotifyError: {
      },
      NotifyDelivered: {
      },
      NotifyOpened: {
      }
    };

    this._form = this.fb.group({
      Subject: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      Content: ['', [Validators.maxLength(10000), validateContent()]],
      NotifyError: ['', []],
      NotifyDelivered: ['', []],
      NotifyOpened: ['', []]
    });

    this.genericValidator = new GenericValidator(this.validationMessages);
  this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
	this.displayMessage = this.genericValidator.processMessages(this._form);
});
  }

  public contentIsValid(): boolean {
    return this.content.replace(/<[^>]*(>|$)|&nbsp;/gi, '').trim().length > 0;
  }

  private populateModalForm() {
    this._form.reset();
    this._form.controls['NotifyError'].patchValue(true);
    this._form.controls['NotifyDelivered'].patchValue(false);
    this._form.controls['NotifyOpened'].patchValue(false);
    this._form.markAsPristine();
  }

  public cancelForm() {
    this.dialogRef.close();
  }

  private showError(err: any) {
    let errorMsg = '';
    if (err instanceof HttpErrorResponse) {
      errorMsg = 'Error: ' + err.error.FriendlyMsg;
    } else {
      errorMsg = 'Error: ' + err;
    }
    this.snackBar.open(errorMsg.length == 0 ? 'An error was detected.' : errorMsg, 'Retry');
    this.isLoading = false;
  }

  public modelchange($event: any): void {
    this.content = $event;
  }

  public getDataSignature() {
    this.isLoading = true;
    this.emailService.getAccountMemberSignatureByAccountMemberId(this.authService.AccountMember.Id).subscribe(signature => {
      if (signature.Signature.trim().length > 0) {
        this.content = this.content + ' <br><br><br><br>' + signature.Signature;
        this.summerNote.summernoteModel = this.content;
      }
      this.isLoading = false;
    },
    err => {
      this.showError(err);
    });
  }

  public onTemplateSelected(template: ITemplate): void {
    this.content = template.Content;
    this._form.controls['Content'].patchValue(this.content);
  }

  public onTemplateCreated(template: ITemplate): void {
    this.templateCreated = template;
  }

  public sendEmail() {
    if (this._form.valid) {

      const email: IAppEmail = {
        Id: 0,
        LeadId: this.selectedLead.Id,
        Direction: AppEmailDirection.Outbound,
        AccountMemberFromId: this.authService.AccountMember.Id,
        AppEmailType: AppEmailType.AppEmail,
        ContentType: AppEmailContentType.Html,
        Content: this._form.controls['Content'].value,
        To: this.selectedLead.EMail,
        ReplyTo: this.authService.AccountMember.EMail,
        From: 'AdminEmail',
        FromName: `${this.authService.AccountMember.FirstName} ${this.authService.AccountMember.LastName}`,
        Subject: this._form.controls['Subject'].value,
        NotifyDelivered: this._form.controls['NotifyDelivered'].value,
        NotifyOpened: this._form.controls['NotifyOpened'].value,
        NotifyError: this._form.controls['NotifyError'].value
      };

      this.isLoading = true;
      this.snackBar.open('Sending...', '');
      this.emailService.insertAppEmail(email)
        .subscribe((email) => {
          this.snackBar.open('Email sent.', 'Close', { duration: 3000 });
          this.dialogRef.close();
        },
        err => {
          this.showError(err);
        }
      );
    }
  }

}
