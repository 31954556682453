import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { PathLocationStrategy } from '@angular/common';
import { AppAuthService } from '../../app-auth.service';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';

@Component({
  selector: 'app-auth0',
  templateUrl: './auth0.component.html'
})

export class Auth0Component implements OnInit {
  constructor(
    public router: Router,
    public auth: AuthService,
    public pathLocationStrategy: PathLocationStrategy,
    private authService: AppAuthService
  ) {
  }

  ngOnInit() {
    const absolutePathWithParams = this.pathLocationStrategy.path();
    const queryParams = new URLSearchParams(absolutePathWithParams.split('?')[1]);

    const organizationParam = queryParams.get('organization');
    
    if (queryParams.get('error') || !organizationParam) {
      this.router.navigate(['/error']);
    }

    if(queryParams.get('logout')){
      this.authService.logout();
      // set session when logged out from auth0 to identity logout source
      sessionStorage.setItem('isLoggedOutFromAuth0','true');
      // remove auth0IdToken from session storage
      sessionStorage.removeItem("auth0IdToken");
      // in app uses sessionStorage
      sessionStorage.removeItem("auth0SessionId");
      // orgId always in localStorage
      localStorage.removeItem("auth0OrgId");
      // remove last clientId from local storage
      localStorage.removeItem("MML-REMEMBER-CLIENTID");
      // redirect to universal logout page
      window.location.href = AppSettingsService.Settings.auth0Settings.universalLoginUrl + '/logged-out';
    }
    else{
      this.login(organizationParam, queryParams.get('redirectUrl')); 
    }
  }

  private login(organization: string, redirectUrl?: string) {
    this.auth.loginWithRedirect({
      appState: { target: redirectUrl },
      authorizationParams: {
        redirect_uri: AppSettingsService.Settings.auth0Settings.redirectUri,
        organization: organization
      }
    })
  }
}
