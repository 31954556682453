import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss']
})
export class NavMenuItemComponent {
  @Input() menuItem: any;
  isSubMenuVisible = false;

  toggleSubMenu(): void {
    this.isSubMenuVisible = !this.isSubMenuVisible;
  }

  isExternalLink(url: string): boolean {
    return /^(http|https):\/\//.test(url);
  }
}
