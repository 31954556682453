// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-hint {
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: x-small;
}

.small {
  padding: 0 5px 0 0;
}

.log {
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/app/views/angular-materials/shared-components/log/log.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ","sourcesContent":[".mat-hint {\r\n    padding-left: 10px;\r\n    padding-bottom: 10px;\r\n    font-size: x-small;\r\n}\r\n.small{\r\n    padding: 0 5px 0 0;\r\n}\r\n\r\n.log{\r\n    display: flex;\r\n    flex-direction: row;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
