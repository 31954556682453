import { Injectable } from "@angular/core";
import { decodeJwt } from "jose";
import { AppSettingsService } from "./data-services/app-settings.service";
import { Auth0Settings } from "./interfaces/AppSettings";

@Injectable()
export class Auth0Service {
    settings: Auth0Settings = AppSettingsService.Settings.auth0Settings;

    hasRole(roleName: string): boolean {
        const auth0Token = sessionStorage.getItem('auth0IdToken');

        if (!auth0Token || !roleName) {
            return false;
        }

        const roles = this.getRoles(auth0Token);
        return roles.some(role => role.toLowerCase() === roleName.toLowerCase());
    }

    openInPM(pmsPatientId: number) {
        const orgId = localStorage.getItem('auth0OrgId');
        const returnUrl = this.settings.practiceManagementPatientIdentificationUrl + pmsPatientId;
        window.open(this.settings.practiceManagementBaseUrl + orgId + '&ReturnPath=' + returnUrl, '_blank');
    }

    private getRoles(token: string): string[] {
        const decodedJwt = decodeJwt(token);
        return decodedJwt?.["https://nextech.com/user_authorization"]?.['roles'] || [];
    }
}
