import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { BtnPalette } from 'src/app/palette.service';

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html'
})
export class DatePickerDialogComponent implements OnInit {
  
  @Output() pickerEmitter = new EventEmitter<{startDate: Date, endDate: Date}>();
  public entity: {startDate: Date, endDate: Date} = {
    startDate: new Date(),
    endDate: new Date()
  };

  public dateSelected: boolean = false;
  public dialogMessage = "";
  public title = 'Select a date';
  public btnCancelPalette: BtnPalette = BtnPalette.cancel;
  public btnConfirmPalette: BtnPalette = BtnPalette.primary;

  public btnCancelClass = 'nx-cancel-btn';
  public btnConfirmClass = 'nx-primary';

  startDatePicker = new Subject<MatDatepickerInputEvent<any>>();
  endDatePicker = new Subject<MatDatepickerInputEvent<any>>();
  
  constructor() {
    //
  }

  ngOnInit(): void {
    this.startDatePicker.subscribe((data) => {
      this.entity.startDate = data.value;
    });

    this.endDatePicker.subscribe((data) => {
      this.entity.endDate = data.value;
      this.dateSelected = (data.value != null);
    });
  }
}
