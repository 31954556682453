import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppAuthService } from './app-auth.service';
import { DataService } from './data-services/data.service';
import { CommunicationType, ContactType, ILeadCommunicationPreferences, ILeadCommunicationPreferencesRequest, Status } from './interfaces/LeadCommunicationPreferences';

export enum Phones {
  Phone1,
  Phone2
}


@Injectable({
  providedIn: 'root'
})
export class CommunicationsPreferencesService {
  private _leadEmail = '';
  private _leadPhone1 = '';
  private _leadPhone2 = '';
  private _leadPhones = [];

  private _leadEmailEnabled = false;
  private _leadPhone1SmsEnabled = false;
  private _leadPhone2SmsEnabled = false;

  private _isTcpaEnabled = false;
  private _doesLeadHavePhoneNumbers: boolean = false;
  private _doesLeadHaveEmail: boolean = false;

  private _leadCommunicationPreferences: ILeadCommunicationPreferences[];


  // Change Notifications (suscription)
  private dataSource = new Subject<ILeadCommunicationPreferences[]>();
  private tcpaEnabledSource = new Subject<boolean>();

  public dataSourceChanges$ = this.dataSource.asObservable();
  public tcpaEnabledChanges$ = this.tcpaEnabledSource.asObservable();
  // 


  constructor(private service: DataService, private authService: AppAuthService) {
    console.log('*******************************> Communications Preference Service');
  }

  
  public get LeadEmailEnabled(): boolean {
    return this._leadEmailEnabled;
  }

  public get LeadPhone1SmsEnabled(): boolean {
    return this._leadPhone1SmsEnabled;
  }

  public get LeadPhone2SmsEnabled(): boolean {
    return this._leadPhone2SmsEnabled;
  }

  public LeadPhones(): string[] {
    this._leadPhones = [];
    this._leadPhones.push(this._leadPhone1);
    this._leadPhones.push(this._leadPhone2);
    return this._leadPhones;
  }

  public GetData(leadEmail: string, leadPhone1: string, leadPhone2: string) {
    console.log('Communications Preference Service - GetData');
    this._leadEmail = leadEmail;
    this._leadPhone1 = leadPhone1;
    this._leadPhone2 = leadPhone2;
    this.checkIfThereIsPhoneNumber();
    this._doesLeadHaveEmail = this._leadEmail !== '' && this._leadEmail !== undefined;

    this._isTcpaEnabled = true;
    this.tcpaEnabledSource.next(true);
    this._leadPhones = [];
    const leadContactDetails: ILeadCommunicationPreferencesRequest = {
      ClientId: this.authService.getClientId(),
      PracticeId: null,
      ContactDetailsList: [
        {
          EmailAddress: this._leadEmail,
          PhoneNumbers: [this._leadPhone1, this._leadPhone2]
        }
      ]
    };
    this.service.getLeadCommunicationPreferences(leadContactDetails).subscribe(result => {
      if (result != null && result.length > 0) {
        this._leadCommunicationPreferences = result;
        this.ProcessData();
      }
    });
  }

  public Refresh() {
    this.GetData(this._leadEmail, this._leadPhone1, this._leadPhone2);
  }

  public IsLeadPreferenceOptInForPhone(contactTypeId: ContactType, communicationTypeId: CommunicationType, Phone: Phones = Phones.Phone1): boolean {
    let phone = '';
    if (Phone == Phones.Phone1)
      phone = this._leadPhone1;
    if (Phone == Phones.Phone2)
      phone = this._leadPhone2;

    return this.isLeadPreferenceOptIn(contactTypeId, communicationTypeId, phone);
  }

  public IsLeadPreferenceOptInForEmail(contactTypeId: ContactType, communicationTypeId: CommunicationType): boolean {
    return this.isLeadPreferenceOptIn(contactTypeId, communicationTypeId, this._leadEmail);
  }

  private ProcessData() {
    if (this._leadCommunicationPreferences != null) {
      const EmailMarketingOptin = this.isLeadPreferenceOptIn(ContactType.Email, CommunicationType.Marketing, this._leadEmail);
      this._leadEmailEnabled = EmailMarketingOptin;
      const LeadPhone1MarketingSmsOptin = this.isLeadPreferenceOptIn(ContactType.Sms, CommunicationType.Marketing, this._leadPhone1);
      this._leadPhone1SmsEnabled = LeadPhone1MarketingSmsOptin;
      const LeadPhone2MarketingSmsOptin = this.isLeadPreferenceOptIn(ContactType.Sms, CommunicationType.Marketing, this._leadPhone2);
      this._leadPhone2SmsEnabled = LeadPhone2MarketingSmsOptin;
      console.log('DATA:');
      console.log('With Email: ' + this._doesLeadHaveEmail);
      console.log('With Phone: ' + this._doesLeadHavePhoneNumbers);
      console.log(this._leadEmail);
      console.log(this._leadPhone1);
      console.log(this._leadPhone2);
      console.log(this.authService.getClientId());
      console.log('Email Button Enabled: ' + this._leadEmailEnabled);
      console.log('Sms1 Button Enabled: ' + this._leadPhone1SmsEnabled);
      console.log('Sms2 Button Enabled: ' + this._leadPhone2SmsEnabled);
      console.log('Data Source:');
      console.log(this._leadCommunicationPreferences);
      this.dataSource.next(this._leadCommunicationPreferences);
    }
  }

  private checkIfThereIsPhoneNumber(): void {
    const number = this._leadPhone1;
    if (number !== '' && number !== undefined && number !== null) {
      this._doesLeadHavePhoneNumbers = true;
      return;
    }
    const number2 = this._leadPhone2;
    if (number2 !== '' && number2 !== undefined && number2 !== null) {
      this._doesLeadHavePhoneNumbers = true;
    }
  }

  private isLeadPreferenceOptIn(contactTypeId: ContactType, communicationTypeId: CommunicationType, contactInfo: string): boolean {
    if (this._leadCommunicationPreferences == undefined) return;
    const preference = this._leadCommunicationPreferences[0].ContactCommunicationPreferences
      .find(x => x.ContactTypeId === contactTypeId &&
        x.CommunicationTypeId === communicationTypeId &&
        x.ContactInfo === contactInfo);
    if (preference) {
      return preference.StatusId === Status.OptIn;
    }
    return false;
  }

}
