import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AngularMaterialModule } from '../angular-materials/angular-materials.module';
import { EmailModalComponent } from '../angular-materials/lead-management/email-modal/email-modal.component';
import { SharedMaterialComponentsModule } from '../angular-materials/shared-components/shared-components.module';

import { LeadManagementRoutingModule } from './lead-management-routing.module';

@NgModule({
  declarations: [
    EmailModalComponent
  ],
  imports: [
    CommonModule,
    LeadManagementRoutingModule,
    AngularMaterialModule,
    NgxSummernoteModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMaterialComponentsModule
  ]
})
export class LeadManagementModule { }
