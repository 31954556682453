import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { ICountry, IState } from '../interfaces/Country';
import { IMedicalField } from '../interfaces/MedicalField';

import {
  IAccount,
  IAccountMember,
  IAccountMemberPreferences,
  IAccountMemberSignature,
  IApplicationRole,
  IDashboardWidgets,
} from '../interfaces/Account';
import { IAccountPhoneNumber } from '../interfaces/AccountPhoneNumber';
import { ICampaingPreferences } from '../interfaces/CampaingPreferences';
import { IAccountMemberEMailConfig } from '../interfaces/CommunicationLog';
import { IEntity } from '../interfaces/Entity';
import { ILeadSource, ILeadSourceChannel, ILeadSourceQuickAdd, ILeadSourceTag } from '../interfaces/LeadSource';
import { ILocation } from '../interfaces/Location';
import { IProcedure, IProcedureGroup } from '../interfaces/Procedure';
import { IReviewSites } from '../interfaces/ReviewSites';
import { IPhone, ISimpleLog } from '../interfaces/Twillio';
import { IWebSite, IWebSiteType } from '../interfaces/WebSite';
import { AppSettingsService } from './app-settings.service';

@Injectable()
export class ConfigDataService {

  private _baseUrl = AppSettingsService.Settings.baseUrl;

  constructor(private _http: HttpClient) {

  }

  // ********************* Account ******************************
  getAccountDataForEdit(id: number): Observable<any[]> {
    let api = '/Account/Get/';
    const l1 = this._http.get<IAccount>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
    api = '/Country/Get/';
    const l2 = this._http.get<ICountry>(`${this._baseUrl}${api}`).catch(this.handleError);
    api = '/State/Get/';
    const l3 = this._http.get<IState>(`${this._baseUrl}${api}`).catch(this.handleError);
    api = '/MedicalField/Get/';
    const l4 = this._http.get<IMedicalField>(`${this._baseUrl}${api}`).catch(this.handleError);
    return Observable.forkJoin([l1, l2, l3, l4]);
  }
  updateAccount(id: number, entity: IAccount): Observable<IAccount> {
    const api = '/Account/Update/';
    return this._http.put<IAccount>(`${this._baseUrl}${api}${id}`, entity);
  }

  // ********************* Account Member **************************
  getAccountMembersByParent(id: number): Observable<IAccountMember[]> {
    const api = '/AccountMember/GetByParent/';
    return this._http.get<IAccountMember[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  getAccountMemberEdit(id: number) {
    const api = '/AccountMember/Get/';
    return this._http.get<IAccountMember>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  // ********************* Aplication Role **************************
  getApplicationRoles(): Observable<IApplicationRole[]> {
    const api = '/ApplicationRole/Get/';
    return this._http.get<IApplicationRole[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }

  // ********************* Account Member Signature**************************
  getAccountMemberSignatureByParent(id: number): Observable<IAccountMemberSignature> {
    const api = '/AccountMemberSignature/GetByParent/';
    return this._http.get<IAccountMemberSignature>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  updateAccountMemberSignature(id: number, entity: IAccountMemberSignature): Observable<IAccountMemberSignature> {
    const api = '/AccountMemberSignature/Update/';
    return this._http.put<IAccountMemberSignature>(`${this._baseUrl}${api}${id}`, entity).catch(this.handleError);
  }
  insertAccountMemberSignature(entity: IAccountMemberSignature): Observable<IAccountMemberSignature> {
    const api = '/AccountMemberSignature/Add/';
    return this._http.post<IAccountMemberSignature>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }

  // ********************* Account Member Preferences**************************
  getAccountMemberPreferencesByParent(id: number): Observable<IAccountMemberPreferences> {
    const api = '/AccountMemberPreferences/GetByParent/';
    return this._http.get<IAccountMemberPreferences>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  insertAccountMemberPreferences(entity: IAccountMemberPreferences) {
    const api = '/AccountMemberPreferences/Add';
    return this._http.post<IAccountMemberPreferences>(`${this._baseUrl}${api}`, entity);
  }
  updateAccountMemberPreferences(preference: IAccountMemberPreferences): Observable<any> {
    const api = '/AccountMemberPreferences/Update/';
    return this._http.put(`${this._baseUrl}${api}${preference.Id}`, preference).catch(this.handleError);
  }

// ********************* Account Member EMail Config**************************

getAccountMemberEMailConfigByParent(id: number): Observable<IAccountMemberEMailConfig> {
  const api = '/AccountMemberEMailConfig/GetByParent/';
  return this._http.get<IAccountMemberEMailConfig>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
}
saveAccountMemberEMailConfig(entity: IAccountMemberEMailConfig) {
  const api = '/AccountMemberEMailConfig/AddOrUpdate/';
  return this._http.post<IAccountMemberEMailConfig>(`${this._baseUrl}${api}`, entity);
}

deleteAccountMemberEMailConfig(id: number): Observable<any> {
  const api = '/AccountMemberEMailConfig/Delete/';
  return this._http.delete(`${this._baseUrl}${api}${id}`);
}

  // ********************* Dashboard Widget**************************
  getDashboardWidgetByParent(accountId: number) {
    const api = '/DashboardWidgets/GetByParent/';

    return this._http.get(`${this._baseUrl}${api}${accountId}`);
  }
  updateDashboardWidget(setting: IDashboardWidgets) {
    const api = '/DashboardWidgets/Update/';

    return this._http.put(`${this._baseUrl}${api}${setting.Id}`, setting);
  }
  addDashboardWidget(setting: IDashboardWidgets) {
    const api = '/DashboardWidgets/Add';

    return this._http.post(`${this._baseUrl}${api}`, setting);
  }

  // ********************* WebSites **************************
  getWebSitesByParent(id: number): Observable<IWebSite[]> {
    const api = '/WebSite/GetByParent/';
    return this._http.get<IWebSite[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  getWebSiteTypes(): Observable<IWebSiteType[]> {
    const api = '/WebSite/GetWebSiteTypes/';
    return this._http.get<IWebSiteType[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }
  insertWebSite(entity: IWebSite): Observable<IWebSite> {
    const api = '/WebSite/Add';
    return this._http.post<IWebSite>(`${this._baseUrl}${api}`, entity);
  }
  updateWebSite(entity: IWebSite): Observable<any> {
    const api = '/WebSite/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteWebSite(id: number): Observable<any> {
    const api = '/WebSite/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }
  updateWebSites(entities: IWebSite[]): Observable<any> {
    if (entities == null) { return Observable.of(new HttpResponse({ status: 200 })); }
    const api = '/WebSite/UpdateArray';
    return this._http.put(`${this._baseUrl}${api}`, entities);
  }

  // ********************* Lead Source **************************
  getLeadSourcesByParent(id: number): Observable<ILeadSource[]> {
    const api = '/LeadSource/GetByParent/';
    return this._http.get<ILeadSource[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  getLeadSourceChannels(): Observable<ILeadSourceChannel[]> {
    const api = '/LeadSource/GetChannels/';
    return this._http.get<ILeadSourceChannel[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }
  getLeadSourceQuickAdd(): Observable<ILeadSourceQuickAdd[]> {
    const api = '/LeadSource/GetQuickAdd/';
    return this._http.get<ILeadSourceQuickAdd[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }
  getLeadSourceTagsByParent(id: number): Observable<ILeadSourceTag[]> {
    const api = '/LeadSource/GetTagsByParent/';
    return this._http.get<ILeadSourceTag[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  insertLeadSource(entity: ILeadSource): Observable<ILeadSource> {
    const api = '/LeadSource/Add';
    return this._http.post<ILeadSource>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }
  updateLeadSource(entity: ILeadSource): Observable<any> {
    const api = '/LeadSource/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteLeadSource(id: number): Observable<any> {
    const api = '/LeadSource/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* Country and State **************************

  // *
  getStates(): Observable<IState[]> {
    const api = '/States/';
    return this._http.get<IState[]>(`${this._baseUrl}${api}`);
  }

  // *
  getCountries(): Observable<ICountry[]> {
    const api = '/Countries/';
    return this._http.get<ICountry[]>(`${this._baseUrl}${api}`);
  }

  // ********************* Locations **************************
    // *
  getLocationsByParent(id: number): Observable<ILocation[]> {
    const api = `/Accounts/${id}/Locations/`;
    return this._http.get<ILocation[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }
  getMainLocationByParent(id: number): Observable<ILocation[]> {
    const api = '/Location/GetMainByParent/';
    return this._http.get<ILocation[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }
  updateLocation(entity: ILocation): Observable<any> {
    const api = '/Location/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  insertLocation(entity: ILocation): Observable<ILocation> {
    const api = '/Location/Add/';
    return this._http.post<ILocation>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }
  deleteLocation(id: number): Observable<any> {
    const api = '/Location/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }
  insertLocations(entity: ILocation[]): Observable<ILocation[]> {
    const api = '/Location/AddArray/';
    return this._http.post<ILocation[]>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }
  updateLocations(locations: ILocation[]): Observable<any> {
    const api = '/Location/UpdateArray/';
    return this._http.put(`${this._baseUrl}${api}`, locations).catch(this.handleError);
  }

  // ********************* Campaing Prefferences**************************
  getCampaignByParent(id: number) {
    const api = '/CampaingPreferences/GetByParent/';
    return this._http.get<ICampaingPreferences>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  insertCampaignPreferences(entity: ICampaingPreferences): Observable<ICampaingPreferences> {
    const api = '/CampaingPreferences/Add/';
    return this._http.post<ICampaingPreferences>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }

  updateCampaignPreferences(entity: ICampaingPreferences): Observable<ICampaingPreferences> {
    const api = '/CampaingPreferences/Update/';
    return this._http.put<ICampaingPreferences>(`${this._baseUrl}${api}${entity.Id}`, entity).catch(this.handleError);
  }

  // ********************* Review Sites**************************

  getReviewSites(id: number) {
    const api = '/ReviewSites/GetByParent/';
    return this._http.get<IReviewSites>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  updateReviewSites(entity: IReviewSites): Observable<IReviewSites> {
    const api = '/ReviewSites/Update/';
    return this._http.put<IReviewSites>(`${this._baseUrl}${api}${entity.Id}`, entity).catch(this.handleError);
  }

  insertReviewSites(entity: IReviewSites): Observable<IReviewSites> {
    const api = '/ReviewSites/Add/';
    return this._http.post<IReviewSites>(`${this._baseUrl}${api}`, entity).catch(this.handleError);
  }

  // ********************* Medical Fields**************************

    // *
  getMedicalFields(): Observable<IMedicalField[]> {
    const api = '/MedicalFields';
    return this._http.get<IMedicalField[]>(`${this._baseUrl}${api}`).catch(this.handleError);
  }
  insertMedicalField(entity: IMedicalField): Observable<IMedicalField> {
    const api = '/MedicalField/Add/';
    return this._http.post<IMedicalField>(`${this._baseUrl}${api}`, entity);
  }
  updateMedicalField(entity: IWebSite): Observable<any> {
    const api = '/MedicalField/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteMedicalField(id: number): Observable<any> {
    const api = '/MedicalField/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // *********************** Procedures ***********************************/

  getProceduresByParent(id: number): Observable<IProcedure[]> {
    const api = '/procedure/GetByParent/';
    return this._http.get<IProcedure[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  insertProcedure(entity: IProcedure): Observable<IProcedure> {
    const api = '/procedure/Add/';
    return this._http.post<IProcedure>(`${this._baseUrl}${api}`, entity);
  }

  saveProcedureArray(items: IProcedure[]): Observable<any> {
    const api = '/procedure/addarray/';
    return this._http.post(`${this._baseUrl}${api}`, items);
  }

  updateProcedure(entity: IProcedure): Observable<any> {
    const api = '/procedure/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  deleteProcedure(id: number): Observable<any> {
    const api = '/procedure/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  /************************* ProcedureGroup *****************************/

  getProcedureGroup(id: number): Observable<IProcedureGroup[]> {
    const api = '/ProcedureGroup/GetByParent/';
    return this._http.get<IProcedureGroup[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  insertProcedureGroup(entity: IProcedureGroup): Observable<IProcedureGroup> {
    const api = '/ProcedureGroup/Add/';
    return this._http.post<IProcedureGroup>(`${this._baseUrl}${api}`, entity);
  }

  updateProcedureGroup(entity: IProcedureGroup): Observable<any> {
    const api = '/ProcedureGroup/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  deleteProcedureGroup(id: number): Observable<any> {
    const api = '/ProcedureGroup/Delete/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* ACCOUNT PHONE NUMBER **************************
  getAccountPhoneNumbersByParent(id: number, smsNumber: boolean): Observable<IAccountPhoneNumber[]> {
    const api = '/AccountPhoneNumber/GetByParent/';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('smsNumbers', smsNumber.toString());
    return this._http.get<IAccountPhoneNumber[]>(`${this._baseUrl}${api}${id}`, {params: params, headers: headers }).catch(this.handleError);
  }

  updateAccountPhoneNumber(entity: IAccountPhoneNumber): Observable<any> {
    const api = '/AccountPhoneNumber/Update/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  // ********************* TWILLIO PHONE NUMBERS **************************

  getAccountLocalPhoneNumbersByAccountAndState(id: number, stateCode: string): Observable<IPhone[]> {
    const api = '/Communications/GetLocalNumbers/';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('stateCode', stateCode);
    return this._http.get<IPhone[]>(`${this._baseUrl}${api}${id}`, {params: params, headers: headers }).catch(this.handleError);
  }

  getAccountTollFreePhoneNumbersByAccount(id: number): Observable<IPhone[]> {
    const api = '/Communications/GetTollFreeNumbers/';
    return this._http.get<IPhone[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  }

  purchaseAccountPhoneNumberByAccount(accountPhoneNumber: IAccountPhoneNumber): Observable<IAccountPhoneNumber> {
      const api = '/Communications/PurchaseNumber/';
      return this._http.post<IAccountPhoneNumber>(`${this._baseUrl}${api}`, accountPhoneNumber);
  }

  removeAccountPhoneNumber(id: number): Observable<any> {
    const api = '/Communications/RemoveNumber/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // getSimpleLogs(id:number):Observable<ISimpleLog[]>{
  //   const api = '/Twillio/GetSimpleLogsByParent/';
  //   return this._http.get<ISimpleLog[]>(`${this._baseUrl}${api}${id}`).catch(this.handleError);
  // }

  sendSimpleLog(entity: ISimpleLog): Observable<ISimpleLog> {
    const api = '/Communications/SendSms/';
    return this._http.post<ISimpleLog>(`${this._baseUrl}${api}`, entity);
  }

  // ********************* FAKE ENTITY SERVICES **************************
  getEntity(id: number): Observable<Object> {
    const data: IEntity = { Id: 1, Name: 'Name', Protected: false };
    if (id > 0) {
      return Observable.of(new HttpResponse({ status: 200, body: data }));
    } else {
      return Observable.throwError('Error');
    }
  }

  updateEntity(data: IEntity): Observable<Object> {
    if (data != null) {
      return Observable.of(new HttpResponse({ status: 200, body: '' }));
    } else {
      return Observable.throwError('Error');
    }
  }
  insertEntity(data: IEntity): Observable<Object> {
    if (data != null) {
      return Observable.of(new HttpResponse({ status: 200, body: 'Id=1' }));
    } else {
      return Observable.throwError('Error');
    }
  }
  deleteEntity(id: number): Observable<Object> {
    if (id > 0) {
      return Observable.of(new HttpResponse({ status: 200, body: '' }));
    } else {
      return Observable.throwError('Error');
    }
  }

  private handleError(err: HttpErrorResponse) {
     console.log(err.error);
    return Observable.throwError(err);
  }
}
