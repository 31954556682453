import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent implements OnInit {
  @ViewChild('mobileView') iframe: ElementRef

  switchButton:string;
  selectedTemplate:string;
  public templateName:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {selectedTemplate:any, templateName:string},
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PreviewTemplateComponent>
  ) { }

  ngOnInit(): void {
    this.switchButton="Desktop";
    this.selectedTemplate = this.data.selectedTemplate;
    this.templateName = this.data.templateName;
  }
  
  ngAfterViewInit(){
    if(this.iframe ){
      this.iframe.nativeElement.srcdoc = this.selectedTemplate;
    }
  }

  getSafeHTML(): SafeHtml{
    return this.sanitizer.bypassSecurityTrustHtml(this.selectedTemplate);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}