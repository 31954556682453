import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppAuthService } from 'src/app/app-auth.service';
import { FeatureFlags } from 'src/app/interfaces/FeatureFlag';
import { DataService } from 'src/app/data-services/data.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';
import { Auth0Settings } from 'src/app/interfaces/AppSettings';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UserAvatarComponent implements OnInit {
  settings: Auth0Settings;
  darkMode: string;
  nextechAuth0ApiUrl: string;
  auth0email: string;
  auth0Name: string;

  constructor(
    private authService: AppAuthService,
    private dataService: DataService,
    private auth0Service: AuthService,
    private router: Router) {
      this.settings = AppSettingsService.Settings.auth0Settings;
  }

  ngOnInit() {
    this.initAvatar();
  }

  initAvatar() {
    this.darkMode = 'true';
    this.nextechAuth0ApiUrl = this.settings.auth0ApiBaseUrl + `/api`;
    const user = this.authService.AccountMember;
    if (user) {
      this.auth0Name = `${user.FirstName} ${user.LastName}`;
      this.auth0email = user.EMail;
    }
  }

  auth0Logout() {
    if (!this.authService.isLoggedIn)
      return;

    this.dataService.getFlagEnabledForAccount(FeatureFlags.auth0Migrated).subscribe(flag => {
      if (flag) {
        let returnToUrl = this.settings.appUri + `/auth0/logged-out`;
        const orgId = this.authService.getOrganizationId();
        if (orgId) {
          returnToUrl = `${returnToUrl}?logout=true&organization=${orgId}`
        }

        this.auth0Service.logout({
          logoutParams: {
            returnTo: returnToUrl
          }
        });
      }
      else{
        this.router.navigate(['login']);
      }
    });
  }
}
