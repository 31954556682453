import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UserMessageComponent } from './user-message/user-message.component';
import { GridMenuComponent } from './grid-menu/grid-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TagInputModule } from 'ngx-chips';
import { NgxMaskModule } from 'ngx-mask';
import { EmailSharedComponent } from './email-shared/email-shared.component';
import { FunnelComponent } from '../angular-materials/shared-components/funnel/funnel.component';
import { LeadsComponent } from './leads/leads.component';
import { LogInfoComponent } from './log-info/log-info.component';
import { NoteSharedComponent } from './note-shared/note-shared.component';
import { ReminderSharedComponent } from './reminder-shared/reminder-shared.component';
import { SmsSharedComponent } from './sms-shared/sms-shared.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FusionChartsModule } from 'angular-fusioncharts';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CallPlayerMiniComponent } from './call-player-mini/call-player-mini.component';
import { CustomRangeComponent } from './filterbar/custom-range/custom-range.component';
import { FilterbarComponent } from './filterbar/filterbar.component';
import { MultiselectCheckboxComponent } from './multiselect-checkbox/multiselect-checkbox.component';
// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import { SimpleChartComponent } from './simple-chart/simple-chart.component';
import { AppPipesModule } from '../../app-pipes/app-pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { WidgetChart1SharedComponent } from '../angular-materials/shared-components/widget-chart1-shared/widget-chart1-shared.component';

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts);


@NgModule({
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-us' }
  ],
  imports: [
    CommonModule,
    FusionChartsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    RouterModule,
    TagInputModule,
    FormsModule,
    MatDialogModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    NgxDatatableModule,
    TooltipModule,
    NgxSummernoteModule,
    BsDropdownModule.forRoot(),
    AppPipesModule
  ],
  exports: [
    UserMessageComponent,
    FunnelComponent,
    LogInfoComponent,
    LeadsComponent,
    ReminderSharedComponent,
    EmailSharedComponent,
    NoteSharedComponent,
    SmsSharedComponent,
    FilterbarComponent,
    CustomRangeComponent,
    CallPlayerMiniComponent,
    WidgetChart1SharedComponent,
    SimpleChartComponent,
    MultiselectCheckboxComponent,
    AppPipesModule,
    GridMenuComponent 
  ],
  declarations: [
    UserMessageComponent,
    FunnelComponent,
    LogInfoComponent,
    LeadsComponent,
    ReminderSharedComponent,
    NoteSharedComponent,
    EmailSharedComponent,
    SmsSharedComponent,
    FilterbarComponent,
    CustomRangeComponent,
    CallPlayerMiniComponent,
    WidgetChart1SharedComponent,
    SimpleChartComponent,
    GridMenuComponent,
    MultiselectCheckboxComponent  ]
})
export class SharedComponentsModule {}
