import { Injectable } from '@angular/core';


@Injectable()
export class PaletteService {

    getBtnPalette(element: BtnPalette) {
        let result = {
            pallete: undefined
        };

        switch (element) {
            case BtnPalette.primary:
                result.pallete = 'nx-primary';
                break;
            case BtnPalette.accent:
                result.pallete = 'nx-accent';
                break;
            case BtnPalette.warn:
                result.pallete = 'nx-warn';
                break;
            case BtnPalette.success:
                result.pallete = 'nx-success';
                break;
            case BtnPalette.caution:
                result.pallete = 'nx-caution';
                break;
            case BtnPalette.info:
                result.pallete = 'nx-info';
                break;
            case BtnPalette.cancel:
                result.pallete = 'nx-cancel-btn';
                break;
            default:
                result.pallete = 'nx-primary';
                break;
        }
        return result;
    }
}

export enum BtnPalette {
    primary,
    accent,
    warn,
    success,
    caution,
    info,
    cancel
  }