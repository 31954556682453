import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BtnPalette, PaletteService } from 'src/app/palette.service';
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})

export class ConfirmationModalComponent implements OnInit {
  public title = 'Warning';
  public dialogMessage = 'Are you sure you want to proceed with this action?';
  public btnCancelPalette: BtnPalette = BtnPalette.cancel;
  public btnConfirmPalette: BtnPalette = BtnPalette.primary;

  public btnCancelClass = 'nx-cancel-btn';
  public btnConfirmClass = 'nx-primary';

  constructor(private paletteService: PaletteService,
    @Inject(MAT_DIALOG_DATA) public data: {
    title: string,
    dialogMessage: string,
    btnCancelPalette: BtnPalette,
    btnConfirmPalette: BtnPalette
  }) {
    if (data) {
      this.title = data.title;
      this.dialogMessage = data.dialogMessage;
      this.btnCancelPalette = data.btnCancelPalette;
      this.btnConfirmPalette = data.btnConfirmPalette;
    }
  }

  ngOnInit(): void {
    const btnCancelConfig = this.checkPallet(this.btnCancelPalette);
    const btnConfirmConfig = this.checkPallet(this.btnConfirmPalette);

    this.btnCancelClass = btnCancelConfig.pallete;
    this.btnConfirmClass = btnConfirmConfig.pallete;
  }

  public checkPallet(element) {
    return this.paletteService.getBtnPalette(element);
  }
}
