import { Injectable } from '@angular/core';
import cronstrue from 'cronstrue';
import * as moment from 'moment';

declare const require: any;
const cronTime = require('cron-time-generator');

@Injectable({
  providedIn: 'root'
})
export class CronService {

  public DailyAtTime(hour: number, minute: number, includeWeekends: boolean = false, useUtc?: boolean): ICronExpression {
    if (!this.validateHourAndMinutes(hour, minute)) {
      return this.createCronExpressionResult('');
    }

    let auxHour: number = hour;
    let auxMinute: number = minute;

    if (useUtc) {
      const today = new Date();
      const utcDate = this.getUtcDate(auxHour, auxMinute, today);
      auxHour = utcDate.hour;
      auxMinute = utcDate.minute;
    }

    if (includeWeekends) {
      return this.createCronExpressionResult(cronTime.everyWeekDayAt(auxHour, auxMinute, DayOfWeek.Sunday, DayOfWeek.Saturday), includeWeekends);
    } else {
      return this.createCronExpressionResult(cronTime.everyWeekDayAt(auxHour, auxMinute), includeWeekends);
    }
  }

  public WeeklyAtDayAndTime(day: DayOfWeek, hour: number, minute: number, useUtc?: boolean): ICronExpression {
    if (!this.validateHourAndMinutes(hour, minute)) {
      return this.createCronExpressionResult('');
    }


    let auxHour: number = hour;
    let auxMinute: number = minute;
    let auxDay: number = 0;
    let utcDay: number = 0;
    let increaseDayByOne: boolean = false;

    if (useUtc) {
      const today = new Date();
      const utcDateValues = this.getUtcDate(auxHour, auxMinute, today);
      auxDay = moment(today).date();
      utcDay = utcDateValues.day
      auxHour = utcDateValues.hour;
      auxMinute = utcDateValues.minute;
      increaseDayByOne = utcDay > auxDay;
    }
    switch (day) {
      case DayOfWeek.Sunday:
        if (increaseDayByOne) {
          // Thanks to the UTC date transformation we need to increase the day in one
          return this.createCronExpressionResult(cronTime.everyMondayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everySundayAt(auxHour, auxMinute));
      case DayOfWeek.Monday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everyTuesdayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everyMondayAt(auxHour, auxMinute));
      case DayOfWeek.Tuesday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everyWednesdayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everyTuesdayAt(auxHour, auxMinute));
      case DayOfWeek.Wednesday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everyThursdayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everyWednesdayAt(auxHour, auxMinute));
      case DayOfWeek.Thursday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everyFridayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everyThursdayAt(auxHour, auxMinute));
      case DayOfWeek.Friday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everySaturdayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everyFridayAt(auxHour, auxMinute));
      case DayOfWeek.Saturday:
        if (increaseDayByOne) {
          return this.createCronExpressionResult(cronTime.everySundayAt(auxHour, auxMinute));
        }
        return this.createCronExpressionResult(cronTime.everySaturdayAt(auxHour, auxMinute));
      default:
        return this.createCronExpressionResult('');
    }
  }

  public MonthlyAtDayAndTime(dayNumber: number, hour: number, minute: number, lastDayOfMonth: boolean = false, includeWeekends: boolean = false, useUtc?: boolean): ICronExpression {
    if (dayNumber <= 0 || dayNumber > 31) {
      return this.createCronExpressionResult('', includeWeekends);
    }
    if (!this.validateHourAndMinutes(hour, minute)) {
      return this.createCronExpressionResult('', includeWeekends);
    }

    let auxDay: number = dayNumber;
    let auxHour: number = hour;
    let auxMinute: number = minute;
    let baseDate: Date;
    let utcDate: {
      day: number;
      month: number;
      year: number;
      hour: number;
      minute: number;
      utc: string;
    }

    if (useUtc) {
      const auxDate = moment(new Date());
      baseDate = new Date(auxDate.year(), auxDate.month(), auxDay, auxHour, auxMinute);
      utcDate = this.getUtcDate(auxHour, auxMinute, baseDate);

      auxDay = utcDate.day;
      auxHour = utcDate.hour;
      auxMinute = utcDate.minute;
    }

    if (lastDayOfMonth){
      return this.createCronExpressionResult(cronTime.everyMonthOn('L', auxHour, auxMinute), includeWeekends);
    }
    else {
      return this.createCronExpressionResult(cronTime.everyMonthOn(auxDay, auxHour, auxMinute), includeWeekends);
    }
  }

  public MonthlyAtLastDayAndTime(hour: number, minute: number, includeWeekends: boolean = false): ICronExpression {
    if (!this.validateHourAndMinutes(hour, minute)) {
      return this.createCronExpressionResult('', includeWeekends);
    }
    return this.createCronExpressionResult(cronTime.everyMonthOn('L', hour, minute), includeWeekends);
  }

  private validateHourAndMinutes(hour: number, minute: number): boolean {
    if (hour < 0 || hour > 24 || minute < 0 || minute > 59) {
      return false;
    }
    return true;
  }

  private createCronExpressionResult(cronExpression: string, includeWeekends: boolean = false): ICronExpression {
    try {
      let isValid: boolean = false;

      if (cronExpression.trim().length > 0) {
        isValid = true;
      }

      let cronText = cronstrue.toString(cronExpression);

      if (includeWeekends == true) {
        cronText = cronText + ' ' + 'including weekends'
      }

      const result: ICronExpression = {
        CronExpression: cronExpression,
        Description: cronText,
        IsValid: isValid,
        IncludingWeekends: includeWeekends,
      };

      return result;
    }
    catch {
      const result: ICronExpression = {
        CronExpression: cronExpression,
        Description: '',
        IsValid: false,
        IncludingWeekends: includeWeekends,
      };
      return result;
    }
  }

  private getUtcDate(hours: number, minutes: number, baseDate: Date): {
    day: number;
    month: number;
    year: number;
    hour: number;
    minute: number;
    utc: string;
  } {

    let auxHours = hours == 0 ? 24 : hours;
    const momentDate = moment(baseDate);
    let day = momentDate.date()

    const selectedDate = new Date(momentDate.year(), momentDate.month(), day, auxHours, minutes);
    const utcDate = moment(selectedDate).utc();

    const utcValues = {
      day: utcDate.date(),
      month: utcDate.month() + 1,
      year: utcDate.year(),
      hour: utcDate.hours(),
      minute: utcDate.minutes(),
      utc: moment(selectedDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
    return utcValues
  }

}

export enum DayOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

export interface ICronExpression {
  CronExpression: string;
  Description: string;
  IsValid: boolean;
  IncludingWeekends: boolean;
}
