import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-custom-range',
  templateUrl: './custom-range.component.html'
})
export class CustomRangeComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;
  @Output() CustomRangeSet = new EventEmitter<any>();
  @Output() Cancel = new EventEmitter<any>();

  public canSave = false;

  private _selectedMoments = [];

  public set selectedMoments(value: any) {
    this._selectedMoments = value;
    this.canSave = true;
    console.log(this._selectedMoments);
  }

  public get selectedMoments(): any {
    return this._selectedMoments;
  }

  constructor() {
  }

  ngOnInit() {

  }

  public modalShown() {
  }

  public modalClosed() {
    this.canSave = false;
    this._selectedMoments = [];
    this.myModal.hide();
  }

  public addEntity() {
    this.canSave = false;
    this._selectedMoments = [];
    this.myModal.show();
  }

  public modalSave() {
    this.CustomRangeSet.emit(this._selectedMoments);
    this.myModal.hide();
  }

  public modalClose() {
    this.Cancel.emit(null);
    this.myModal.hide();
  }

}
