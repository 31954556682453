import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { IFilerEvent } from '../../../shared-components/filterbar/filterbar.component';
import { DatePickerDialogComponent } from './date-picker-dialog/date-picker-dialog.component';

@Component({
  selector: 'app-filterbar-material',
  templateUrl: './filterbar.component.html'
})
export class FilterbarMaterialComponent implements OnInit, OnDestroy {
  @Output() selectedEvent = new EventEmitter<IFilerEvent>();
  
  public customDate: string;
  public showCancel: boolean = false;
  public customDateId: number = 1;
  public event: IFilerEvent;

  datePickerDialog: DatePickerDialogComponent;
  startDatePicked = new Date();
  endDatePicked = new Date();

  selectedOpt: any = {};

  options = [
  {
    text: "Last 7 Days",
    value: 1
  },
  {
    text: "This Week",
    value: 2
  },
  {
    text: "Last Week",
    value: 3
  },
  {
    text: "Last 30 Days",
    value: 4
  },
  {
    text: "This Month",
    value: 5
  },
  {
    text: "Last Month",
    value: 6
  },
  {
    text: "This Year",
    value: 7
  },
  {
    text: "Last Year",
    value: 8
  },
  {
    text: "Year to Date",
    value: 9,
    default: true
  },
  {
    text: "Last 12 Months",
    value: 10
  },
  {
    text: "Custom Dates",
    value: 101
  },
  {
    text: "All Time",
    value: 102
  }
  ];

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setDefaults();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  public onSelectionChange(event) {
    this.selectedOpt = this.options.filter(a => a.value == event.value)[0];
    
    if(!this.selectedOpt){
      this.selectedOpt = this.options[0];
    }

    setTimeout(() => {
      this.selectionChange();
    }, 500);
  }

  private setDefaults() {
    this.selectedOpt = this.options.filter(a => a.default)[0];
    this.showCancel = false;
    this.customDate = null;
    
    this.selectionChange();
  }

  private getCustomDate() {
    this.customDate = this.selectedOpt.text;
    this.customDate += ": " + (moment(this.startDatePicked).format("MM/DD/YYYY") + " - " + moment(this.endDatePicked).format("MM/DD/YYYY"));
  }

  public selectionChange () {
    let sendEvent: boolean = true;
    const today = moment();

    switch (this.selectedOpt.value) {
      case 1: { //Last 7 Days
        this.startDatePicked = today.clone().add(-7, 'days').toDate();
        this.endDatePicked = today.clone().toDate();
        
        break;
      }
      case 2: { //This Week
        this.startDatePicked = today.clone().weekday(1).toDate();
        this.endDatePicked = today.clone().weekday(5).toDate();

        break;
      }
      case 3: { //Last Week
        this.startDatePicked = today.clone().add(-1, 'week').weekday(1).toDate();
        this.endDatePicked = today.clone().add(-1, 'week').weekday(5).toDate();

        break;
      }
      case 4: { //Last 30 Days
        this.startDatePicked = today.clone().add(-30, 'days').toDate();
        this.endDatePicked = today.clone().toDate();

        break;
      }
      case 5: { //This Month
        this.startDatePicked = today.clone().startOf('month').toDate();
        this.endDatePicked = today.clone().endOf('month').toDate();

        break;
      }
      case 6: { //Last Month
        this.startDatePicked = today.clone().add(-1, 'month').startOf('month').toDate();
        this.endDatePicked = today.clone().add(-1, 'month').endOf('month').toDate();

        break;
      }
      case 7: { //This Year
        this.startDatePicked = today.clone().startOf('year').toDate();
        this.endDatePicked = today.clone().endOf('year').toDate();

        break;
      }
      case 8: { //Last Year
        this.startDatePicked = today.clone().add(-1, 'year').startOf('year').toDate();
        this.endDatePicked = today.clone().add(-1, 'year').endOf('year').toDate();

        break;
      }
      case 9: { //Year to Date
        this.startDatePicked = today.clone().startOf('year').toDate();
        this.endDatePicked = today.clone().toDate();

        break;
      }
      case 10: { //Last 12 Months
        this.startDatePicked = today.clone().add(-12, 'month').toDate();
        this.endDatePicked = today.clone().toDate();

        break;
      }
      case 101: { //Custom Dates
        this.dialog.open(DatePickerDialogComponent, {
          width: '400px', data: {
            title: 'Confirmation',
            dialogMessage: "Select a date"
          }
        }).afterClosed().subscribe(result => {

          if (result) {
            this.startDatePicked = result.startDate;
            this.endDatePicked = result.endDate;
          } else {
            sendEvent = false;
            this.setDefaults();
          }

          this.sendEvent(sendEvent);
        });

        break;
      }
      case 102: { //All Time
        this.startDatePicked = today.clone().add(-100, 'year').toDate();
        this.endDatePicked = today.clone().toDate();
        
        break;
      }
      default: { //Last 7 days (Default)
        this.startDatePicked = today.add(-7, 'days').toDate();
        this.endDatePicked = today.clone().toDate();

        break;
      }
    }

    this.sendEvent(sendEvent);
  }

  public sendEvent(send: boolean){
    if (send) {
      this.getCustomDate();
      this.customDateId = 9999;
      this.showCancel = true;

      this.event = {
        index: this.customDateId,
        date1: this.startDatePicked,
        date2: this.endDatePicked,
      };

      this.selectedEvent.emit(this.event);
    }
  }

  public cancel () {
    this.setDefaults();
  }
}
