import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { INavData } from '../_nav';
import { IAppGuard } from '../_nav';
import {
  IAccount,
  IAccountMember,
  IAccountMemberEMailConfig,
  IAccountMemberPreferences,
  IAccountMemberSignature,
  IApplicationRole,
} from '../interfaces/Account';
import { IAccountPhoneNumber } from '../interfaces/AccountPhoneNumber';
import { IAppEmail } from '../interfaces/AppEmail';
import {
  IAppointment,
  IAppointmentConsultStatus,
  IAppointmentType,
  IAppointmentTypePms,
  IAppointmentTypePmsCategory,
  ICalendar,
  IMmlAppointmentType,
  IMmlRelateAppointmentTypes,
} from '../interfaces/Appointment';
import { IAppTimeZone } from '../interfaces/AppTimeZone';
import { ICall } from '../interfaces/Call';
import { IChat, ISmsChat } from '../interfaces/Chat';
import { ICountry, IState } from '../interfaces/Country';
import { IEmailBlast } from '../interfaces/EmailBlast';
import {
  IEmailTemplate,
  IEmailTemplateCategory,
} from '../interfaces/EmailTemplate';
import { IEntity } from '../interfaces/Entity';
import { IErrorLog } from '../interfaces/ErrorLog';
import {
  IComplexLead,
  ILeadFilterBindingModel,
  ILeadGrade,
  ILeadNotQualifiedReason,
  ILeadPhone,
  ILeadStatus,
  ISimpleLead,
} from '../interfaces/Lead';
import { ILeadCommunicationPreferenceExtended, ILeadCommunicationPreferences, ILeadCommunicationPreferencesRequest } from '../interfaces/LeadCommunicationPreferences';
import { ILeadHear } from '../interfaces/LeadHear';
import { ILeadIncomingEMail } from '../interfaces/LeadIncomingEmail';
import { ILeadSource, ILeadSourceChannel } from '../interfaces/LeadSource';
import { ILocation, IMmlLocation, IMmlRelateLocations } from '../interfaces/Location';
import { IMedicalField } from '../interfaces/MedicalField';
import { IMessageBox } from '../interfaces/MessageBox';
import { IPatient } from '../interfaces/Patient';
import { IPmsFilters, IPmsEmr } from '../interfaces/PmsEmr';
import { IProcedure, IProcedureGroup } from '../interfaces/Procedure';
import { IReferralSource, IRelateLeadSourceChannels } from '../interfaces/ReferralSource';
import { IReminder } from '../interfaces/Reminder';
import { ISms } from '../interfaces/Sms';
import { ITransactionKey } from '../interfaces/TransactionKey';
import { IPhone } from '../interfaces/Twillio';
import {
  IAppointmentCompleteView1,
  IAppointmentScheduledView1,
  IEmailBlastEventsView,
  IFollowupLeadsView1,
  IFunnelView1,
  ILeadConversionRpt1,
  ILeadsView1,
  INewLeadsView1,
  INotQualifiedLeadsView1,
  IProcedureCompletedView1,
  IProcedureScheduledView1,
  IRemindersWidget1,
  ISimpleChart1,
  IUnreadLeadEmailsView,
} from '../interfaces/Views';

import { IMmlRelateProviders, IProvider } from '../interfaces/Provider';
import { ValidationFieldsEnum } from '../interfaces/ValidationFieldsEnum';
import { IWebSite } from '../interfaces/WebSite';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private _baseUrl = AppSettingsService.Settings.baseUrl;
  private _baseUrlV2 = AppSettingsService.Settings.baseUrlV2;

  constructor(private _http: HttpClient) { }
  // ********************* ErrorLog **************************
  insertErrorLog(entity: IErrorLog): Observable<any> {
    const api = '/ClientErrorLog/';
    return this._http.post<IErrorLog>(`${this._baseUrl}${api}`, entity);
  }

  // ********************* Country and State **************************
  public getStates(): Observable<IState[]> {
    const api = '/States/';
    return this._http.get<IState[]>(`${this._baseUrl}${api}`);
  }

  public getCountries(): Observable<ICountry[]> {
    const api = '/Countries/';
    return this._http.get<ICountry[]>(`${this._baseUrl}${api}`);
  }

  // ********************* AppTimeZones **************************
  public getTimeZones(): Observable<IAppTimeZone[]> {
    const api = '/TimeZones/';
    return this._http.get<IAppTimeZone[]>(`${this._baseUrl}${api}`);
  }

  // ********************* MedicalFields **************************
  public getMedicalFields(): Observable<IMedicalField[]> {
    const api = '/MedicalFields/';
    return this._http.get<IMedicalField[]>(`${this._baseUrl}${api}`);
  }

  // ********************* PmsEmrs **************************
  public getPmEmrs(): Observable<IPmsEmr[]> {
    const api = '/PmsEmrs/';
    return this._http.get<IPmsEmr[]>(`${this._baseUrl}${api}`);
  }

  // ********************* ApplicationRoles **************************
  public getApplicationRoles(): Observable<IApplicationRole[]> {
    const api = '/ApplicationRoles/';
    return this._http.get<IApplicationRole[]>(`${this._baseUrl}${api}`);
  }

  // ********************* Accounts **************************
  public getAccounts(): Observable<IAccount[]> {
    const api = '/Accounts/';
    return this._http.get<IAccount[]>(`${this._baseUrl}${api}`);
  }

  public getAccount(id: number): Observable<IAccount> {
    const api = '/Accounts/';
    return this._http.get<IAccount>(`${this._baseUrl}${api}${id}`);
  }

  insertAccount(entity: IAccount): Observable<IAccount> {
    const api = '/Accounts/';
    return this._http.post<IAccount>(`${this._baseUrl}${api}`, entity);
  }

  updateAccount(id: number, entity: IAccount): Observable<IAccount> {
    const api = '/Accounts/';
    return this._http.put<IAccount>(`${this._baseUrl}${api}${id}`, entity);
  }

  deleteAccount(id: number): Observable<any> {
    const api = '/Accounts/';
    return this._http.delete<IAccount>(`${this._baseUrl}${api}${id}`);
  }

  activateTwillio(accountId: number): Observable<IAccount> {
    const api = `/Accounts/${accountId}/TwillioActivation/`;
    return this._http.post<IAccount>(`${this._baseUrl}${api}`, null);
  }

  // ********************* AccountMembers **************************
  public getAccountMembers(accountId: number): Observable<IAccountMember[]> {
    const api = `/Accounts/${accountId}/AccountMembers/`;
    return this._http.get<IAccountMember[]>(`${this._baseUrl}${api}`);
  }

  public getAccountMember(id: number): Observable<IAccountMember> {
    const api = '/AccountMembers/';
    return this._http.get<IAccountMember>(`${this._baseUrl}${api}${id}`);
  }

  public insertAccountMember(
    entity: IAccountMember
  ): Observable<IAccountMember> {
    const api = '/AccountMembers/';
    return this._http.post<IAccountMember>(`${this._baseUrl}${api}`, entity);
  }

  public updateAccountMember(
    id: number,
    entity: IAccountMember
  ): Observable<IAccountMember> {
    const api = '/AccountMembers/';
    return this._http.put<IAccountMember>(
      `${this._baseUrl}${api}${id}`,
      entity
    );
  }

  public deleteAccountMember(id: number): Observable<any> {
    const api = '/AccountMembers/';
    return this._http.delete<IAccountMember>(`${this._baseUrl}${api}${id}`);
  }

  public inviteAccountMember(id: number): Observable<any> {
    const api = `/AccountMembers/${id}/Invite/`;
    return this._http.post(`${this._baseUrl}${api}`, null);
  }

  public getPreferences(id: number): Observable<any> {
    const api = `/AccountMembers/${id}/Preferences`;
    return this._http.get(`${this._baseUrl}${api}`);
  }

  public postPreferences(payload: IAccountMemberPreferences) {
    const api = `/AccountMembers/Preferences`;
    return this._http.post(`${this._baseUrl}${api}`, payload);
  }

  // ********************* Account Member EMail Config**************************

  getAccountMemberEMailConfigByAccountMemberId(
    id: number
  ): Observable<IAccountMemberEMailConfig> {
    const api = `/AccountMembers/${id}/EMailConfig/`;
    return this._http.get<IAccountMemberEMailConfig>(`${this._baseUrl}${api}`);
  }
  saveAccountMemberEMailConfig(entity: IAccountMemberEMailConfig) {
    const api = `/AccountMembers/EMailConfig/`;
    return this._http.post<IAccountMemberEMailConfig>(
      `${this._baseUrl}${api}`,
      entity
    );
  }

  // ********************* Locations **************************

  getLocationsByAccountId(accountId: number): Observable<ILocation[]> {
    const api = `/Accounts/${accountId}/Locations/`;
    return this._http.get<ILocation[]>(`${this._baseUrl}${api}`);
  }
  getLocation(id: number): Observable<ILocation> {
    const api = '/Locations/';
    return this._http.get<ILocation>(`${this._baseUrl}${api}${id}`);
  }
  updateLocation(entity: ILocation): Observable<any> {
    const api = '/Locations/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  insertLocation(entity: ILocation): Observable<ILocation> {
    const api = '/Locations/';
    return this._http.post<ILocation>(`${this._baseUrl}${api}`, entity);
  }
  deleteLocation(id: number): Observable<any> {
    const api = '/Locations/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }
  getPmsLocations(): Observable<IMmlLocation[]> {
    const api = '/PmsIntegrations/Location';
    return this._http.get<IMmlLocation[]>(`${this._baseUrl}${api}`);
  }
  relateLocations(locations: IMmlRelateLocations) {
    const api = '/PmsIntegrations/Location/';
    return this._http.post<IMmlRelateLocations>(`${this._baseUrl}${api}`, locations);
  }

  // ********************* Websites **************************

  getWebSitesByAccountId(accountId: number): Observable<IWebSite[]> {
    const api = `/Accounts/${accountId}/Websites/`;
    return this._http.get<IWebSite[]>(`${this._baseUrl}${api}`);
  }
  getWebSite(id: number): Observable<IWebSite> {
    const api = '/Websites/';
    return this._http.get<IWebSite>(`${this._baseUrl}${api}${id}`);
  }
  insertWebSite(entity: IWebSite): Observable<IWebSite> {
    const api = '/Websites/';
    return this._http.post<IWebSite>(`${this._baseUrl}${api}`, entity);
  }
  updateWebSite(entity: IWebSite): Observable<any> {
    const api = '/Websites/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteWebSite(id: number): Observable<any> {
    const api = '/Websites/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // const api = `/Accounts/${accountId}/Leads/filter/`;
  // const headers = new HttpHeaders().set('Content-Type', 'application/json');
  // const params = new HttpParams().set('firstName', firstName).set('lastName', lastName)
  // .set('email', Email).set('phone', Phone).set('dob', DoB);
  // return this._http.get<ISimpleLead[]>(`${this._baseUrl}${api}`, {params: params, headers: headers });

  // ********************* LeadSources **************************

  getLeadSourcesByAccountId(
    accountId: number,
    includeSystem = false
  ): Observable<ILeadSource[]> {
    const api = `/Accounts/${accountId}/LeadSources/`;
    const params = new HttpParams().set('includeSystem', String(includeSystem));
    return this._http.get<ILeadSource[]>(`${this._baseUrl}${api}`, {
      params: params,
    });
  }

  getLeadSourcesInRange(
    accountId: number,
  ): Observable<ILeadSource[]> {
    const api = `/Accounts/${accountId}/Leads/Range`;
    const params = new HttpParams().set('dateBegin', String(new Date().getDate));
    params.set('dateEnd', String(new Date().getDate));
    return this._http.get<ILeadSource[]>(`${this._baseUrl}${api}`, {
      params: params,
    });
  }

  getLeadSource(id: number): Observable<ILeadSource> {
    const api = '/LeadSources/';
    return this._http.get<ILeadSource>(`${this._baseUrl}${api}${id}`);
  }
  insertLeadSource(entity: ILeadSource): Observable<ILeadSource> {
    const api = '/LeadSources/';
    return this._http.post<ILeadSource>(`${this._baseUrl}${api}`, entity);
  }
  updateLeadSource(entity: ILeadSource): Observable<any> {
    const api = '/LeadSources/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteLeadSource(id: number): Observable<any> {
    const api = '/LeadSources/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  getLeadSourceChannels(): Observable<ILeadSourceChannel[]> {
    const api = '/LeadSources/Channels/';
    return this._http.get<ILeadSourceChannel[]>(`${this._baseUrl}${api}`);
  }

  // ********************* Lead Phones **************************
  public getLeadPhones(leadId: number): Observable<ILeadPhone[]> {
    const api = '/LeadPhones/';
    return this._http.get<ILeadPhone[]>(`${this._baseUrl}${api}${leadId}`);
  }

  // ********************* ACCOUNT PHONE NUMBER **************************

  getAccountPhoneNumbersByAccountId(
    accountId: number,
    smsNumber: boolean
  ): Observable<IAccountPhoneNumber[]> {
    const api = `/Communications/${accountId}/PhoneNumbers/${smsNumber}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('smsNumbers', smsNumber.toString());
    return this._http.get<IAccountPhoneNumber[]>(`${this._baseUrl}${api}`, { params: params, headers: headers });
  }

  updateAccountPhoneNumber(entity: IAccountPhoneNumber): Observable<any> {
    const api = `/Communications/PhoneNumbers/`;
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  // ********************* TWILLIO PHONE NUMBERS **************************

  getAccountLocalPhoneNumbersByAccountIdAndState(
    accountId: number,
    stateCode: string
  ): Observable<IPhone[]> {
    const api = `/Twillio/${accountId}/Numbers/Local/${stateCode}/`;
    return this._http.get<IPhone[]>(`${this._baseUrl}${api}`);
  }

  getAccountTollFreePhoneNumbersByAccountId(
    accountId: number
  ): Observable<IPhone[]> {
    const api = `/Twillio/${accountId}/Numbers/TollFree/`;
    return this._http.get<IPhone[]>(`${this._baseUrl}${api}`);
  }

  purchaseAccountPhoneNumber(
    accountPhoneNumber: IAccountPhoneNumber
  ): Observable<IAccountPhoneNumber> {
    const api = '/Twillio/Numbers/';
    return this._http.post<IAccountPhoneNumber>(
      `${this._baseUrl}${api}`,
      accountPhoneNumber
    );
  }

  removeAccountPhoneNumber(phoneNumberId: number): Observable<any> {
    const api = `/Twillio/Numbers/${phoneNumberId}/`;
    return this._http.delete(`${this._baseUrl}${api}`);
  }

  // ********************* ProcedureGroups **************************

  getProcedureGroupsByAccountId(
    accountId: number
  ): Observable<IProcedureGroup[]> {
    const api = `/Accounts/${accountId}/ProcedureGroups/`;
    return this._http.get<IProcedureGroup[]>(`${this._baseUrl}${api}`);
  }
  getProcedureGroup(id: number): Observable<IProcedureGroup> {
    const api = '/ProcedureGroups/';
    return this._http.get<IProcedureGroup>(`${this._baseUrl}${api}${id}`);
  }
  updateProcedureGroup(entity: IProcedureGroup): Observable<any> {
    const api = '/ProcedureGroups/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  insertProcedureGroup(entity: IProcedureGroup): Observable<IProcedureGroup> {
    const api = '/ProcedureGroups/';
    return this._http.post<IProcedureGroup>(`${this._baseUrl}${api}`, entity);
  }
  deleteProcedureGroup(id: number): Observable<any> {
    const api = '/ProcedureGroups/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* Procedures **************************

  getProceduresByAccountId(accountId: number): Observable<IProcedure[]> {
    const api = `/Accounts/${accountId}/Procedures/`;
    return this._http.get<IProcedure[]>(`${this._baseUrl}${api}`);
  }
  getProcedure(id: number): Observable<IProcedure> {
    const api = '/Procedures/';
    return this._http.get<IProcedure>(`${this._baseUrl}${api}${id}`);
  }
  updateProcedure(entity: IProcedure): Observable<any> {
    const api = '/Procedures/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  insertProcedure(entity: IProcedure): Observable<IProcedure> {
    const api = '/Procedures/';
    return this._http.post<IProcedure>(`${this._baseUrl}${api}`, entity);
  }
  deleteProcedure(id: number): Observable<any> {
    const api = '/Procedures/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  uploadProcedure(payload: IProcedure[]): Observable<any> {
    const api = '/Procedures/collection';
    return this._http.post<IProcedure>(`${this._baseUrl}${api}`, payload);
  }

  // ********************* Account Member Signature**************************

  getAccountMemberSignatureByAccountMemberId(
    accountMemberId: number
  ): Observable<IAccountMemberSignature> {
    const api = `/AccountMembers/${accountMemberId}/Signature/`;
    return this._http.get<IAccountMemberSignature>(`${this._baseUrl}${api}`);
  }
  saveAccountMemberSignature(entity: IAccountMemberSignature) {
    const api = `/AccountMembers/Signature/`;
    return this._http.post<IAccountMemberSignature>(
      `${this._baseUrl}${api}`,
      entity
    );
  }

  // ********************* LeadHear **************************

  getLeadHearByAccountId(accountId: number): Observable<ILeadHear[]> {
    const api = `/Accounts/${accountId}/LeadHear/`;
    return this._http.get<ILeadHear[]>(`${this._baseUrl}${api}`);
  }
  getLeadHear(id: number): Observable<ILeadHear> {
    const api = '/LeadHear/';
    return this._http.get<ILeadHear>(`${this._baseUrl}${api}${id}`);
  }
  insertLeadHear(entity: ILeadHear): Observable<ILeadHear> {
    const api = '/LeadHear/';
    return this._http.post<ILeadHear>(`${this._baseUrl}${api}`, entity);
  }
  updateLeadHear(entity: ILeadHear): Observable<any> {
    const api = '/LeadHear/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteLeadHear(id: number): Observable<any> {
    const api = '/LeadHear/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* LeadGrade **************************

  getLeadGradeByAccountId(accountId: number): Observable<ILeadGrade[]> {
    const api = `/Accounts/${accountId}/LeadGrades/`;
    return this._http.get<ILeadGrade[]>(`${this._baseUrl}${api}`);
  }

  // ********************* LeadStatus **************************

  getLeadStatusByAccountId(accountId: number): Observable<ILeadStatus[]> {
    const api = `/Accounts/${accountId}/LeadStatus/`;
    return this._http.get<ILeadStatus[]>(`${this._baseUrl}${api}`);
  }

  // ********************* LeadNotQualifiedReason **************************

  getLeadNotQualifiedReasonsByAccountId(
    accountId: number
  ): Observable<ILeadNotQualifiedReason[]> {
    const api = `/Accounts/${accountId}/LeadNotQualifiedReason/`;
    return this._http.get<ILeadNotQualifiedReason[]>(`${this._baseUrl}${api}`);
  }

  // ********************* COMPLEX LEAD **************************
  getComplexLead(
    leadId: number,
    autoSavedAccountMemberId = 0
  ): Observable<IComplexLead> {
    const api = `/ComplexLeads/${leadId}/`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set(
      'autoSavedAccountMemberId',
      autoSavedAccountMemberId.toString()
    );
    return this._http.get<IComplexLead>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  updateComplexLead(entity: IComplexLead): Observable<any> {
    const api = `/ComplexLeads/${entity.LeadId}/`;
    return this._http.put(`${this._baseUrl}${api}`, entity);
  }

  reassignComplexLead(leadId: number, reassignedUserId: number): Observable<any> {
    const api = `/ComplexLeads/Reassign/${leadId}/${reassignedUserId}`;
    return this._http.get(`${this._baseUrl}${api}`);
  }

  getAllLeadsView1(accountId: Number): Observable<ILeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/All1`;
    return this._http.get<ILeadsView1[]>(`${this._baseUrl}${api}`);
  }

  getLeadLandings(accountId: Number): Observable<string[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/LandingValues`;
    return this._http.get<string[]>(`${this._baseUrl}${api}`);
  }

  getAllNotQualifiedLeadsView1(accountId: Number): Observable<INotQualifiedLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AllNotQualified1`;
    return this._http.get<INotQualifiedLeadsView1[]>(`${this._baseUrl}${api}`);
  }
  
  getAllLeadsView1Filter(
    accountId: Number,
    d1: string,
    d2: string,
    landingValue: string,
    usersSelected: string
  ): Observable<ILeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/All1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams()
      .set('d1', d1)
      .set('d2', d2)
      .set('landingValue', landingValue)
      .set('usersSelected', usersSelected);

    return this._http.get<ILeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getAllNotQualifiedLeadsView1Range(
    accountId: Number,
    d1: string,
    d2: string
  ): Observable<INotQualifiedLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AllNotQualified1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<INotQualifiedLeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getFunnel(accountId: Number): Observable<IFunnelView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/funnel1`;
    return this._http.get<IFunnelView1[]>(`${this._baseUrl}${api}`);
  }

  getFunnelRange(
    accountId: Number,
    d1: string,
    d2: string
  ): Observable<IFunnelView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/funnel1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<IFunnelView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getNewLeads(accountId: number, maxCount: number = 0): Observable<INewLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/New1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('maxCount', maxCount.toString());
    return this._http.get<INewLeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getNewLeadsRange(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<INewLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/New1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<INewLeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getNewLeadsView1Filter(
    accountId: Number,
    d1: string,
    d2: string,
    landingValue: string,
    users: string
  ): Observable<INewLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/New1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams()
      .set('d1', d1)
      .set('d2', d2)
      .set('landingValue', landingValue)
      .set('users', users);

    return this._http.get<INewLeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getFollowUpLeads(accountId: number): Observable<IFollowupLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/Followup1`;
    return this._http.get<IFollowupLeadsView1[]>(`${this._baseUrl}${api}`);
  }

  getFollowUpLeadsRange(
    accountId: number,
    d1: string,
    d2: string,
    users: string
  ): Observable<IFollowupLeadsView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/Followup1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams()
      .set('d1', d1)
      .set('d2', d2)
      .set('usersSelected', users);

    return this._http.get<IFollowupLeadsView1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getAppointmentScheduled(
    accountId: number
  ): Observable<IAppointmentScheduledView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AppointmentScheduled1`;
    return this._http.get<IAppointmentScheduledView1[]>(
      `${this._baseUrl}${api}`
    );
  }

  getAppointmentScheduledRange(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<IAppointmentScheduledView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AppointmentScheduled1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<IAppointmentScheduledView1[]>(
      `${this._baseUrl}${api}`,
      { params: params, headers: headers }
    );
  }

  getAppointmentCompleted(
    accountId: number
  ): Observable<IAppointmentCompleteView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AppointmentComplete1`;
    return this._http.get<IAppointmentCompleteView1[]>(
      `${this._baseUrl}${api}`
    );
  }

  getAppointmentCompletedRange(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<IAppointmentCompleteView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/AppointmentComplete1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<IAppointmentCompleteView1[]>(
      `${this._baseUrl}${api}`,
      { params: params, headers: headers }
    );
  }

  getProcedureScheduled(
    accountId: number
  ): Observable<IProcedureScheduledView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/ProcedureScheduled1`;
    return this._http.get<IProcedureScheduledView1[]>(`${this._baseUrl}${api}`);
  }

  getProcedureScheduledRange(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<IProcedureScheduledView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/ProcedureScheduled1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<IProcedureScheduledView1[]>(
      `${this._baseUrl}${api}`,
      { params: params, headers: headers }
    );
  }

  getProcedureCompleted(
    accountId: number
  ): Observable<IProcedureCompletedView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/ProcedureCompleted1`;
    return this._http.get<IProcedureCompletedView1[]>(`${this._baseUrl}${api}`);
  }

  getProcedureCompletedRange(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<IProcedureCompletedView1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/ProcedureCompleted1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<IProcedureCompletedView1[]>(
      `${this._baseUrl}${api}`,
      { params: params, headers: headers }
    );
  }

  getWidgetChart1(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<ISimpleChart1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/WidgetChart1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<ISimpleChart1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getWidgetChart1B(
    accountId: number,
    d1: string,
    d2: string
  ): Observable<ISimpleChart1[]> {
    const api = `/Accounts/${accountId}/ComplexLeads/WidgetChart1B`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', d1).set('d2', d2);
    return this._http.get<ISimpleChart1[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }



  
  getOpportunitiesWonWidget(accountId: number): Observable<number> {
    const api = `/Accounts/${accountId}/ComplexLeads/OpportunitiesWon`;
    return this._http.get<number>(`${this._baseUrl}${api}`);
  }

  getOpportunitiesWonWidgetRange(
    accountId: number,
    dateFrom: string,
    dateTo: string
  ): Observable<number> {
    const api = `/Accounts/${accountId}/ComplexLeads/OpportunitiesWon`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('d1', dateFrom).set('d2', dateTo);
    return this._http.get<number>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  // ********************* SIMPLE LEAD **************************

  getSimpleLead(id: number): Observable<ISimpleLead> {
    const api = '/Leads/';
    return this._http.get<ISimpleLead>(`${this._baseUrl}${api}${id}`);
  }

  updateSimpleLead(entity: ISimpleLead): Observable<any> {
    const api = '/Leads/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  insertSimpleLead(entity: ISimpleLead): Observable<ISimpleLead> {
    const api = '/Leads/';
    return this._http.post<ISimpleLead>(`${this._baseUrl}${api}`, entity);
  }

  deleteSimpleLead(id: number): Observable<any> {
    const api = '/sysadmin/Leads/'; // real delete
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  reQualifySimpleLead(id: number): Observable<any> {
    const api = '/Leads/ReQualify';
    return this._http.put(`${this._baseUrl}${api}${id}`, '');
  }

  deleteSimpleLeadWithReason(id: number, reasonId: number): Observable<any> {
    const api = '/Leads/';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set(
      'leadNotQualifiedReasonId',
      reasonId.toString()
    );
    return this._http.delete(`${this._baseUrl}${api}${id}`, {
      params: params,
      headers: headers,
    });
  }

  filterSimpleLeads(
    accountId: Number,
    entity: ILeadFilterBindingModel
  ): Observable<ISimpleLead[]> {
    const api = `/Accounts/${accountId}/Leads/Filter/`;
    return this._http.post<ISimpleLead[]>(`${this._baseUrl}${api}`, entity);
  }

  getSimpleLeadSearch(
    accountId: Number,
    text: string
  ): Observable<ISimpleLead[]> {
    const api = `/Accounts/${accountId}/Leads/Search/`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('text', text);
    return this._http.get<ISimpleLead[]>(`${this._baseUrl}${api}`, {
      params: params,
      headers: headers,
    });
  }

  getLeadsInRange(
    accountId: Number,
    startDate: Date,
    endDate: Date 
  ): Observable<ISimpleLead[]> {
    const api = `/Accounts/${accountId}/Leads/Range/`;
    const params = new HttpParams()
    .append('beginDate', this.dateConversion(startDate))
    .append('endDate', this.dateConversion(endDate))
    .append('sourceList', '' );

    return this._http.get<ISimpleLead[]>(`${this._baseUrl}${api}`, {
      params: params
    });
  }

  getLeadsBySource(
    accountId: Number,
    sourceList: Number[]
  ): Observable<ISimpleLead[]> {
    const startDate = new Date('0001-01-01T00:00:00Z')
    // The year of 0001 and 2300 are being used as min max values to get all leads 
    const endDate = new Date('2300-01-01T00:00:00Z')
    const api = `/Accounts/${accountId}/Leads/Range/`;
    const params = new HttpParams()
      .append('beginDate', this.dateConversion(startDate))
      .append('endDate', this.dateConversion(endDate))
      .append('sources', JSON.stringify(sourceList) );


    return this._http.get<ISimpleLead[]>(`${this._baseUrl}${api}`, {
      params: params
    });
  }

  private dateConversion (date: Date): string {

    let convDate = '';
    if (date) {
      convDate = (date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
    }

    return convDate;
  }

  // ********************* PATIENT **************************
  createPatient(entity: IPatient): Observable<any> {
    const api = `/PmsIntegrations/Patients/`;
    return this._http.post(`${this._baseUrl}${api}`, entity);
  }
  getPatient(PatientId: string): Observable<IPatient> {
    const api = '/PmsIntegrations/Patients/';
    return this._http.get<IPatient>(`${this._baseUrl}${api}${PatientId}`);
  }
  getPatientsPerLead(LeadId: number): Observable<IPatient[]> {
    const api = '/PmsIntegrations/Patients/Lead/';
    return this._http.get<IPatient[]>(`${this._baseUrl}${api}${LeadId}`);
  }
  updatePatient(patientId: string, entity: IPatient): Observable<IPatient> {
    const api = `/PmsIntegrations/Patients/${patientId}`;
    return this._http.put<IPatient>(`${this._baseUrl}${api}`, entity);
  }

  unlinkPatient(leadId: number): Observable<any> {
    const api = `/PmsIntegrations/Patients/Unlink/${leadId}`;
    return this._http.put(`${this._baseUrl}${api}`, null);
  }


  // ********************* MessageBox **************************
  getMessageBoxByLeadId(leadId: number): Observable<IMessageBox[]> {
    const api = `/Leads/${leadId}/MessageBox`;
    return this._http.get<IMessageBox[]>(`${this._baseUrl}${api}`);
  }

  getMessageBox(id: number): Observable<IMessageBox> {
    const api = `/MessageBox/`;
    return this._http.get<IMessageBox>(`${this._baseUrl}${api}${id}`);
  }

  updateMessageBox(
    entity: IMessageBox,
    leadId: number,
    noteId: number
  ): Observable<IMessageBox> {
    const api = `/Leads/${leadId}/MessageBox/${noteId}`;
    return this._http.put<IMessageBox>(`${this._baseUrl}${api}`, entity);
  }

  insertMessageBox(
    entity: IMessageBox,
    leadId: number
  ): Observable<IMessageBox> {
    const api = `/Leads/${leadId}/MessageBox/`;
    return this._http.post<IMessageBox>(`${this._baseUrl}${api}`, entity);
  }

  deleteMessageBox(id: number): Observable<any> {
    const api = '/MessageBox/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* Reminder **************************
  getReminder(id: number): Observable<IReminder> {
    const api = `/Reminders/`;
    return this._http.get<IReminder>(`${this._baseUrl}${api}${id}`);
  }

  insertReminder(leadId: number, entity: IReminder): Observable<IReminder> {
    const api = `/Leads/${leadId}/Reminders/`;
    return this._http.post<IReminder>(`${this._baseUrl}${api}`, entity);
  }

  cancelReminder(id: number): Observable<any> {
    const api = `/Reminders/Cancel/${id}/`;
    return this._http.post<any>(`${this._baseUrl}${api}`, null);
  }

  getRemindersByAccountMemberWidget1(
    accountMemberId: number
  ): Observable<IRemindersWidget1[]> {
    const api = `/AccountMembers/${accountMemberId}/Reminders/`;
    return this._http.get<IRemindersWidget1[]>(`${this._baseUrl}${api}`);
  }

  // ********************* Appointment Types **************************

  getAppointmentTypesByAccountId(
    accountId: number
  ): Observable<IAppointmentType[]> {
    const api = `/Accounts/${accountId}/AppointmentTypes/`;
    return this._http.get<IAppointmentType[]>(`${this._baseUrl}${api}`);
  }
  getAppointmentType(id: number): Observable<IAppointmentType> {
    const api = '/AppointmentTypes/';
    return this._http.get<IAppointmentType>(`${this._baseUrl}${api}${id}`);
  }

  getAppointmentCategoriesByAccountId(
    accountId: number
  ): Observable<IAppointmentTypePmsCategory[]> {
    const api = `/Accounts/${accountId}/Categories/`;
    return this._http.get<IAppointmentTypePmsCategory[]>(`${this._baseUrl}${api}`);
  }
  getMmlAppointmentTypes(): Observable<IMmlAppointmentType[]> {
    const api = `/PmsIntegrations/AppointmentType/`;
    return this._http.get<IMmlAppointmentType[]>(`${this._baseUrl}${api}`);
  }
  getAppointmentTypesPmsByAccountId(
    accountId: number
  ): Observable<IAppointmentTypePms[]> {
    const api = `/Accounts/${accountId}/AppointmentTypesPms/`;
    return this._http.get<IAppointmentTypePms[]>(`${this._baseUrl}${api}`);
  }
  relateAppointmentTypes(
    relations: IMmlRelateAppointmentTypes,
    appointmentSync: boolean
  ): Observable<IMmlRelateAppointmentTypes> {
    if (appointmentSync) {
      // Call new endpoint
      const api = '/PmsIntegrations/InitialRelateAppointmentType/';
      return this._http.post<IMmlRelateAppointmentTypes>(`${this._baseUrl}${api}`, relations);
    }
    else {
      // Call old endpoint
      const api = '/PmsIntegrations/RelateAppointmentType/';
      return this._http.put<IMmlRelateAppointmentTypes>(`${this._baseUrl}${api}`, relations);
    }
  }
  // ********************* Appointment **************************

  getAppointmentByLeadId(leadId: number): Observable<IAppointment> {
    const api = `/Leads/${leadId}/Appointments/`;
    return this._http.get<IAppointment>(`${this._baseUrl}${api}`);
  }

  getAllAppointmentsByLeadId(leadId: number): Observable<IAppointment[]> {
    const api = `/Leads/${leadId}/Appointments/`;
    return this._http.get<IAppointment[]>(`${this._baseUrl}${api}`);
  }

  getAppointment(id: number): Observable<IAppointment> {
    const api = '/Appointments/';
    return this._http.get<IAppointment>(`${this._baseUrl}${api}${id}`);
  }

  insertAppointment(
    leadId: number,
    entity: IAppointment
  ): Observable<IAppointment> {
    const api = `/Leads/${leadId}/Appointments/`;
    return this._http.post<IAppointment>(`${this._baseUrl}${api}`, entity);
  }

  cancelAppointment(id: number): Observable<any> {
    const api = `/Appointments/Cancel/${id}/`;
    return this._http.post<any>(`${this._baseUrl}${api}`, null);
  }

  setAppointmentConsultStatus(
    id: number,
    entity: IAppointmentConsultStatus
  ): Observable<any> {
    const api = `/Appointments/ConsultStatus/${id}/`;
    return this._http.post<any>(`${this._baseUrl}${api}`, entity);
  }
  // ********************* Call **************************
  getCall(id: number): Observable<ICall> {
    const api = `/Calls/`;
    return this._http.get<ICall>(`${this._baseUrl}${api}${id}`);
  }

  insertCall(leadId: number, entity: ICall): Observable<ICall> {
    const api = `/Leads/${leadId}/Calls/`;
    return this._http.post<ICall>(`${this._baseUrl}${api}`, entity);
  }

  getCallRecordingUrl(id: number): Observable<any> {
    const api = `/Calls/${id}/Recording`;
    return this._http.get<any>(`${this._baseUrl}${api}`);
  }

  // ********************* EmailTemplate & EmailTemplateCategories **************************

  getEmailTemplatesByAccountId(
    accountId: number
  ): Observable<IEmailTemplate[]> {
    const api = `/Accounts/${accountId}/EmailTemplates/`;
    return this._http.get<IEmailTemplate[]>(`${this._baseUrl}${api}`);
  }

  generateStripoToken(): Observable<string> {
    const api = `/EmailTemplates/StripoToken/`;
    return this._http.get(`${this._baseUrl}${api}`, { responseType: 'text' });
  }

  getEmailTemplate(id: number): Observable<IEmailTemplate> {
    const api = `/EmailTemplates/`;
    return this._http.get<IEmailTemplate>(`${this._baseUrl}${api}${id}`);
  }

  insertEmailTemplate(entity: IEmailTemplate): Observable<IEmailTemplate> {
    const api = '/EmailTemplates/';
    return this._http.post<IEmailTemplate>(`${this._baseUrl}${api}`, entity);
  }

  updateEmailTemplate(entity: IEmailTemplate): Observable<any> {
    const api = '/EmailTemplates/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }

  deleteEmailTemplate(id: number): Observable<any> {
    const api = '/EmailTemplates/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  getEmailTemplateCategoriesByAccountId(
    accountId: number
  ): Observable<IEmailTemplateCategory[]> {
    const api = `/Accounts/${accountId}/EmailTemplateCategories/`;
    return this._http.get<IEmailTemplateCategory[]>(`${this._baseUrl}${api}`);
  }  

  // ********************* Emails **************************

  getAppEmail(id: number): Observable<IAppEmail> {
    const api = '/Emails/Outgoing/';
    return this._http.get<IAppEmail>(`${this._baseUrl}${api}${id}`);
  }

  getLeadIncomingEmail(id: number): Observable<ILeadIncomingEMail> {
    const api = '/Emails/Incoming/';
    return this._http.get<ILeadIncomingEMail>(`${this._baseUrl}${api}${id}`);
  }

  insertAppEmail(entity: IAppEmail): Observable<IAppEmail> {
    const api = '/Emails/Outgoing/';
    return this._http.post<IAppEmail>(`${this._baseUrl}${api}`, entity);
  }

  insertEmailBlast(entity: IEmailBlast): Observable<any> {
    const api = '/Emails/Blast/';
    return this._http.post<IEmailBlast>(`${this._baseUrl}${api}`, entity);
  }

  getAllEmailBlastEventsAccountId(
    accountId: number
  ): Observable<IEmailBlastEventsView[]> {
    const api = `/Emails/Blast/${accountId}`;
    return this._http.get<IEmailBlastEventsView[]>(`${this._baseUrl}${api}`);
  }

  // ********************* Chat & SMS **************************

  getChat(leadId: number): Observable<IChat> {
    const api = `/Chats/`;
    return this._http.get<IChat>(`${this._baseUrl}${api}${leadId}`);
  }

  getAllChatsByAccountId(accountId: number): Observable<IChat[]> {
    const api = `/Accounts/${accountId}/Chats/`;
    return this._http.get<IChat[]>(`${this._baseUrl}${api}`);
  }

  getAllSmsChatByLeadId(leadId: number): Observable<ISmsChat[]> {
    const api = `/Sms/all/`;
    return this._http.get<ISmsChat[]>(`${this._baseUrl}${api}${leadId}`);
  }

  sendSms(entity: ISms): Observable<ISms> {
    const api = '/Sms/outgoing/';
    return this._http.post<ISms>(`${this._baseUrl}${api}`, entity);
  }

  // this method was added due to a bug on Backend
  postSms(entity: ISms): Observable<ISms[]> {
    const api = '/Sms/outgoing/';
    return this._http.post<ISms[]>(`${this._baseUrl}${api}`, entity);
  }
  // ********************* TransactionKeys **************************

  getTransactionKeysByAccountId(
    accountId: number
  ): Observable<ITransactionKey[]> {
    const api = `/Accounts/${accountId}/TransactionKeys/`;
    return this._http.get<ITransactionKey[]>(`${this._baseUrl}${api}`);
  }
  geTransactionKey(id: number): Observable<ITransactionKey> {
    const api = '/TransactionKeys/';
    return this._http.get<ITransactionKey>(`${this._baseUrl}${api}${id}`);
  }
  insertTransactionKey(entity: ITransactionKey): Observable<ITransactionKey> {
    const api = '/TransactionKeys/';
    return this._http.post<ITransactionKey>(`${this._baseUrl}${api}`, entity);
  }
  updateTransactionKey(entity: ITransactionKey): Observable<any> {
    const api = '/TransactionKeys/';
    return this._http.put(`${this._baseUrl}${api}${entity.Id}`, entity);
  }
  deleteTransactionKey(id: number): Observable<any> {
    const api = '/TransactionKeys/';
    return this._http.delete(`${this._baseUrl}${api}${id}`);
  }

  // ********************* VIEWS**************************
  getLeadConversionRpt1(accountId: number): Observable<ILeadConversionRpt1[]> {
    const api = `/Accounts/${accountId}/Reports/LeadConversion1`;
    return this._http.get<ILeadConversionRpt1[]>(`${this._baseUrl}${api}`);
  }

  getAppointmentsCalendar1(
    accountId: number,
    date: string,
    locationId: number,
    providerId: number,
    viewInterval: number
  ): Observable<ICalendar[]> {
    const api = `/Accounts/${accountId}/Appointments/Calendar1`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // const params = new HttpParams().set('Year', year.toString()).set('month', month.toString())
    const params = new HttpParams()
      .set('date', date)
      .set('locationId', locationId.toString())
      .set('providerId', providerId.toString())
      .set('viewInterval', viewInterval.toString());
    return this._http.get<ICalendar[]>(`${this._baseUrl}${api}`, {
      headers: headers,
      params: params,
    });
  }

  getUnreadLeadEmailsAlerts(
    accountMemberId: number
  ): Observable<IUnreadLeadEmailsView[]> {
    const api = `/Emails/Incoming/GetUnreadLeadEmailsAlerts/${accountMemberId}`;
    return this._http.get<IUnreadLeadEmailsView[]>(`${this._baseUrl}${api}`);
  }

  checkForUnreadLeadEMails(accountMemberId: number): Observable<any> {
    const api = `/Emails/Incoming/CheckForUnreadLeadEMails/${accountMemberId}`;
    return this._http.get<any>(`${this._baseUrl}${api}`);
  }

  // ********************* FAKE ENTITY SERVICES **************************

  getEntitiesByAccountId(accountId: number): Observable<any> {
    const data: IEntity[] = [];
    for (let i = 0; i < 100; i++) {
      data.push({ Id: i + 1, Name: 'Name ' + (i + 1), Protected: false });
    }

    if (accountId > 0) {
      return Observable.of(data);
    } else {
      return Observable.throwError('Error');
    }
  }

  getEntity(id: number): Observable<any> {
    const data: IEntity = { Id: 1, Name: 'Name', Protected: false };
    const data2: IEntity = { Id: 0, Name: '', Protected: false };
    if (id > 0) {
      return Observable.of(data);
    } else {
      return Observable.of(new HttpResponse({ status: 200, body: data2 }));
    }
  }

  updateEntity(data: IEntity): Observable<any> {
    if (data != null) {
      return Observable.of(new HttpResponse({ status: 200, body: '' }));
    } else {
      return Observable.throwError('Error');
    }
  }
  insertEntity(data: IEntity): Observable<any> {
    if (data != null) {
      return Observable.of(data);
    } else {
      return Observable.throwError('Error');
    }
  }
  deleteEntity(id: number): Observable<any> {
    if (id > 0) {
      return Observable.of(new HttpResponse({ status: 200, body: '' }));
    } else {
      return Observable.throwError('Error');
    }
  }
  // ********************* NAVIGATION **************************
  getMenu(): Observable<INavData[]> {
    const api = '/Navigation/Menu';
    return this._http.get<INavData[]>(`${this._baseUrlV2}${api}`);
  }

  getMenuMercury(): Observable<INavData[]> {
    const api = '/Navigation/MercuryMenu';
    return this._http.get<INavData[]>(`${this._baseUrl}${api}`);
  }

  getAppGuardDefinitions(): Observable<IAppGuard[]> {
    const api = '/Navigation/AppGuards';
    return this._http.get<IAppGuard[]>(`${this._baseUrl}${api}`);
  }
  // **********************FEATURE FLAGS SERVICES*****************************
  getFlagEnabledForAccount(featureId: number): Observable<boolean> {
    const api = `/Features/${featureId}`;
    return this._http.get<boolean>(`${this._baseUrl}${api}`);
  }
  // **********************LEAD PROCEDURES SERVICES********************
  updateProceduresOfInterestLead(leadId: number, proceduresOfInteretsIds: number[]): Observable<number> {
    const api = `/LeadProcedures/${leadId}/Interest/`;
    return this._http.put<number>(`${this._baseUrl}${api}`, proceduresOfInteretsIds);
  }
  // ***********************TCPA COMPLIANCE**********************************
  getLeadCommunicationPreferences(leadPreferencesRequest: ILeadCommunicationPreferencesRequest)
    : Observable<ILeadCommunicationPreferences[]> {
    const api = `/GetLeadPreferences/`;
    return this._http.post<ILeadCommunicationPreferences[]>(`${this._baseUrl}${api}`, leadPreferencesRequest);
  }
  updateLeadCommunicationPreferences(entities: Array<ILeadCommunicationPreferenceExtended>) {
    const api = '/UpdateLeadPreferences/';
    return this._http.post<Array<ILeadCommunicationPreferenceExtended>>(`${this._baseUrl}${api}`, entities);
  }
  addLeadCommunicationPreferences(entities: Array<ILeadCommunicationPreferenceExtended>) {
    const api = '/AddLeadPreferences/';
    return this._http.post<Array<ILeadCommunicationPreferenceExtended>>(`${this._baseUrl}${api}`, entities);
  }
  // ********************* REFERRAL SOURCES **************************
  getReferralSources(): Observable<IReferralSource[]> {
    const api = '/PmsIntegrations/ReferralSource';
    return this._http.get<IReferralSource[]>(`${this._baseUrl}${api}`);
  }
  relateLeadSourceChannels(relations: IRelateLeadSourceChannels) {
    const api = '/PmsIntegrations/LeadSourceChannel/';
    return this._http.post<IRelateLeadSourceChannels>(`${this._baseUrl}${api}`, relations);
  }
  // ***********************Verify patient details**********************************
  getValidationFields(): Observable<Array<ValidationFieldsEnum>> {
    const api = '/PmsIntegrations/Patients/';
    return this._http.get<Array<ValidationFieldsEnum>>(`${this._baseUrl}${api}`);
  }

  // ***********************Providers**********************************
  getPmsProviders(): Observable<Array<IProvider>> {
    const api = '/PmsIntegrations/Provider'; //GET
    return this._http.get<Array<IProvider>>(`${this._baseUrl}${api}`);
  }
  relateProviders(providers: IMmlRelateProviders): Observable<IMmlRelateProviders> {
    const api = '/PmsIntegrations/Provider'; // POST
    return this._http.post<IMmlRelateProviders>(`${this._baseUrl}${api}`, providers);
  }

  // ***********************PMS Filters**********************************
  getPmsFilters(): Observable<IPmsFilters> {
    const api = '/PmsIntegrations/Filters';
    return this._http.get<IPmsFilters>(`${this._baseUrl}${api}`);
  }
}
