import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

export interface IMappedItem {
  Name: string;
  Id: number;
  MappedToId?: number;
}

@Component({
  selector: 'app-mapping-table',
  templateUrl: './mapping-table.component.html',
  styleUrls: ['./mapping-table.component.scss']
})
export class MappingTableComponent implements OnInit {
  @Input() column1 = [];
  @Input() column2 = [];
  @Input() column1Title: string = '';
  @Input() column2Title: string = '';
  @Output() mappedItems: EventEmitter<any[]> = new EventEmitter<any[]>();
  public mappings = [];
  public changedRows: boolean[];
  public canSave: boolean = false;

  constructor() {
    //
   }

  ngOnInit(): void {
    this.resetChanges();
    this.mappings = [];
    this.column1.forEach((elem) => {
      const column2Elem = this.column2[elem.MappedToId];
      this.mappings.push(column2Elem.Id);
    });
  }

  private resetChanges() {
    this.changedRows = new Array(this.column1.length);
    this.changedRows.fill(false);
    this.canSave = false;
  }

  public onValueChange(ev: any, i: number) {
    let option = (ev.source.selected as MatOption).value;
    const column2Elem = this.column2.find(item => item.Id.toString() === option);
    const column2ElemIndex = this.column2.indexOf(column2Elem);
    if (this.column1[i].MappedToId != column2ElemIndex) {
      this.changedRows[i] = true;
    } else {
      this.changedRows[i] = false;
    }
    this.canSave = false;
    let index = 0;
    while (index < this.changedRows.length && !this.canSave) {
      if (this.changedRows[index]) {
        this.canSave = true;
      }
      index++;
    }
  }

  public onSave() {
    this.mappedItems.emit(this.mappings);
    this.resetChanges();
  }

}
