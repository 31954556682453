import { IAccount } from './Account';

export enum DataEventSource {
    AccontMembers,
    Locations,
    WebSites,
    LeadSources,
    Procedures,
    ProcedureGroups,
    LeadHear,
    TransactionKeys,
    LeadPatientOptIn,
    LeadCustomFields,
    Notes,
    Appointment,
    Call,
    Sms,
    Email,
    AccountEmailTemplates
}

export interface IDataEvent {
    AccountId: number;
    Account: IAccount;
    Entity: any;
    Type: DataEventSource;
}
