import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, Router, RouterEvent, Event } from '@angular/router';
import { Subject } from 'rxjs';
import { installPendo } from '../assets/scripts/pendo';
import { AppSettingsService } from './data-services/app-settings.service';
import { Titles } from './interfaces/Title';
import { autoCloseSessionMins } from './interfaces/consts';
import { filter, take } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(private router: Router, private titleService: Title) {
    const pendoKey =  AppSettingsService.Settings ? AppSettingsService.Settings.pendoApiKey:'';
    installPendo(pendoKey);
    
    this.setTimeout();
    this.userInactive.subscribe(() => {
      console.log('user has been inactive for 60s');
      this.router.navigate(['/login']);
    });

    this.router.events
      .pipe(
        filter((evt: Event | RouterEvent): boolean => {
          return evt instanceof NavigationEnd;
        }),
        take(1)
      )
      .subscribe((event: RouterEvent) => {
        this.setTitle(event);
      });
  }

  setTimeout() {
    const mSeconds = autoCloseSessionMins * 60 * 1000;
    this.userActivity = setTimeout(() => this.userInactive.next(), mSeconds);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private setTitle(event: RouterEvent): void {
    const title = this.titleService.getTitle();
    if (event.url.indexOf('/unsubscribe') !== -1) {
      if (this.navigationValidForTitleChange(event) && title !== Titles.unsubscribe) {
        this.titleService.setTitle(Titles.unsubscribe);
      }
    } else {
      if (this.navigationValidForTitleChange(event) && title !== Titles.leadManagement) {
        this.titleService.setTitle(Titles.leadManagement);
      }
    }
  }

  private navigationValidForTitleChange(event: RouterEvent): boolean {
    return event instanceof NavigationEnd || (event instanceof NavigationError && event.id === 1);
  }

}
