import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { AppAuthService } from '../../../app-auth.service';
import { DataService } from '../../../data-services/data.service';
import { IAccount } from '../../../interfaces/Account';
import { IAccountPhoneNumber } from '../../../interfaces/AccountPhoneNumber';
import { IChat } from '../../../interfaces/Chat';
import { IErrorLog } from '../../../interfaces/ErrorLog';
import { FormState } from '../../../interfaces/FormState';
import { ISms } from '../../../interfaces/Sms';
import { GenericValidator } from '../../../utils/generic-validator';
import { UserMessageComponent } from '../user-message/user-message.component';
import { debounceTime } from  'rxjs/operators';

@Component({
  selector: 'app-sms-shared',
  templateUrl: './sms-shared.component.html'
})
export class SmsSharedComponent implements OnInit {
  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;
  @ViewChild('myModal') public myModal: ModalDirective;

  @Input() account: IAccount;
  @Input() leadId: number;
  @Input() leadPhoneId: number;
  @Input() leadPhone2Id: number;

  @Output() canceled = new EventEmitter<any>();
  @Output() sent = new EventEmitter<any>();

  ready = false;
  // form
  public formState = FormState.Error;
  public displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  _form: UntypedFormGroup;
  get FormState() { return FormState; } // to expose enum values, not the actual formstate
  // data
  public accountPhoneNumberList: IAccountPhoneNumber[] = [];
  public selectedLeadChat: IChat;

  constructor(private service: DataService, private authService: AppAuthService, private fb: UntypedFormBuilder) {
    this.validationMessages = {
      Content: {
        required: 'Please enter field name.',
        minlength: 'Field name must be at least 1 characters.',
        maxLength: 'Field name must be less than 1500 characters.'
      },
      FromId: {
        required: 'Please enter field name.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this._form = this.fb.group({
      Content: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1600)]],
      FromId: [null, [Validators.required]]
    });
    this._form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
      this.displayMessage = this.genericValidator.processMessages(this._form);
    });
  }

  public modalShown() {
    console.log('Modal Shown');
  }

  public modalClosed() {
    console.log('Modal Closed!');
    this.formState = FormState.Error;
    this.myModal.hide();
  }

  public addEntity() {
    this.formState = FormState.isNew;
    this.getData(this.leadId);
    this.myModal.show();
  }

  private getData(leadId: number) {
    if ( leadId === undefined) { return; }
    this.ready = false;
    this.userMessage.getData();
    const l1 = this.service.getChat(leadId);
    const l2 = this.service.getAccountPhoneNumbersByAccountId(this.authService.Account.Id, true);
    forkJoin([l1, l2]).subscribe(([data1, data2]) => {
      this.ready = true;
      this.userMessage.clear();
      this.selectedLeadChat = data1;
      this.accountPhoneNumberList = data2;
      if (this.accountPhoneNumberList && this.accountPhoneNumberList.length > 0) {
        this._form.controls['FromId'].patchValue(this.accountPhoneNumberList[0].Id);
      }
    },
      err => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
          this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
        } else {
          console.log(err);
        }
      },
      () => {
      });
  }

  public modalSave() {
    if (this._form.valid) {
      if (this.formState === FormState.isNew) {
        console.log('insert');
        this.sendMessage();
      }
    }
  }

  // send SMS
  public sendMessage() {
    const content = this._form.controls['Content'].value;
    const accontPhoneNumberId = this._form.controls['FromId'].value;
    const leadPhoneId = this.leadPhoneId;
    const sms: ISms = {
      AccountId: this.selectedLeadChat.AccountId,
      AccountMemberId: this.authService.AccountMember.Id,
      AccountPhoneNumberId: accontPhoneNumberId, // -->
      Content: content, // -->
      Direction: 0,
      LeadId: this.selectedLeadChat.LeadId,
      LeadPhoneId: leadPhoneId // -->
    };
    this.sendSms(sms);
  }

  private sendSms(entity: ISms) {
    this.service.sendSms(entity)
      .subscribe(
        (data) => {
          this.sent.emit(null);
          this.modalClose();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            const error: IErrorLog = err.error;
            console.log(error);
            this.userMessage.showError(error.FriendlyMsg, error.StatusCode);
          } else {
            console.log(err);
          }
        }
      );
  }

  public modalClose() {
    console.log('Modal Closed!');
    this.canceled.emit(null);
    this.formState = FormState.Error;
    this.myModal.hide();
  }

}
