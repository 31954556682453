import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';




// Import components
import { AppComponent } from './app.component';
import { LoginComponent } from './views/init-components/login/login.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];


// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { CreatePasswordComponent } from './views/init-components/create-password/create-password.component';
import { ForgotComponent } from './views/init-components/forgot-password/forgot.component';
import { LeadManagementModule } from './views/lead-management/lead-management.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionServiceModule  } from 'ng-connection-service';
import { HeaderInterceptor } from './app-add-header.interceptor';
import { AppAuthService } from './app-auth.service';
import { PaletteService } from './palette.service';
import { AppGuardService } from './app-guard.service';
import { AppSignalrService } from './app-signalr.service';
import { ConfigDataService } from './data-services/config-data.service';
import { DataService } from './data-services/data.service';
import { ActivateComponent } from './views/init-components/activate/activate.component';
import { UnauthorizedComponent } from './views/init-components/unauthorized/unauthorized.component';
import { SharedComponentsModule } from './views/shared-components/shared-components.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar-portable';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppSessionService } from './app-session.service';
import { appInitializer } from './app.initializer';
import { AppSettingsService } from './data-services/app-settings.service';
import { LeadDetailsRedirectionComponent } from './views/init-components/lead-details-redirection/lead-details-redirection.component';
import { SpamUnsubscribeComponent } from './views/init-components/spam-unsubscribe/spam-unsubscribe.component';
import { CommunicationsPreferencesService } from './communications-preferences.service';
import { MainLayoutComponent } from './containers/main-layout/main-layout.component';
import { SnackbarComponent } from  './containers/snack-bar/snack-bar.component';
import { NavMenuItemComponent } from './views/angular-materials/shared-components/nav-menu-item/nav-menu-item.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { CustomLocationStrategy } from './custom-location-strategy';
import { Auth0Module } from './views/auth0/auth0.module';
import { Auth0Service } from './auth0.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule.forRoot({
      clientId: '', // These will be set dynamically
      domain: '',
    }),
    PerfectScrollbarModule,
    HttpClientModule,
    ReactiveFormsModule,
    LeadManagementModule,
    SharedComponentsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({preventDuplicates: true}), // ToastrModule added,
    NgSelectModule,
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    TooltipModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatInputModule,
    MatAutocompleteModule,
    MatListModule,
    MatCardModule,
    Auth0Module,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    CreatePasswordComponent,
    ForgotComponent,
    ActivateComponent,
    UnauthorizedComponent,
    LeadDetailsRedirectionComponent,
    SpamUnsubscribeComponent,
    MainLayoutComponent,
    SnackbarComponent,
    NavMenuItemComponent
  ],
  providers: [
    Auth0Service,
    PathLocationStrategy,
  {
    provide: LocationStrategy,
    useClass: CustomLocationStrategy,
  }, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    AppGuardService,
    AppAuthService,
    PaletteService,
    ConfigDataService,
    AppSignalrService,
    DataService,
    ConnectionServiceModule,
    AppSessionService,
    AppSettingsService,
    CommunicationsPreferencesService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AppSettingsService, Injector, AuthClientConfig], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
