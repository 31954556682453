import { AbstractControl, ValidatorFn } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatPhoneToDisplay(value: string): string {
  try {
    if (!value) {
      return '';
    }
    if (value.length === 0) {
      return '';
    }
    // const phoneNumber = parsePhoneNumberFromString(value, 'US');
    // return phoneNumber.formatNational();
    const phoneNumber = parsePhoneNumberFromString(value, 'US');
    return phoneNumber.format('NATIONAL');
  } catch (error) {
    return value;
  }
}

export function formatPhoneToBind(value: string): string {
  try {
    if (!value) {
      return '';
    }
    if (value.length === 0) {
      return '';
    }
    const phoneNumber = parsePhoneNumberFromString(value, 'US');
    return phoneNumber.nationalNumber.toString();
  } catch (error) {
    return value;
  }
}

export function PhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && !isPhoneValid(control.value)) {
          return { 'invalid': true };
      }
      return null;
  };
}

export function isPhoneValid(value: string): boolean {
  try {
    if (!value) {
      return false;
    }
    if (value.length === 0) {
      return false;
    }
    const phoneNumber = parsePhoneNumberFromString( value, 'US');
    return phoneNumber.isPossible();
  } catch (error) {
    return false;
  }
}

export function formatPhoneToSave(value: string): string {
  try {
    if (!value) {
      return '';
    }
    if (value.length === 0) {
      return '';
    }
    const phone = parsePhoneNumberFromString(value, 'US');
    return phone.format('E.164');
  } catch (error) {
    return value;
  }
}
