
import * as CryptoJS from 'crypto-js';

export function Encrypt(text: string, pass: string) {
    const conversionEncryptOutput = CryptoJS.AES.encrypt(text.trim(), pass.trim()).toString();
    // console.log(conversionEncryptOutput);
    return conversionEncryptOutput;
}
export function Decrypt(text: string, pass: string) {
    const conversionDecryptOutput = CryptoJS.AES.decrypt(text.trim(), pass.trim()).toString(CryptoJS.enc.Utf8);
    // console.log(conversionDecryptOutput);
    return conversionDecryptOutput;
}
