import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noname',
})

export class NoNamePipe implements PipeTransform {
  transform(value: string): string {
    return value === null || value.trim() === '' ? 'No Name' : value;
  }
}
