import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { GenericValidator } from '../../../../utils/generic-validator'
import { BtnPalette, PaletteService } from 'src/app/palette.service';
@Component({
    selector: 'app-update-dialog',
    templateUrl: './update-dialog.component.html'
})

export class UpdateDialogComponent implements OnInit {
    public title = 'Warning';
    public label = '';
    public value = '';
    public btnCancelPalette: BtnPalette = BtnPalette.cancel;
    public btnConfirmPalette: BtnPalette = BtnPalette.primary;
    public form: UntypedFormGroup;
    public displayMessage: { [key: string]: string } = {};
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;
    public btnCancelClass = 'nx-cancel-btn';
    public btnConfirmClass = 'nx-primary';

    constructor(private fb: UntypedFormBuilder,
        private paletteService: PaletteService,
        private dialogRef: MatDialogRef<UpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            label: string,
            value: string,
            btnCancelPalette: BtnPalette,
            btnConfirmPalette: BtnPalette
        }) {
        if (data) {
            this.title = data.title;
            this.label = data.label;
            this.value = data.value;
            this.btnCancelPalette = data.btnCancelPalette;
            this.btnConfirmPalette = data.btnConfirmPalette;
        }
        this.configForm();
    }

    private configForm(): void {
        this.validationMessages = {
            Name: {
                required: 'Please enter Template Name.'
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);
        this.form = this.fb.group({
            Name: ['', Validators.required],
        });
        this.form.valueChanges.pipe(debounceTime(800)).subscribe(val => {
            this.displayMessage = this.genericValidator.processMessages(this.form);
        });


    }
    ngOnInit(): void {
        const btnCancelConfig = this.checkPallet(this.btnCancelPalette);
        const btnConfirmConfig = this.checkPallet(this.btnConfirmPalette);
        this.form.get('Name').setValue(this.value);
        this.btnCancelClass = btnCancelConfig.pallete;
        this.btnConfirmClass = btnConfirmConfig.pallete;
    }

    public checkPallet(element) {
        return this.paletteService.getBtnPalette(element);
    }

    public cancelForm() {
        this.dialogRef.close();
    }

    public saveForm(){
        this.dialogRef.close(this.form.get('Name').value);
    }
}
