import { Component, Input } from '@angular/core';
import { AppAuthService } from '../../../../app-auth.service';
import { ToLocalDateTime } from '../../../../interfaces/AppTimeZone';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent  {
  @Input() logInfo: any ;
  @Input() visible: boolean = true;

  constructor(private authService: AppAuthService) { }

  public ToLocalDateTime(date: Date) {
    if (date === null) { return 'not available'; }
    return ToLocalDateTime(date, this.authService.AccountMember.AppTimeZoneName);
  }

}
