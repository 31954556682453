import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Error, Auth0ErrorSource } from '../../../interfaces/Auth0Error';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  error: Auth0Error;
  navigation: string;

  constructor(private router: Router) {
    this.error = this.router.getCurrentNavigation()?.extras?.state as Auth0Error;
    if (this.error?.errorId) {
      this.setAction(this.error);
    }
    else {
      this.navigation = '/#/login'
    }
  }

  setAction(err: Auth0Error) {
    switch (err.errorId) {
      case Auth0ErrorSource.UnifiedIdentity:
      case Auth0ErrorSource.Unauthorized:
      default:
        this.navigation = '/#/login'
        break;
    }
  }

  gotoAction() {
    window.location.replace(this.navigation);
  }
}
