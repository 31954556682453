import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { IAccountMemberSignature } from '../../interfaces/Account';
import { IAppEmail } from '../../interfaces/AppEmail';
import { ILeadIncomingEMail } from '../../interfaces/LeadIncomingEmail';
import { AppSettingsService } from "../app-settings.service";


@Injectable({
    providedIn: 'root'
})
export class EmailsService {
    private _baseUrl = AppSettingsService.Settings.baseUrl;
  
    constructor(private _http: HttpClient) { }
  
    public getAppEmail(id: number): Observable<IAppEmail> {
        const api = '/Emails/Outgoing/';
        return this._http.get<IAppEmail>(`${this._baseUrl}${api}${id}`);
    }
    
    public getLeadIncomingEmail(id: number): Observable<ILeadIncomingEMail> {
        const api = '/Emails/Incoming/';
        return this._http.get<ILeadIncomingEMail>(`${this._baseUrl}${api}${id}`);
    }
    
    public insertAppEmail(entity: IAppEmail): Observable<IAppEmail> {
        const api = '/Emails/Outgoing/';
        return this._http.post<IAppEmail>(`${this._baseUrl}${api}`, entity);
    }

    public getAccountMemberSignatureByAccountMemberId(
        accountMemberId: number
      ): Observable<IAccountMemberSignature> {
        const api = `/AccountMembers/${accountMemberId}/Signature/`;
        return this._http.get<IAccountMemberSignature>(`${this._baseUrl}${api}`);
    }
    
    public saveAccountMemberSignature(entity: IAccountMemberSignature) {
        const api = `/AccountMembers/Signature/`;
        return this._http.post<IAccountMemberSignature>(`${this._baseUrl}${api}`, entity);
    }
  
}