export interface ILeadContactDetails {
    EmailAddress: string;
    PhoneNumbers: string[];
}

export interface ILeadCommunicationPreferencesRequest {
    ClientId: string;
    PracticeId: string;
    ContactDetailsList: ILeadContactDetails[];
}

export interface ILeadCommunicationPreference {
    ContactTypeId: number;
    ContactInfo: string;
    CommunicationTypeId: number;
    StatusId: number;
}

export interface ILeadCommunicationPreferenceExtended extends ILeadCommunicationPreference {
    ClientId: string;
    PracticeId: string;
}

export interface ILeadCommunicationPreferences {
    ContactCommunicationPreferences: ILeadCommunicationPreference[];
}

export enum Status {
    OptIn = 1,
    OptOut,
    Pending,
    ExplicitConsentReceived,
    NotFound
}

export enum CommunicationType {
    Transactional = 1,
    Marketing
}

export enum ContactType {
    Email = 1,
    PhoneCall,
    Sms
}
