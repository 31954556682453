export enum InternalRoles {
  None = 0,
  Admin = 1,
  UserAdmin,
  UserRole1,
  UserRole2
  }

export enum ApplicationRoles {
  Admin = 1,
  Provider_Physician = 2,
  User = 3,
  Marketing_Agency = 4
}

//Auth0 Roles
export enum Auth0Roles{
  PmRole= 'User_PPlus',
  NlmRole = 'User_NLM'
}
