import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuardService } from '../../app-guard.service';

const routes: Routes = [
  {
    path: 'manual-lead',
    loadChildren: () => import('./manual-lead/manual-lead.module').then(m => m.ManualLeadModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'all-leads',
    loadChildren: () => import('./all-leads/all-leads.module').then(m => m.AllLeadsModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'lead-details',
    loadChildren: () => import('./lead-details/lead-details.module').then(m => m.LeadDetailsModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'text-message-dashboard',
    loadChildren: () => import('./text-message-dashboard/text-message-dashboard.module').then(m => m.TextMessageDashboardModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'new-leads',
    loadChildren: () => import ('./new-leads/new-leads.module').then(m => m.NewLeadsModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'followup',
    loadChildren: () => import ('./followup/followup.module').then(m => m.FollowupModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'app-scheduled',
    loadChildren: () => import ('./app-scheduled/app-scheduled.module').then(m => m.AppScheduledModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'app-completed',
    loadChildren: () => import ('./app-complete/app-complete.module').then(m => m.AppCompleteModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'procedure-scheduled',
    loadChildren: () => import ('./procedure-scheduled/procedure-scheduled.module').then(m => m.ProcedureScheduledModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'procedure-completed',
    loadChildren: () => import ('./procedure-completed/procedure-completed.module').then(m => m.ProcedureCompletedModule),
    canActivate: [AppGuardService]
  },
  {
    path: 'not-qualified-leads',
    loadChildren: () => import ('./not-qualified-leads/not-qualified-leads.module').then(m => m.NotQualifiedLeadsModule),
    canActivate: [AppGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadManagementRoutingModule { }
