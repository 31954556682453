import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html'
})
export class UserMessageComponent implements OnInit, AfterViewInit {

  @Input() ShowMessage = false;
  @Input() Message = '';

  private messageType: MsgsTypes = MsgsTypes.info;
  private showSpinner = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }

  public clear() {
    this.Message = '';
    this.messageType = MsgsTypes.info;
    this.ShowMessage = false;
  }

  public saveData() {
    this.createMsg('Saving data...', MsgsTypes.info, false);
  }

  public getData() {
    this.createMsg('Getting data...', MsgsTypes.info, false);
  }

  public saveSucceded() {
    this.createMsg('Saving...', MsgsTypes.info, true, 2000);
  }

  public dataError(componentName: string, functionName: string) {
    this.createMsg('The application didn\'t found data needed to display this page. Please contact technical support..', MsgsTypes.error, true);
  }

  public showError(errMsg: string, errN: number) {
    const msg = errMsg + ' [Status Code ' + errN + ']';
    this.createMsg(msg, MsgsTypes.error);
  }

  public createMsg(msg: string, type: MsgsTypes, timed: boolean = true, time = 20000, showSpinner: boolean = false) {
    this.Message = msg;
    this.messageType = type;
    this.ShowMessage = true;
    this.showSpinner = showSpinner;
    if (timed) {
      setTimeout(() => this.ShowMessage = false, time);
    }
  }

  public getCSSClass() {
    if (this.messageType === MsgsTypes.info) {
      return 'alert alert-success';
    } else if (this.messageType === MsgsTypes.warning) {
      return 'alert alert-warning';
    } else if (this.messageType === MsgsTypes.error) {
      return 'alert alert-danger';
    }
  }

}

export enum MsgsTypes {
  info,
  warning,
  error
}
