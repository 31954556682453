
export interface IAppEmail {
    Date?: string;
    AppEmailType: AppEmailType;
    Direction: AppEmailDirection;
    From: string;
    FromName?: string;
    AccountMemberFromId: number;
    ReplyTo?: string;
    To: string;
    AccountMemberToId?: number;
    // From or To
    LeadId: number;
    Subject: string;
    Content: string;
    ContentType: AppEmailContentType;

    ResponseCode?: number;
    ResponseReason?: string;
    Response?: string;
    MsgId?: string;
    TemplateId?: string;
    NotifyError: boolean;
    NotifyDelivered: boolean;
    NotifyOpened: boolean;

    Id: number;
    DateCreated?: string;
    DateUpdated?: string;
    CreatedBy?: string;
    UpdatedBy?: string;
    Protected?: boolean;
}

export enum AppEmailDirection {
    Outbound,
    Inbound
}

export enum AppEmailContentType {
    Text = 0,
    Html,
    Template
}

export enum AppEmailType {
    SystemEmail,
    AppEmail,
    LeadEmail,
    EmailBlast
}
