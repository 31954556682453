// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  height: 35px;
  padding: 0 1rem;
  margin-top: 8px !important;
}

input.mat-input-element {
  font-size: 14px;
}

.margin-top {
  margin-top: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/angular-materials/shared-components/save-template/save-template.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,2BAAA;AACJ","sourcesContent":[".btn {\r\n    height: 35px;\r\n    padding: 0 1rem; \r\n    margin-top: 8px !important;\r\n}\r\n\r\ninput.mat-input-element {\r\n    font-size: 14px;\r\n}\r\n\r\n.margin-top {\r\n    margin-top: 15px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
