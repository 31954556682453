import { Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-daterange-filter',
  templateUrl: './daterange-filter.component.html'
})
export class DateRangeFilterComponent implements OnInit {
  @Output() dateRangeSelected = new EventEmitter<{ startDate: Date, endDate: Date, userTimeZoneStartDate: string, userTimeZoneEndDate: string }>();
  @ViewChild('picker') dateRangePicker: MatDateRangePicker<Date>;
  @Input() timeZone: string | undefined;
  @Input() defaultDays: number = 30;
  @Input() resetDate: Subject<boolean>  = new Subject<boolean>();
  @Input() isDisabled: Subject<boolean> = new Subject<boolean>();

  @ViewChild('startDateInput', { read: MatInput }) startDateInput: MatInput;
  @ViewChild('endDateInput', { read: MatInput }) endDateInput: MatInput;


  public showPresetOptions: boolean = false;
  public startDate: Date;
  public endDate: Date;
  public dateSelected: boolean = false;
  public disabledCalendar: boolean;

  public userTimeZoneStartDate: string;
  public userTimeZoneEndDate: string;

  presetOptions = [
    { label: 'Today', days: 0 },
    { label: 'Yesterday', days: 1 },
    { label: 'Last 7 days', days: 8 },
    { label: 'Last 30 days', days: 31 },
    { label: 'Last 90 days', days: 91 },
  ];

  constructor(private cdr: ChangeDetectorRef) { }

  setDefaultDateRange(days: number) {
    this.setUserTimeZone();
    let defaultStartDate = this.setDateStartTimeZero(moment().subtract(days, 'days'));
    let defaultEndDate = this.setDateEndTimeLast(moment());

    this.startDate = this.getOnlyDateFromUserTimeZone(defaultStartDate);
    this.endDate = this.getOnlyDateFromUserTimeZone(defaultEndDate);

    this.userTimeZoneStartDate = defaultStartDate.format();
    this.userTimeZoneEndDate = defaultEndDate.format();
    setTimeout(() => {
      this.disabledCalendar = true;
      this.dateRangeSelected.emit({ startDate: this.startDate, endDate: this.endDate, userTimeZoneStartDate: this.userTimeZoneStartDate, userTimeZoneEndDate: this.userTimeZoneEndDate });
    }, 500)
    this.resetDefaultTimeZone();
  }

  setDateStartTimeZero(date: moment.Moment):moment.Moment {
    return date.set("hour", 0).set("minute", 0).set('seconds', 0)
  }

  setDateEndTimeLast(date: moment.Moment):moment.Moment {
    return date.set("hour", 23).set("minute", 59).set('seconds', 59)
  }

  clearSelection() {
    if (this.startDateInput != null) {
      this.startDateInput.value = "";
    }
    if (this.endDateInput != null) {
      this.endDateInput.value = "";
    }
  }
  // Called when the user types the start date.
  onStartDateSelected(event: any) {
    if (event.value != null) {
      this.clearSelection();
      this.setUserTimeZone();

      this.startDate = event.value;
      this.userTimeZoneStartDate = this.setDateStartTimeZero(moment().set("year", this.startDate.getFullYear()).set("month", this.startDate.getMonth()).set("date", this.startDate.getDate())).format();
      console.log(event);
      this.validateDateRange();
      this.cdr.detectChanges();
      this.resetDefaultTimeZone();

    }

  }

  // Called when the user types an end date.
  onEndDateSelected(event: any) {
    if (event.value != null) {
      this.setUserTimeZone();

      this.endDate = event.value;
      this.userTimeZoneEndDate = this.setDateEndTimeLast(moment().set("year", this.endDate.getFullYear()).set("month", this.endDate.getMonth()).set("date", this.endDate.getDate())).format();
      this.validateDateRange();
      this.resetDefaultTimeZone();
      this.disabledCalendar = true;
      this.dateRangeSelected.emit({ startDate: this.startDate, endDate: this.endDate, userTimeZoneStartDate: this.userTimeZoneStartDate, userTimeZoneEndDate: this.userTimeZoneEndDate });
      this.cdr.detectChanges();

    }
  }

  validateDateRange() {
    if (this.endDate === undefined || this.endDate === null || isNaN(this.endDate.getTime()) || !this.isValidYear(this.endDate.getFullYear())) {
      this.endDate = moment().toDate();
    }

    if (this.startDate === undefined || this.startDate === null || isNaN(this.startDate.getTime()) || !this.isValidYear(this.startDate.getFullYear())) {
      this.startDate = moment().subtract(this.defaultDays, 'days').toDate();
    }
    if (this.startDate && this.endDate && this.endDate < this.startDate) {
      this.setDefaultDateRange(this.defaultDays);
    }
  }

  isValidYear(year: number): boolean {
    // Check if the year has four digits
    return /^\d{4}$/.test(year.toString());
  }

  startDatePicker = new Subject<MatDatepickerInputEvent<any>>();
  endDatePicker = new Subject<MatDatepickerInputEvent<any>>();

  togglePresetOptions() {
    this.showPresetOptions = !this.showPresetOptions;
  }

  getOnlyDateFromUserTimeZone(timeZoneDateTime: moment.Moment): Date {
    return new Date(timeZoneDateTime.year(), timeZoneDateTime.month(), timeZoneDateTime.date())
  }

  setUserTimeZone() {
    if (this.timeZone != undefined) {
      moment.tz.setDefault(this.timeZone)
    } else {
      this.resetDefaultTimeZone();
    }
  }

  resetDefaultTimeZone() {
    moment.tz.setDefault()
  }

  // Called when a predefined option is clicked.
  onPresetSelected(days: number) {

    this.setUserTimeZone();
    let selectedDate = this.setDateStartTimeZero(moment().subtract(days, 'days'))
    this.userTimeZoneStartDate = selectedDate.format()
    this.startDate = this.getOnlyDateFromUserTimeZone(selectedDate)
    let endDateMoment = this.setDateEndTimeLast(moment())
    this.userTimeZoneEndDate = endDateMoment.format()
    this.endDate = this.getOnlyDateFromUserTimeZone(moment())

    if (days > 0) {
      endDateMoment = this.setDateEndTimeLast(moment().subtract(1, 'days'))
      this.userTimeZoneEndDate = endDateMoment.format()
      this.endDate = this.getOnlyDateFromUserTimeZone(endDateMoment)
    }

    this.resetDefaultTimeZone();
    this.disabledCalendar = true;
    this.dateRangeSelected.emit({ startDate: this.startDate, endDate: this.endDate, userTimeZoneStartDate: this.userTimeZoneStartDate, userTimeZoneEndDate: this.userTimeZoneEndDate });
    this.showPresetOptions = false;
    this.cdr.detectChanges();
    if (this.dateRangePicker && typeof this.dateRangePicker.close === 'function') {
      this.dateRangePicker.close();
    }
  }

  resetDates() {
    this.setDefaultDateRange(this.defaultDays);
    if (this.dateRangePicker) {
      this.dateRangePicker.close();
    }
  }

  ngOnInit(): void {

    this.isDisabled.subscribe({
      next: (res: boolean) => {
        this.disabledCalendar = res;
      }
    })

    this.resetDate.subscribe({
      next: (res: any) => {
        this.setDefaultDateRange(this.defaultDays);
      }
    })

    this.setDefaultDateRange(this.defaultDays);
    this.startDatePicker.subscribe((data) => {
      this.startDate = moment(data.value).toDate();
    });

    this.endDatePicker.subscribe((data) => {
      this.endDate = moment(data.value).toDate();
      this.dateSelected = (data.value != null);
    });
  }

  onOpen() {
    let matCalendar: any = document.getElementsByClassName("mat-calendar")[0];

    let div1 = document.createElement("div");
    div1.className = 'date-filter-option'

    let div2 = document.createElement("div");

    for (let option of this.presetOptions) {
      let button = document.createElement("button");
      button.innerText = option.label
      button.addEventListener('click', (e) => {
        this.onPresetSelected(option.days)
      });
      div2.appendChild(button)
    }

    let button = document.createElement("button");
    button.className = "reset";
    button.innerText = "Reset";
    button.addEventListener('click', (e) => {
      this.resetDates();
    });

    let div3 = document.createElement("div");
    div3.appendChild(button)

    div1.appendChild(div2);
    div1.appendChild(div3);
    matCalendar.parentNode.parentNode.appendChild(div1);

  }
}