import { Component, OnInit } from '@angular/core';
import { IAuth0Login } from '../../../interfaces/Login';
import { AppAuthService } from '../../../app-auth.service';
import { concatMap, tap } from 'rxjs/operators';
import { DataService } from '../../../data-services/data.service';
import { Router } from '@angular/router';
import { IAccountMember } from '../../../interfaces/Account';
import { InternalRoles } from '../../../interfaces/roles-enum';
import { LeadDetailsRedirectionService } from '../../init-components/lead-details-redirection/lead-details-redirection.service';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { decodeJwt } from 'jose';
import { PathLocationStrategy } from '@angular/common';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {
  errorMessage = '';
  UnauthorizedMsg = false;
  canLogin = true;
  auth0Token: IdToken;
  token: string;
  clientId: string;
  sessionId: string;

  constructor(
    private authService: AppAuthService,
    private dataService: DataService,
    private router: Router,
    private auth: AuthService,
    public pathLocationStrategy: PathLocationStrategy,
    private redirectLead: LeadDetailsRedirectionService) {
  }

  ngOnInit(): void {
    const absolutePathWithParams = this.pathLocationStrategy.path();
    const queryParams = new URLSearchParams(absolutePathWithParams.split('?')[1]);

    if (queryParams.get('code') && queryParams.get('state')) {
      this.auth.isAuthenticated$.subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated) {
            this.auth.idTokenClaims$.subscribe(results => {
              this.auth0Token = results;
              this.auth.appState$.subscribe({
                next: (state) => {
                  this.getToken(state?.target);
                }
              });             
            });
          }
          else {
           this.navigateToRoute(AppSettingsService.Settings.auth0Settings.universalLoginUrl+'/error');
          }
        }
      });
    }
  }

  tryLogin(token: string, clientId: string, sessionId: string, redirectUrl?: string) {
    const login: IAuth0Login = {
      Auth0Token: token,
      AccountNumber: '',
      ReturnPath: redirectUrl,
      Target: '',
      SessionId: sessionId
    };
    this.authService.loginAuth0(login, clientId).pipe(
      tap(session => {
        this.authService.setClientId(clientId);
        this.authService.setToken(session.Token, session.Expiration);
        this.lastClientId = clientId;
        this.authService.setSessionEmail(session.User);
        this.authService.redirectUrl = redirectUrl;
      }),
      concatMap(accountMember => this.authService.getAccountByEmail(accountMember.User)),
      tap(accountMember => {
        this.authService.AccountMember = accountMember;
        this.authService.setSessionAccountMember(accountMember);
      }),
      concatMap(account => this.authService.getAccount(account.AccountId)),
      tap(account => {
        this.authService.Account = account;
        this.authService.setSessionAccount(account);
      }),
      concatMap(guards => this.dataService.getAppGuardDefinitions()),
      tap(guards => {
        this.authService.guards = guards;
        this.authService.setGuards(guards);
      }),
    ).subscribe(response => {
      const session = this.authService.getSession();

      this.onGetUserComplete(session.accountMember);
    },
      err => {
        this.UnauthorizedMsg = true;
        setTimeout(() => {
          this.canLogin = true;
        }, 100);
        this.router.navigate(['/error'], { state: err.error });
      },
      () => {
        console.log('try login completed');
      });
  }
  
  onGetUserComplete(member: IAccountMember) {
    if (!this.authService.Account.IsActive) {
      this.router.navigate(['/unauthorized'], { queryParams: { err: 5 } });
      return;
    }

    this.authService.login(member.EMail, member.InternalRole, member); // login in authService

    if (!member.ActivateAccountCompleted) { // complete activate account
      this.navigateToRoute('/#/activate'); // admin app USER
      return;
    }

    if (!member.IsActive) {
      this.router.navigate(['/unauthorized'], { queryParams: { err: 4 } });
      return;
    }

    if (member.InternalRole === InternalRoles.UserAdmin) {
      if (this.authService.redirectUrl && this.authService.redirectUrl !== '/') {
        this.redirect();
      } else {
        this.redirectToDashboard(); // admin app USER
      }
      return;
    }

    if (member.InternalRole === InternalRoles.UserRole1) {
      if (this.authService.redirectUrl && this.authService.redirectUrl !== '/') {
        this.redirect();
      } else {
        this.redirectToDashboard();
      }
      return;
    }
    // if no role selected logout
    this.authService.logout();
  }

  redirect() {
    if (this.redirectLead.redirect) {
      this.redirectToLeadDetails();
    } else {
      this.redirectLead.redirect = false;
      this.navigateToRoute(`/#${this.authService.redirectUrl}`);
    }
  }

  redirectToDashboard() {
    if (this.redirectLead.redirect) {
      this.redirectToLeadDetails();
    } else {
      this.redirectLead.redirect = false;
      this.navigateToRoute('/#/dashboard');
    }
  }

  redirectToLeadDetails() {
    this.redirectLead.redirectLeadFromLogin();
    this.redirectLead.redirect = false;
  }

  navigateToRoute(route: string){
    window.location.replace(route);
  }

  get lastClientId(): string {
    return localStorage.getItem('MML-REMEMBER-CLIENTID') || '';
  }
  set lastClientId(value: string) {
    localStorage.setItem('MML-REMEMBER-CLIENTID', value);
  }

  private getToken(redirectUrl?: string) {
    if (this.auth0Token?.__raw) {
      this.token = this.auth0Token.__raw;
      this.sessionId = this.auth0Token.sid;

      sessionStorage.setItem("auth0IdToken", this.token);
      sessionStorage.setItem("auth0SessionId", this.sessionId);

      if (this.auth0Token.org_id) {
        localStorage.setItem("auth0OrgId", this.auth0Token.org_id);
      } else {
        localStorage.removeItem("auth0OrgId");
      }

      this.clientId = this.getNxClientId(this.token);
      sessionStorage.setItem("nxClientId", this.clientId);
      sessionStorage.setItem("org_changeprocessing", "true");
      localStorage.setItem("auth0_hasLoggedIn","true");

      this.tryLogin(this.token, this.clientId, this.sessionId, redirectUrl);
    }
  }

  private getNxClientId(identityToken: string) {
    let decodedJwt = decodeJwt(identityToken);
    if ("https://nextech.com/organization" in decodedJwt) {
      return decodedJwt["https://nextech.com/organization"]["nx_client_id"]
    }
  }
}
